import React, { useState, useEffect, MouseEventHandler } from "react";

//ui
import Grid from "@material-ui/core/Grid";
import {
  Table,
  Form,
  Row,
  Col,
  Card,
  Spinner,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { makeStyles, styled } from "@material-ui/styles";
import Select, { StylesConfig, components } from "react-select";
import CreatableSelect from "react-select/creatable";
// import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import { TransitionGroup, CSSTransition } from "react-transition-group";
// components
import EquipmentModal from "../components/EquipmentModal";
import BodyMapModal from "../components/BodyMapModal";
import { useFormik } from "formik";
import VariationsComponent from "../components/VariationsComponent";
import AdModExerciseDialog from "../components/dialog-bars/AdModExerciseDialog";
import ProgressBar from "../../Other/ProgressBar";
// import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import ReactivateExerciseDialog from "../components/dialog-bars/ReactivateExerciseDialog";

// redyx
import { shallowEqual, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  newExercise,
  getExercise,
  exerciseFieldChange,
  variationFieldChange,
  variationFileRemove,
  duplicateVariation,
  addBlankVariation,
  removeVariation,
  fetchEquipmentIcons,
  fetchPropIcons,
  fetchTags,
  addExercise,
  updateExercise,
} from "../_redux/exercises/exerciseLibraryActions";
import * as Yup from "yup";
import CustomSelect from "../../Other/CustomSelect";

const colourStyles: StylesConfig<ColourOption, true> = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      ":active": {
        backgroundColor: "#ffe5e5",
      },
      ":focus": {
        backgroundColor: "#ffe5e5",
      },
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      padding: "3px",
      backgroundColor: "#ffe5e5",
    };
  },
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#ff5764 ",
      color: "white",
    },
  }),
};

export default function AdModExercisePage({
  history,
  match: {
    params: { id },
  },
}) {
  const { mode } = useParams();
  const title = typeof id !== "undefined" ? "Edit Exercise" : "Add Exercise";
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [equipmentModalShow, setEquipmentModalShow] = useState(false);
  const [bodyMapModalShow, setBodyMapModalShow] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const handleEquipModal = () => setEquipmentModalShow(true);
  const handleBodyMapModal = () => setBodyMapModalShow(true);
  const [readOnly, setReadOnly] = useState(false);
  const [reactivateDialog, setReactivateDialog] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  const exerciseValidationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
  });

  const initialValues = {
    name: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: exerciseValidationSchema,
    onSubmit: (values) => {},
  });

  const dispatch = useDispatch();

  const { tags } = useSelector((state) => state.exerciseLibrary, shallowEqual);
  const {
    exercise,
    saveExerciseLoading,
    saveExerciseSuccess,
    listLoading,
  } = useSelector((state) => state.exerciseLibrary);

  useEffect(() => {
    if (id) {
      dispatch(getExercise(id));
    } else {
      dispatch(newExercise());
    }
    dispatch(fetchTags());
    dispatch(fetchEquipmentIcons());
    dispatch(fetchPropIcons());

    // let splitted = pathname.split("/");
    if (mode == "read-only") {
      setReadOnly(true);
    }
  }, [mode]);

  const optionsArrayString = (options) => {
    let opts = [];
    options &&
      options.map((option) => {
        option = {
          label: option,
          value: option,
        };
        opts = [...opts, option];
        // console.log(option);
      });

    return opts;
  };

  const valueArrayString = (options) => {
    let opts = [];
    options &&
      options.map((option) => {
        option = {
          label: option,
          value: option,
        };
        opts = [...opts, option];
        // console.log(option);
      });

    return opts;
  };

  const handleEquipModalClose = () => {
    setEquipmentModalShow(false);
    setTimeout(() => {
      setSelectedEquipment(null);
    }, 100);
  };

  const handleMultiValueClick = (data) => {
    setSelectedEquipment(data);
    handleEquipModal();
  };

  const MultiValueLabel = ({ children, ...props }) => {
    const onMouseDown: MouseEventHandler = (e) => {
      e.preventDefault();
      e.stopPropagation();
      handleMultiValueClick(props.data);
    };
    const innerProps = { ...props, onMouseDown };
    return (
      <components.MultiValueLabel {...props} innerProps={innerProps}>
        <span style={{ fontSize: "85%", padding: "3px", cursor: "pointer" }}>
          {children}
        </span>
      </components.MultiValueLabel>
    );
  };

  const handleExerciseFieldChange = (e) => {
    let params = {
      target: e.target.name,
      value: e.target.value,
    };
    dispatch(exerciseFieldChange(params));
  };

  const handleTextSelectChange = (value, action) => {
    let textValues = [];

    value !== null &&
      value.map((val) => {
        textValues = [...textValues, val.value];
      });

    let params = {
      target: action.name,
      value: textValues,
    };

    dispatch(exerciseFieldChange(params));
  };

  const handleSelectChange = (value, action) => {
    let params = {
      target: action.name,
      value: value !== null ? value : [],
    };
    dispatch(exerciseFieldChange(params));
  };

  const handleSingleSelectChange = (value, action) => {
    let params = {
      target: action.name,
      value: value,
    };
    dispatch(exerciseFieldChange(params));
  };

  const handleVariationFieldChange = (e, index) => {
    let params = {
      index: index,
      target: e.target.name,
      value: e.target.value,
    };

    dispatch(variationFieldChange(params));
  };

  const handleVariationCheckChange = (event, index) => {
    let params = {
      index: index,
      target: event.target.name,
      value: event.target.value,
    };

    dispatch(variationFieldChange(params));
  };

  const handleVariationSingleSelectChange = (value, action, index) => {
    let params = {
      index: index,
      target: action.name,
      value: value.id,
    };
    // console.log(params);
    dispatch(variationFieldChange(params));
  };

  const handleVariationSelectChange = (value, action, index) => {
    // let ids = [];
    // value !== null &&
    //   value.map((val) => {
    //     ids = [...ids, val.id];
    //   });
    let params = {
      index: index,
      target: action.name,
      value: value,
    };
    // dispatch(exerciseFieldChange(params));
    // let params = {
    //   index: index,
    //   target: action.name,
    //   value: value,
    // };

    dispatch(variationFieldChange(params));
  };

  const handleVariationFileChange = (file, index) => {
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      let params = {
        index: index,
        target: file.target_name,
        value: file,
        b64: baseURL,
      };
      dispatch(variationFieldChange(params));
    };
  };

  const getBase64 = (file) => {
    // return new Promise((resolve) => {
    let fileInfo;
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      console.log(baseURL);
    };
    // });
  };

  const handleVariationFileRemove = (target, index) => {
    let params = {
      index: index,
      target: target,
    };
    dispatch(variationFileRemove(params));
  };

  const handleAddBlankVariation = () => {
    dispatch(addBlankVariation());
  };

  const handleDuplicateVariation = (index) => {
    dispatch(duplicateVariation({ index: index }));
  };

  const handleRemoveVariation = (index) => {
    dispatch(removeVariation({ index: index }));
  };

  useEffect(() => {
    saveExerciseSuccess && history.push("/exercises");
  }, [saveExerciseSuccess]);

  useEffect(() => {
    let errorsCount = 0;

    if (exercise?.name == "") {
      errorsCount++;
    }
    exercise?.variations &&
      exercise.variations.map((variation) => {
        if (variation.name == "") {
          errorsCount++;
        }
      });

    if (errorsCount > 0) {
      setHasErrors(true);
    } else {
      setHasErrors(false);
    }
  }, [exercise, exercise?.variations]);

  const handleSave = () => {
    if (exercise.id) {
      dispatch(updateExercise(exercise, hasErrors));
    } else {
      dispatch(addExercise(exercise, hasErrors));
    }
  };

  function enableEdit() {
    setReadOnly(false);
  }

  return (
    <div>
      {listLoading && <ProgressBar />}
      <AdModExerciseDialog />
      <ReactivateExerciseDialog
        reactivateDialog={reactivateDialog}
        setReactivateDialog={setReactivateDialog}
      />
      <div className="card border-0">
        <div className="card-header d-flex">
          {readOnly ? (
            <>
              <h4>View Exercise</h4>
              <Link
                component={RouterLink}
                to={`/exercises/${id}`}
                color="inherit"
                className="ml-auto"
              >
                <Button
                  onClick={enableEdit}
                  variant="contained"
                  className="bg-primary rounded-pill text-white"
                  size="large"
                >
                  Edit Exercise
                </Button>
              </Link>
            </>
          ) : (
            <>
              <h4>{title}</h4>
              {exercise?.archived_at !== null && exercise?.id && (
                <Button
                  onClick={() => {
                    setReactivateDialog(true);
                  }}
                  variant="contained"
                  className="bg-primary rounded-pill text-white ml-auto"
                  size="large"
                >
                  Re-activate
                </Button>
              )}
            </>
          )}
        </div>
        {!listLoading && (
          <>
            <div
              className="card-body"
              style={{ borderBottom: "1px solid #EBEDF3", minHeight: "300px" }}
            >
              <Form>
                <Grid container className="input-group mb-5" spacing={1}>
                  <Grid item xs={2}>
                    <strong>Name *</strong>
                  </Grid>
                  <Grid item xs={6} className="position-relative">
                    <Form.Control
                      readOnly={readOnly}
                      placeholder="Name"
                      name="name"
                      value={exercise?.name}
                      onChange={handleExerciseFieldChange}
                      // {...formik.getFieldProps("name")}
                    />
                    {exercise?.name == "" && (
                      <svg
                        style={{
                          position: "absolute",
                          top: "15px",
                          right: "-15px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="red"
                        class="bi bi-exclamation-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                      </svg>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={1} className="input-group mb-5">
                  <Grid item xs={2}>
                    <strong>Equipment</strong>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      isDisabled={readOnly}
                      name="equipment"
                      onChange={handleSelectChange}
                      value={exercise?.equipment}
                      // value={optionFromId()}
                      options={tags?.equipments}
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => option.id}
                      aria-labelledby="aria-label"
                      inputId="aria-example-input"
                      placeholder="Equipments"
                      isMulti
                      styles={colourStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#ff8a93",
                          primary: "#ffe5e5",
                        },
                      })}
                      components={{
                        MultiValueLabel,
                      }}
                    />
                  </Grid>
                  {!readOnly && (
                    <Grid item xs={3}>
                      <Button
                        variant="contained"
                        className="bg-primary rounded-pill text-white"
                        size="large"
                        onClick={handleEquipModal}
                      >
                        + Add new equipment
                      </Button>
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={1} className="input-group mb-5">
                  <Grid item xs={2}>
                    <strong>Class types</strong>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      isDisabled={readOnly}
                      name="class_types"
                      onChange={handleSelectChange}
                      options={tags?.class_types}
                      value={exercise?.class_types}
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => option.id}
                      aria-labelledby="aria-label"
                      inputId="aria-example-input"
                      placeholder="Class types"
                      isMulti
                      styles={colourStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#ff8a93",
                          primary: "#ffe5e5",
                        },
                      })}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} className="input-group mb-5">
                  <Grid item xs={2}>
                    <strong>Conditions</strong>
                  </Grid>
                  <Grid item xs={6}>
                    <CreatableSelect
                      isDisabled={readOnly}
                      name="conditions"
                      isMulti
                      onChange={handleTextSelectChange}
                      options={optionsArrayString(tags?.conditions)}
                      value={valueArrayString(exercise?.conditions)}
                      getOptionLabel={(option) => `${option.label}`}
                      getOptionValue={(option) => option.value}
                      styles={colourStyles}
                      placeholder="Select/Add"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#ff8a93",
                          primary: "#ffe5e5",
                        },
                      })}
                    />
                    <div></div>
                  </Grid>
                </Grid>
                <Grid container spacing={1} className="input-group mb-5">
                  <Grid item xs={2}>
                    <strong>Body part</strong>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomSelect
                      isMulti
                      // isDisabled={true}
                      menuIsOpen={false}
                      isSearchable={false}
                      name="body_parts"
                      onChange={handleSelectChange}
                      // onClick={handlePreventedClick}
                      options={tags?.body_parts}
                      value={exercise?.body_parts}
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => option.id}
                      aria-labelledby="aria-label"
                      inputId="aria-example-input"
                      placeholder="Body parts"
                      // styles={colourStyles}
                      // theme={(theme) => ({
                      //   ...theme,
                      //   colors: {
                      //     ...theme.colors,
                      //     primary25: "#ff8a93",
                      //     primary: "#ffe5e5",
                      //   },
                      // })}
                    />
                  </Grid>
                  {!readOnly && (
                    <Grid item xs={3}>
                      <Button
                        variant="contained"
                        className="bg-primary rounded-pill text-white"
                        size="large"
                        onClick={handleBodyMapModal}
                      >
                        Select from body map
                      </Button>
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={1} className="input-group mb-5">
                  <Grid item xs={2}>
                    <strong>Precaution</strong>
                  </Grid>
                  <Grid item xs={6}>
                    <CreatableSelect
                      isDisabled={readOnly}
                      name="precaution"
                      isMulti
                      onChange={handleTextSelectChange}
                      styles={colourStyles}
                      options={optionsArrayString(tags?.precautions)}
                      value={valueArrayString(exercise?.precaution)}
                      getOptionLabel={(option) => `${option.label}`}
                      getOptionValue={(option) => option.value}
                      placeholder="Select/Add"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#ff8a93",
                          primary: "#ffe5e5",
                        },
                      })}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} className="input-group mb-5">
                  <Grid item xs={2}>
                    <strong>Contraindication</strong>
                  </Grid>
                  <Grid item xs={6}>
                    <CreatableSelect
                      isDisabled={readOnly}
                      name="contraindication"
                      isMulti
                      onChange={handleTextSelectChange}
                      styles={colourStyles}
                      options={optionsArrayString(tags?.contraindications)}
                      value={valueArrayString(exercise?.contraindication)}
                      getOptionLabel={(option) => `${option.label}`}
                      getOptionValue={(option) => option.value}
                      placeholder="Select/Add"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#ff8a93",
                          primary: "#ffe5e5",
                        },
                      })}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} className="input-group mb-5">
                  <Grid item xs={2}>
                    <strong>Progression</strong>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      isDisabled={readOnly}
                      name="progression"
                      onChange={handleSelectChange}
                      options={tags?.existing_exercises}
                      value={exercise?.progression}
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => option.id}
                      aria-labelledby="aria-label"
                      inputId="aria-example-input"
                      placeholder="Select Exercises"
                      isMulti
                      styles={colourStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#ff8a93",
                          primary: "#ffe5e5",
                        },
                      })}
                    />
                    {/* <Select
                      name="progression"
                      onChange={handleSingleSelectChange}
                      options={tags?.existing_exercises}
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => option.id}
                      value={exercise?.progression}
                      aria-labelledby="aria-label"
                      inputId="aria-example-input"
                      placeholder="Exercises"
                      styles={colourStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#ffe5e5",
                          primary: "#ff8a93",
                        },
                      })}
                    /> */}
                  </Grid>
                </Grid>
                <Grid container spacing={1} className="input-group mb-5">
                  <Grid item xs={2}>
                    <strong>Regression</strong>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      isDisabled={readOnly}
                      name="regression"
                      onChange={handleSelectChange}
                      options={tags?.existing_exercises}
                      value={exercise?.regression}
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => option.id}
                      aria-labelledby="aria-label"
                      inputId="aria-example-input"
                      placeholder="Select Exercises"
                      isMulti
                      styles={colourStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#ff8a93",
                          primary: "#ffe5e5",
                        },
                      })}
                    />
                  </Grid>
                </Grid>
              </Form>
              <EquipmentModal
                show={equipmentModalShow}
                onHide={handleEquipModalClose}
                selected={selectedEquipment}
              />
              <BodyMapModal
                show={bodyMapModalShow}
                onHide={() => setBodyMapModalShow(false)}
              />
            </div>

            <div className="card-body">
              <strong>Variations</strong>
              <TransitionGroup>
                {exercise?.variations.length > 0 &&
                  exercise?.variations.map((variation, index) =>
                    variation?.deleted == 1 ? (
                      ""
                    ) : (
                      <CSSTransition
                        key={variation.id ? variation.id : variation.uid}
                        classNames="example"
                        timeout={{ enter: 500, exit: 300 }}
                      >
                        <Grid
                          container
                          className="d-flex justify-content-center mb-5"
                        >
                          <Grid
                            item
                            lg={8}
                            style={{ backgroundColor: "#ebebeb" }}
                          >
                            <VariationsComponent
                              readOnly={readOnly}
                              key={index}
                              index={index}
                              variation={variation}
                              handleVariationFieldChange={(event) => {
                                handleVariationFieldChange(event, index);
                              }}
                              handleVariationSelectChange={(event, actions) =>
                                handleVariationSelectChange(
                                  event,
                                  actions,
                                  index
                                )
                              }
                              handleVariationCheckChange={(event) => {
                                handleVariationCheckChange(event, index);
                              }}
                              handleVariationFileChange={(file) => {
                                handleVariationFileChange(file, index);
                              }}
                              handleVariationFileRemove={(target) => {
                                handleVariationFileRemove(target, index);
                              }}
                              handleVariationSingleSelectChange={(
                                event,
                                actions
                              ) => {
                                handleVariationSingleSelectChange(
                                  event,
                                  actions,
                                  index
                                );
                              }}
                            />
                            {!readOnly && (
                              <Row className="p-5">
                                <Col className="text-right">
                                  <button
                                    className="btn btn-primary rounded-pill"
                                    onClick={() => {
                                      handleDuplicateVariation(index);
                                    }}
                                  >
                                    + Duplicate variation
                                  </button>
                                </Col>
                              </Row>
                            )}
                          </Grid>
                          {!readOnly && (
                            <Grid item lg={2} className="text-center">
                              <button
                                className="btn btn-primary rounded-pill"
                                onClick={() => {
                                  handleRemoveVariation(index);
                                }}
                              >
                                - Remove variation
                              </button>
                            </Grid>
                          )}
                        </Grid>
                      </CSSTransition>
                    )
                  )}
              </TransitionGroup>
              {!readOnly && (
                <Row className="mt-5">
                  <Col lg={12} className="d-flex">
                    <button
                      className="btn btn-primary rounded-pill m-auto"
                      onClick={handleAddBlankVariation}
                    >
                      + Add new blank variation
                    </button>
                  </Col>
                </Row>
              )}
            </div>
            {!readOnly && (
              <Row className="justify-content-center">
                <Col lg={1}>
                  <button className="btn btn-secondary rounded-pill w-100 text-primary">
                    Cancel
                  </button>
                </Col>
                <Col lg={1}>
                  <Button
                    disabled={saveExerciseLoading}
                    variant="primary"
                    onClick={() => handleSave()}
                    className="rounded-pill w-100"
                  >
                    {saveExerciseLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        style={{
                          position: "absolute",
                          left: "2rem",
                          top: "1rem",
                        }}
                        aria-hidden="true"
                      />
                    )}
                    Save
                  </Button>
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    </div>
  );
}
