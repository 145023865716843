import React, { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { setActiveWeek } from "../_redux/clinicalRehabActions";

const ClinicalRehabWeeks = () => {
  const dispatch = useDispatch();
  const { weeks, activeWeek, weeksLoading, listLoading } = useSelector(
    (state) => state.clinicalRehab
  );

  useEffect(() => {
    weeks.length > 0 && dispatch(setActiveWeek(0));
  }, [weeks]);

  const handleNext = () => {
    console.log("next");
    const curIndex = activeWeek?.week - 1;
    const weekIndex = curIndex + 1;

    dispatch(setActiveWeek(weekIndex));
  };

  const handlePrevious = () => {
    const curIndex = activeWeek?.week - 1;
    const weekIndex = curIndex - 1;

    dispatch(setActiveWeek(weekIndex));
  };

  return (
    <Card style={{ backgroundColor: "lightgray" }} className="mt-3">
      <Card.Body className="p-3">
        <Row className="d-flex justify-content-center">
          <Col lg={3}>
            <Row>
              {weeksLoading ? (
                <Col className="d-flex justify-content-center">
                  <CircularProgress
                    style={{
                      color: "#ff8a93",
                    }}
                  />
                </Col>
              ) : (
                <>
                  <Col className="d-flex justify-content-center align-items-center">
                    {activeWeek?.week != 1 && (
                      <a
                        href="javascript:void(0)"
                        onClick={handlePrevious}
                        style={{
                          pointerEvents: listLoading && "none",
                        }}
                      >
                        <SVG
                          fill="dark"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/chevron-left.svg"
                          )}
                        />
                      </a>
                    )}
                  </Col>
                  <Col lg={5}>
                    <Row className="text-center">
                      <Col>
                        <strong>Week {activeWeek?.week}</strong>
                      </Col>
                    </Row>
                    <Row className="text-center">
                      <Col>{activeWeek?.range}</Col>
                    </Row>
                  </Col>
                  <Col className="d-flex justify-content-center align-items-center">
                    {activeWeek?.week != weeks.length && (
                      <a
                        href="javascript:void(0)"
                        onClick={handleNext}
                        style={{
                          pointerEvents: listLoading && "none",
                        }}
                      >
                        <SVG
                          fill="dark"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/chevron-right.svg"
                          )}
                        />
                      </a>
                    )}
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ClinicalRehabWeeks;
