import React, { useState, useEffect, useRef } from "react";

// ui
import Grid from "@material-ui/core/Grid";
import { Image, Spinner, Button, InputGroup } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import UserDialog from "../components/dialog-bars/UserDialog";
import SVG from "react-inlinesvg";
import ProgressBar from "../../Other/ProgressBar";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { getPermissionList } from "../../Permissions/_redux/permissionActions";
import {
  populateNewUser,
  userFieldChage,
  userCreate,
  userUpdate,
  getUser,
} from "../_redux/userActions";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";

export default function AdModUserPage({
  history,
  match: {
    params: { id },
  },
}) {
  const dispatch = useDispatch();
  const { mode } = useParams();
  const title = typeof id !== "undefined" ? "Edit User" : "Add User";
  const { users, permissions, auth } = useSelector((state) => state);
  const {
    user,
    actionsLoading,
    listLoading,
    createUserSuccess,
    errors,
  } = users;
  const defaultImg = toAbsoluteUrl("/media/images/default-profile.png");
  const [previewImage, setPreviewImage] = useState(null);
  const imageRef = useRef();
  const imageFileTypes = ["png", "jpeg", "jpg"];
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    if (id == auth.user.id) {
      history.push("/my-profile");
    }
    dispatch(getPermissionList());
    if (id) {
      dispatch(getUser(id));
    } else {
      dispatch(populateNewUser());
    }

    if (mode == "read-only") {
      setReadOnly(true);
    }

    // console.log("new page");
  }, []);

  useEffect(() => {
    createUserSuccess && history.push("/users");
  }, [createUserSuccess]);

  useEffect(() => {
    if (user?.photo !== "") {
      console.log("has foto");
      setPreviewImage(user?.photo);
      return;
    }
  }, [user, user?.photo_file]);

  useEffect(() => {
    console.log(previewImage);
  }, [previewImage]);

  const handleFieldChange = (e) => {
    let params = {
      val: e.target.value,
      target: e.target.name,
    };
    dispatch(userFieldChage(params));
  };

  const handleSelectChange = (value, action) => {
    let params = {
      target: action.name,
      val: value.name,
    };
    dispatch(userFieldChage(params));
  };

  const handleSave = () => {
    if (user?.id) {
      //update function
      dispatch(userUpdate(user));
    } else {
      dispatch(userCreate(user));
    }
  };

  const handleImageButton = () => {
    imageRef.current.click();
  };

  const handleImageChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];

    if (fileObj) {
      fileObj.target_name = event.target.name;
      handleFileChange(fileObj);
      // // 👇️ reset file input
      event.target.value = null;
    }
  };

  const handleFileChange = (file) => {
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      let params = {
        target: file.target_name,
        val: file,
        b64: baseURL,
      };
      dispatch(userFieldChage(params));
    };
  };

  const resetSchema = Yup.object().shape({
    password: Yup.string()
      .matches(/(?=.*[A-Z])/, "Password must contain a capital letter")
      .matches(
        /^(?=.*[!@#\$%\^&\*])/,
        "Password must contain a special character"
      )
      .matches(/^(?=.{6,20}$)\D*\d/, "Password must contain a number"),
    passwordConfirm: Yup.string()
      // .required("Password confirmation field is required")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().required("Password confirmation field is required"),
      })
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });

  const initialValues = {
    password: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: resetSchema,
    onSubmit: (values) => {},
  });

  useEffect(() => {
    if (formik.values.password) {
      let params = {
        val: formik.values.password,
        target: "password",
      };
      dispatch(userFieldChage(params));
    }
  }, [formik.values]);

  return (
    <div>
      <UserDialog />
      {listLoading && <ProgressBar />}
      <div className="card border-0">
        <div className="card-header">
          <h4>{title}</h4>
        </div>
        {!listLoading && (
          <div className="card-body">
            <Row>
              {!readOnly && (
                <Button
                  style={{ width: "100px", position: "relative" }}
                  // disabled={actionsLoading}
                  disabled={actionsLoading || !formik.isValid}
                  variant="primary"
                  onClick={handleSave}
                  className="rounded-pill ml-auto"
                >
                  <div className="px-4">
                    {actionsLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        style={{
                          position: "absolute",
                          left: "1rem",
                          top: "1rem",
                        }}
                        aria-hidden="true"
                      />
                    )}
                    Save
                  </div>
                </Button>
              )}
            </Row>
            <Row>
              <Col>
                <Grid container className="input-group mb-5" spacing={1}>
                  <Grid item xs={3} className="d-flex align-items-center">
                    <strong>First name</strong>
                  </Grid>
                  <Grid item xs={9}>
                    {errors?.first_name && (
                      <span className="text-danger">
                        {errors?.first_name[0]}
                      </span>
                    )}
                    <Form.Control
                      readOnly={readOnly}
                      name="first_name"
                      onChange={handleFieldChange}
                      value={user?.first_name}
                      className={errors?.first_name && `border border-danger`}
                      isInvalid={errors?.first_name ? true : false}
                    />
                  </Grid>
                </Grid>
                <Grid container className="input-group mb-5" spacing={1}>
                  <Grid item xs={3} className="d-flex align-items-center">
                    <strong>Last name</strong>
                  </Grid>
                  <Grid item xs={9}>
                    {errors?.last_name && (
                      <span className="text-danger">
                        {errors?.last_name[0]}
                      </span>
                    )}
                    <Form.Control
                      readOnly={readOnly}
                      name="last_name"
                      onChange={handleFieldChange}
                      value={user?.last_name}
                      className={errors?.last_name && `border border-danger`}
                      isInvalid={errors?.last_name ? true : false}
                    />
                  </Grid>
                </Grid>
                <Grid container className="input-group mb-5" spacing={1}>
                  <Grid item xs={3} className="d-flex align-items-center">
                    <strong>Access</strong>
                  </Grid>
                  <Grid item xs={9}>
                    <Select
                      isDisabled={readOnly}
                      name="access"
                      onChange={handleSelectChange}
                      options={permissions.list}
                      value={permissions.list.filter((permission) => {
                        if (permission.name == user?.access) {
                          return permission;
                        }
                      })}
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => option.name}
                      aria-labelledby="aria-label"
                      inputId="aria-example-input"
                      // onMenuOpen={onMenuOpen}
                      // onMenuClose={onMenuClose}
                      placeholder="Access List"
                      theme={(theme) => ({
                        ...theme,
                        // borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: "#ffe5e5",
                          primary: "#ff8a93",
                        },
                      })}
                    />
                  </Grid>
                </Grid>
                <Grid container className="input-group mb-5" spacing={1}>
                  <Grid item xs={3} className="d-flex align-items-center">
                    <strong>Email</strong>
                  </Grid>
                  <Grid item xs={9}>
                    {errors?.email && (
                      <span className="text-danger">{errors?.email[0]}</span>
                    )}
                    <Form.Control
                      readOnly={readOnly}
                      name="email"
                      onChange={handleFieldChange}
                      value={user?.email}
                      className={errors?.email && `border border-danger`}
                      isInvalid={errors?.email ? true : false}
                    />
                  </Grid>
                </Grid>
                {!readOnly && (
                  <>
                    <Grid container className="input-group mb-5" spacing={1}>
                      <Grid item xs={3} className="d-flex align-items-center">
                        <strong>Password</strong>
                      </Grid>
                      <Grid item xs={9}>
                        {errors?.password && (
                          <span className="text-danger">
                            {errors?.password[0]}
                          </span>
                        )}
                        <span className="d-flex text-danger">
                          {formik.errors.password}
                        </span>
                        <InputGroup>
                          <Form.Control
                            // isInvalid={formik.errors?.password ? true : false}
                            type={showPassword ? "text" : "password"}
                            name="password"
                            // onChange={handleFieldChange}
                            {...formik.getFieldProps("password")}
                            //   value={user?.password}
                            placeholder="********"
                            autoComplete="new-password"
                            defaultValue={user?.password}
                            className={
                              errors?.password && `border border-danger`
                            }
                            // isInvalid={errors?.password ? true : false}
                          />
                          <a
                            href="javascript:void(0)"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <i
                              className={`fa ${
                                showPassword ? "fa-eye" : "fa-eye-slash"
                              }`}
                              style={{
                                position: "absolute",
                                right: "15px",
                                top: "20px",
                                zIndex: 5,
                              }}
                            ></i>
                          </a>
                        </InputGroup>
                      </Grid>
                    </Grid>
                    <Grid container className="input-group mb-5" spacing={1}>
                      <Grid item xs={3} className="d-flex align-items-center">
                        <strong>Confirm Password</strong>
                      </Grid>
                      <Grid item xs={9}>
                        <span className="d-flex text-danger">
                          {formik.errors.passwordConfirm}
                        </span>

                        <InputGroup>
                          <Form.Control
                            // isInvalid={formik.errors?.password ? true : false}
                            type={showConfirmPassword ? "text" : "password"}
                            name="passwordConfirm"
                            // onChange={handleFieldChange}
                            {...formik.getFieldProps("passwordConfirm")}
                            //   value={user?.password}
                            placeholder="********"
                            autoComplete="passwordConfirm"
                            defaultValue={user?.passwordConfirm}
                            className={
                              errors?.passwordConfirm && `border border-danger`
                            }
                            // isInvalid={errors?.password ? true : false}
                          />
                          <a
                            href="javascript:void(0)"
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                          >
                            <i
                              className={`fa ${
                                showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                              }`}
                              style={{
                                position: "absolute",
                                right: "15px",
                                top: "20px",
                                zIndex: 5,
                              }}
                            ></i>
                          </a>
                          {errors?.passwordConfirm && (
                            <span className="position-absolute text-danger">
                              {errors?.password[0]}
                            </span>
                          )}
                        </InputGroup>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Col>
              <Col>
                <Grid
                  container
                  className="input-group mb-5 text-center"
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <input
                      accept="image/*"
                      id="contained-button-file"
                      hidden
                      ref={imageRef}
                      type="file"
                      name="photo_file"
                      onChange={handleImageChange}
                    />
                    <Image
                      // src={toAbsoluteUrl("/media/images/default-exercise.png")}
                      src={previewImage ? previewImage : defaultImg}
                      roundedCircle
                      // fluid
                      // width="30%"
                      width="200px"
                      height="200px"
                      style={{ border: "2px solid #ff8a93" }}
                    />
                    {!readOnly && (
                      <a href="javascript:void(0)" onClick={handleImageButton}>
                        <SVG
                          style={{
                            width: "20px",
                            position: "absolute",
                            bottom: 0,
                          }}
                          fill="currentColor"
                          src={toAbsoluteUrl("/media/svg/icons/edit.svg")}
                        />
                      </a>
                    )}
                  </Grid>
                </Grid>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
}
