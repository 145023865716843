import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Row, Col } from "react-bootstrap";

const PreviousExportClassesComponent = () => {
  const { perviousExportData } = useSelector((state) => state.courses);

  const [tablizedClassList, setTablizedClasslist] = useState({
    headers: [],
    rows: [],
  });

  useEffect(() => {
    if (perviousExportData) {
      tablizeClassList(perviousExportData["data"]);
    }
  }, [perviousExportData]);

  const markAsBg = (markId) => {
    if (markId == 1) {
      return "#d9d9d9";
    }
    if (markId == 2) {
      return "#93a4ff";
    }
    if (markId == 3) {
      return "#038500";
    }
    if (markId == 4) {
      return "#ffba53";
    }
    if (markId == 5) {
      return "#f481db";
    }
  };

  const tablizeClassList = (classes) => {
    let headers = [];
    let rowTimeRange = [];
    let tempClasses = [];
    let rows = [];

    // add timeRanges for each class
    classes.map((cls) => {
      let claz = cls;
      let timeRange = cls.time;
      // moment(cls.StartDateTime).format("LT") +
      // " - " +
      // moment(cls.EndDateTime).format("LT");
      claz = { ...claz, timeRange };
      tempClasses = [...tempClasses, claz];
    });

    // order by startdatetime
    tempClasses.sort((a, b) => moment(a.start_time) - moment(b.start_time));

    // set headers
    tempClasses.filter((cls) =>
      !headers.includes(moment(cls.start_date).format("LL"))
        ? (headers = [...headers, moment(cls.start_date).format("LL")])
        : ""
    );

    // set first row values
    tempClasses.filter((cls) =>
      !rowTimeRange.includes(cls.time)
        ? (rowTimeRange = [...rowTimeRange, cls.time])
        : ""
    );

    // order by time range
    let tempTimesArr = [];
    let sortedTimeRange = [];
    // make time ranges into timestamps
    rowTimeRange.map((timeRange, index) => {
      let tempSingleTime = timeRange.split("-");
      tempTimesArr = [
        ...tempTimesArr,
        {
          index: index,
          time: moment(tempSingleTime[0].trim(), "hh:mm a").unix(),
        },
      ];
    });
    // sort by time
    tempTimesArr.sort((a, b) => moment(a.time) - moment(b.time));
    // assign original timerange to temp array
    tempTimesArr.map((tempTime) => {
      // console.log(rowTimeRange[tempTime.index]);
      sortedTimeRange = [...sortedTimeRange, rowTimeRange[tempTime.index]];
    });

    // rowTimeRange.sort((a,b)=> moment(a,b));

    // create table data
    sortedTimeRange.map((rtr) => {
      let newtd = {
        timeRange: rtr,
        td: [],
      };
      headers.map((headDate) => {
        let tdList = [];
        tempClasses.map((tc) => {
          if (
            moment(tc.start_date).format("LL") == headDate &&
            tc.time == rtr
          ) {
            let studioData = {
              studio: tc.location,
              classes: [tc],
            };
            tdList = [...tdList, studioData];
            // tdList = [...tdList, [tc.location][tc]];
          }
        });
        newtd.td = [...newtd.td, tdList];
      });

      rows = [...rows, newtd];
    });

    setTablizedClasslist((tablizeClassList) => ({
      ...tablizeClassList,
      headers: headers,
      rows: rows,
    }));
  };
  return (
    <div
      className="d-flex"
      style={{
        minHeight: "600px",
        maxHeight: "550px",
        overflow: "auto",
        border: "1px solid",
      }}
    >
      {tablizedClassList.length !== 0 ? (
        <div>
          <table id="classes-table">
            <colgroup>
              <col></col>
              {tablizedClassList.headers.map((header) => (
                <>
                  <col></col>
                </>
              ))}
            </colgroup>
            <thead>
              <th></th>
              {tablizedClassList.headers.map((header) => (
                <th className="p-5 text-center">
                  {moment(header).format("dddd")}, {moment(header).format("LL")}
                </th>
              ))}
            </thead>
            <tbody>
              {tablizedClassList.rows.map((row) => (
                <tr>
                  <td
                    className="p-2 border-bottom border-dark"
                    style={{ minWidth: "100px", maxWidth: "100px" }}
                  >
                    {row.timeRange}
                  </td>
                  {row.td.map((td) => (
                    <td
                      className="p-5 border-bottom border-dark"
                      style={{ minWidth: "300px" }}
                    >
                      <Row className="d-flex justify-content-start">
                        {td.map((d) => (
                          <Col lg={10} className="mx-2">
                            {d.classes.map((cls) => (
                              <>
                                <Row className="d-flex justify-content-center">
                                  {cls.studio}
                                </Row>
                                <Row>
                                  <Col lg={3}>
                                    <Row className="d-flex justify-content-center">
                                      <span className="p-3">
                                        {cls.patients.length}/2
                                      </span>
                                    </Row>
                                    <Row className="d-flex justify-content-center">
                                      <p
                                        className="border rounded-circle p-3"
                                        style={{ backgroundColor: "#d9d9d9" }}
                                      >
                                        {cls.physio_name
                                          // .replace(/[^a-zA-Z]/, "") //replace special characters and numbers with space
                                          .split(" ")
                                          //
                                          .map((name, index) => {
                                            if (index < 2) {
                                              return name
                                                .substring(0, 1)
                                                .toUpperCase();
                                            }
                                          })}
                                      </p>
                                    </Row>
                                  </Col>
                                  <Col lg={9}>
                                    {cls?.patients.map((patient, index) => (
                                      <Row>
                                        <Col
                                          lg={9}
                                          className="border p-3"
                                          style={{
                                            backgroundColor: markAsBg(
                                              patient.mark_as_id
                                            ),
                                          }}
                                        >
                                          <span>
                                            {patient.FirstName}{" "}
                                            {patient.LastName}
                                          </span>
                                        </Col>

                                        <Col
                                          lg={3}
                                          className="d-flex align-items-center"
                                        >
                                          {/* </div> */}
                                        </Col>
                                      </Row>
                                    ))}
                                  </Col>
                                </Row>
                              </>
                            ))}
                          </Col>
                        ))}
                      </Row>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PreviousExportClassesComponent;
