import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";

import CourseTable from "../components/CoursesTable";
import ExercisePlansTable from "../components/ExercisePlansTable";
import PreviousExportsTable from "../components/PreviousExportsTable";
import Filter from "../../Other/Filter";
// reudx
import { useDispatch, useSelector } from "react-redux";
// import { actions } from "../_redux/courseRedux";
import * as actions from "../_redux/coursesActions";
import { fetchHolidays } from "../_redux/holiday/holidaysActions";

const CoursePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchCourses());
    dispatch(actions.fetchExercisePlans());
    dispatch(actions.fetchLatesDraft());
    dispatch(actions.fetchBlockDates());
    dispatch(actions.fetchPreviousRecords());
    dispatch(fetchHolidays());
  }, [dispatch]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Filter />
        </Grid>
        <Grid item xs={12}>
          <CourseTable />
        </Grid>
        <Grid item xs={12}>
          <PreviousExportsTable />
          {/* <ExercisePlansTable /> */}
        </Grid>
      </Grid>
    </>
  );
};

export default CoursePage;
