import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import ProgressBar from "../../Other/ProgressBar";
import { Image } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import moment from "moment";
// import ArchiveExerciseDialog from "./dialog-bars/ArchiveExerciseDialog";
import SVG from "react-inlinesvg";

// redux
import { useSelector, useDispatch } from "react-redux";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  {
    id: "first_name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    // label: "Dessert (100g serving)",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "access",
    numeric: true,
    disablePadding: false,
    label: "Access",
  },
  {
    id: "updated_at",
    numeric: true,
    disablePadding: false,
    label: "Last updated",
  },
  {},
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "Select all desserts" }}
          /> */}
        </TableCell>
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            // align={row.numeric ? "right" : "left"}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {/* <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Nutrition
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div> */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

export default function EnhancedTable({ list }) {
  const dispatch = useDispatch();
  const { users, auth } = useSelector((state) => state);

  const { listLoading } = users;
  const { user } = auth;

  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [archiveSelect, setArchiveSelect] = useState(null);
  const [archiveDialog, setArchiveDialog] = useState(false);
  const defaultImg = toAbsoluteUrl("/media/images/default-profile.png");

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = list.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleClick(event, name) {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    // console.log(page);
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  function handleChangeDense(event) {
    setDense(event.target.checked);
  }

  function archiveExercise(exercise_id) {
    // console.log(id);
    setArchiveSelect(exercise_id);
    setArchiveDialog(true);
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, list.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      {/* <ArchiveExerciseDialog
        archiveDialog={archiveDialog}
        setArchiveDialog={setArchiveDialog}
        exercise_id={archiveSelect}
      /> */}
      {/* <Paper className={classes.paper}> */}
      {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={list.length}
          />
          <TableBody>
            {listLoading ? (
              <ProgressBar />
            ) : list.length > 0 ? (
              stableSort(list, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.name)}
                      // role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.exercise_id}
                      selected={isItemSelected}
                    >
                      <TableCell className="px-3">
                        {/* {row.variations[0]?.image_link ? (
                          <Image
                            src={row.variations[0].image_link}
                            rounded
                            height={100}
                            width={100}
                          />
                        ) : ( */}
                        <Image
                          src={row.photo ? row.photo : defaultImg}
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50%",
                          }}
                          rounded
                          width={100}
                        />
                        {/* )} */}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.first_name} {row.last_name}
                      </TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.access}</TableCell>
                      <TableCell>
                        {moment(row.updated_at).format("ll")}
                      </TableCell>
                      <TableCell>
                        <Link
                          component={RouterLink}
                          to={
                            `/users/${row.id}`
                            // row.id == user.id
                            //   ? `my-profile`
                            //   : `/users/${row.id}`
                          }
                          color="inherit"
                          className="pr-3"
                        >
                          {/* <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 svg-icon-sort"> */}
                          <SVG
                            style={{ width: "24px" }}
                            fill="currentColor"
                            src={toAbsoluteUrl("/media/svg/icons/notes.svg")}
                          />
                          {/* </span> */}
                        </Link>
                        <Link
                          component={RouterLink}
                          color="inherit"
                          className="pr-3"
                          to={`/users/${row.id}/read-only`}
                        >
                          {/* <Link component={RouterLink} to="/users/details" color="inherit" className="pr-3">  */}
                          {/* <span className="svg-icon svg-icon-sm svg-icon-primary svg-icon-sort"> */}
                          <SVG
                            style={{ width: "24px" }}
                            fill="currentColor"
                            src={toAbsoluteUrl(
                              "/media/svg/icons/eye-solid.svg"
                            )}
                          />
                          {/* </span> */}
                        </Link>
                        <Link
                          color="inherit"
                          className="pr-3"
                          onClick={() => console.log("nice click")}
                        >
                          {/* <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 svg-icon-sort"> */}
                          <SVG
                            fill="currentColor"
                            style={{ width: "24px", height: "22px" }}
                            src={toAbsoluteUrl(
                              "/media/svg/icons/trash-fill.svg"
                            )}
                          />
                          {/* </span> */}
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })
            ) : (
              <tr>
                <td colSpan={7} align="middle" className="p-5">
                  No exercise data found.
                </td>
              </tr>
            )}
            {/* {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
          </TableBody>
        </Table>
      </div>
      {!listLoading && (
        <>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={list.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          {/* <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          /> */}
        </>
      )}{" "}
    </div>
  );
}
