import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
  Image,
  Form,
} from "react-bootstrap";
import { Checkbox } from "@material-ui/core";
import Select from "react-select";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";

const CustomSelect = (props) => {
  return (
    <Select
      {...props}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "#ffe5e5",
          primary: "#ff8a93",
        },
      })}
    />
  );
};

const SelectExerciseRow = ({
  exercise,
  handleAddExercise,
  handleRemoveExercise,
  handleExerciseFieldChange,
}) => {
  const [checked, setChecked] = useState(false);
  const [variation, setVariation] = useState();

  useEffect(() => {
    checked && handleAddExercise(exercise);

    !checked && handleRemoveExercise(exercise.id);
  }, [checked]);

  const handleCheckbox = (e) => {
    setChecked(!checked);
  };

  const fieldChange = (e) => {
    const target = e.target.name;
    const val = e.target.value;
    handleExerciseFieldChange(target, val, exercise.id);
  };

  const variationChange = (e) => {
    // console.log(e);
    setVariation(e);
    const target = "variations";
    const val = e;
    handleExerciseFieldChange(target, [val], exercise.id);
  };

  return (
    <Row className="p-2 ">
      <Col lg={1}>
        <Checkbox
          checked={checked}
          onChange={handleCheckbox}
          className="text-primary"
          // onChange={() => setChecked(!checked)}
          color="default"
          inputProps={{
            "aria-label": "checkbox with default color",
          }}
        />
      </Col>

      <Col lg={11}>
        <Row className="py-2">
          <Col>{exercise.name}</Col>
        </Row>
        <Row>
          <Col>
            Description goes here about the exercise and things and stuff from
            the exercise library
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SelectExerciseRow;
