import React, { useState, useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import {
  InputGroup,
  FormControl,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";
// import { searchExercise } from "../../../_redux/exercisePlanCrud";
import { searchExercise } from "../../../../ExercisePlans/_redux/exercisePlanCrud";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { useSelector } from "react-redux";

const CustomSelect = (props) => {
  return (
    <Select
      {...props}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "#ffe5e5",
          primary: "#ff8a93",
        },
      })}
    />
  );
};

const SearchStep = (props) => {
  const { filters } = useSelector((state) => state.exercisePlans);
  const [selectedBodyPart, setSelectedBodyPart] = useState();
  const [loading, setLoading] = useState(false);

  const defaultFilters = {
    name: "",
    body_part: null,
    equipment: null,
    condition: null,
    props: null,
    level: null,
    contraindication: null,
  };

  const [selectedFilters, setSelectedFilters] = useState(defaultFilters);

  const handleSelectedFilter = (val, target) => {
    console.log(val, target);
    setSelectedFilters((prev) => ({
      ...prev,
      [target]: val,
    }));
  };

  const handleClearFilters = () => {
    setSelectedFilters(defaultFilters);
  };
  const generateUID = () => {
    let dt = new Date().getTime();
    let uid = `xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxxx`.replace(/[xy]/g, function(
      c
    ) {
      let r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });

    return uid;
  };

  const handleSearch = () => {
    // console.log(selectedBodyPart);
    let payload = {
      name: selectedFilters.name ? selectedFilters.name : undefined,
      body_part: selectedFilters.body_part?.id ?? undefined,
      equipment: selectedFilters.equipment?.id ?? undefined,
      condition: selectedFilters.condition?.value ?? undefined,
      props: selectedFilters.props?.id ?? undefined,
      level: selectedFilters?.level ?? undefined,
      contraindication: selectedFilters.contraindication?.value ?? undefined,
    };
    // console.log(payload);
    setLoading(true);
    searchExercise(payload)
      .then((res) => {
        let formatExercises = []; //make variations empty and rename it to variation_list
        // res.data.map();
        if (res?.data.exercises.length > 0) {
          res.data.exercises.map((exercise) => {
            let uid = generateUID();
            exercise.variation_options = exercise.variations;
            exercise.variations = [];
            exercise.checked = false;
            exercise.favourites = false;
            exercise.hide = {
              value: false,
              exercise_plan_with_patient_id: null,
            };
            exercise.exercise_id = exercise.id;
            exercise.uid = uid;
            delete exercise.id;
            formatExercises = [...formatExercises, exercise];
          });
        }
        console.log(formatExercises);
        props.setSearchedExercises(formatExercises);
        props.handleNextStep();
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  const optionsArrayString = (options) => {
    let opts = [];
    options &&
      options.map((option) => {
        option = {
          label: option,
          value: option,
        };
        opts = [...opts, option];
      });

    return opts;
  };

  return (
    <div>
      <Row className=" p-2">
        <Col lg={4}>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text className="border-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              // disabled={disabled}
              onChange={(e) => {
                handleSelectedFilter(e.target.value, "name");
              }}
              placeholder="Search"
              className="border-0"
              style={{ backgroundColor: "#f2f4f8" }}
              value={selectedFilters.name}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row className="p-2">
        <Col lg={12}>Filters</Col>
      </Row>
      <Row className="p-2">
        <Col lg={6}>
          <Row className="mb-3 justify-content-center">
            <Col lg={10}>
              <CustomSelect
                placeholder={"Equipment"}
                options={filters.equipments}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => option.id}
                name="equipment"
                onChange={(option) => handleSelectedFilter(option, "equipment")}
                value={selectedFilters.equipment}
              />
            </Col>
          </Row>
          <Row className="mb-3  justify-content-center">
            <Col lg={10}>
              <CustomSelect
                placeholder={"Conditions"}
                options={optionsArrayString(filters.conditions)}
                name="condition"
                onChange={(option) => handleSelectedFilter(option, "condition")}
                value={selectedFilters.condition}
                // getOptionLabel={(option) => `${option.name}`}
                // getOptionValue={(option) => option.id}
              />
            </Col>
          </Row>
          <Row className="mb-3  justify-content-center">
            <Col lg={10}>
              {" "}
              <CustomSelect
                placeholder={"Props"}
                options={filters.props}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => option.id}
                name="props"
                onChange={(option) => handleSelectedFilter(option, "props")}
                value={selectedFilters.props}
              />
            </Col>
          </Row>
          <Row className="mb-3 justify-content-center">
            <Col lg={10}>
              <ButtonToolbar>
                <ToggleButtonGroup
                  type="radio"
                  name="options"
                  defaultValue={null}
                  style={{ zIndex: 0 }}
                  onChange={(option) => handleSelectedFilter(option, "level")}
                  value={selectedFilters.level}
                >
                  <ToggleButton value="basic">Bas</ToggleButton>
                  <ToggleButton value="intermediate">Int</ToggleButton>
                  <ToggleButton value="advanced">Adv</ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
            </Col>
          </Row>
          <Row className="mb-3 justify-content-center">
            <Col lg={10}>
              {" "}
              <CustomSelect
                placeholder={"Contraindications"}
                options={optionsArrayString(filters.contraindications)}
                name="contraindication"
                onChange={(option) =>
                  handleSelectedFilter(option, "contraindication")
                }
                value={selectedFilters.contraindication}
              />
            </Col>
          </Row>
          <Row className="mb-3 justify-content-center">
            <Col lg={10}>
              <a href="javascript:void(0)" onClick={handleClearFilters}>
                Clear filters
              </a>
            </Col>
          </Row>
        </Col>
        <Col lg={6}>
          <Row className="justify-content-center mb-3">
            <Col lg={8}>
              {" "}
              <CustomSelect
                placeholder={"Select body area"}
                // onChange={(val) => {
                //   setSelectedBodyPart(val.value);
                // }}
                name="body_part"
                onChange={(option) => handleSelectedFilter(option, "body_part")}
                options={filters.body_parts}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => option.id}
                value={selectedFilters.body_part}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={9}>
              <img
                src={toAbsoluteUrl("/media/images/body_map_divs.png")}
                alt=""
                className="w-100"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        className="justify-content-center position-absolute w-100 pb-5"
        style={{ bottom: 0 }}
      >
        <Col lg={2}>
          <button
            disabled={loading}
            className="btn btn-secondary text-primary rounded-pill w-100"
            onClick={() => props.onHide()}
          >
            Cancel
          </button>
        </Col>
        <Col lg={2}>
          <button
            disabled={loading}
            className="btn btn-primary text-white rounded-pill w-100"
            onClick={() => handleSearch()}
          >
            {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                style={{
                  position: "absolute",
                  left: "2rem",
                  top: "1rem",
                }}
                aria-hidden="true"
              />
            )}
            Search
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default SearchStep;
