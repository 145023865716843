import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

const RowCollapse = ({ patients, schedule_id, class_id }) => {
  return (
    <Card className="p-5">
      {/* <Card.Body> */}
      <Row className="my-2 border-bottom">
        <Col>
          <strong>Patient name</strong>
        </Col>
        <Col>
          <strong>Plan status</strong>
        </Col>
        <Col></Col>
      </Row>
      {patients.map((patient) => (
        <Row className="mt-2">
          <Col>{patient.patient_full_name}</Col>
          <Col>
            {patient.is_published ? (
              <>
                Published{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="green"
                  class="bi bi-check-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
              </>
            ) : (
              "Draft"
            )}
          </Col>
          {/* <Col>{patient.id}</Col> */}
          <Col>
            <Link
              component={RouterLink}
              to={`/clinical-rehab-classes/exercise-plans/${schedule_id}/${class_id}?patient_id=${patient.id}`}
              color="inherit"
              className="pr-3"
            >
              <SVG
                style={{ width: "16px" }}
                fill="currentColor"
                src={toAbsoluteUrl("/media/svg/icons/notes.svg")}
              />
            </Link>
          </Col>
        </Row>
      ))}
      {/* <Card.Text>
          With supporting text below as a natural lead-in to additional content.
        </Card.Text> */}
      {/* <Button variant="primary">Go somewhere</Button> */}
      {/* </Card.Body> */}
    </Card>
  );
};

export default RowCollapse;
