import React from "react";
import { Row, Col } from "react-bootstrap";

import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

const ExerciseRow = ({ noteUI, handleNote, exercise }) => {
  return (
    <Row className="mb-3 align-items-center">
      <Col lg={2}>
        <Row>
          <Col>{exercise?.name}</Col>
        </Row>
      </Col>
      <Col lg={10}>
        <Row>
          <Col lg={10}>
            <Row>
              <Col lg={1}></Col>
              <Col lg={11}>
                <Row>
                  <Col lg={12}>
                    <Row>
                      {/* foreah  */}
                      {exercise?.weeks.map((i, index) => (
                        <Col>
                          {/* Wk {index + 1} */}
                          {/* <Row>
                            <Col>
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/notes-check.svg"
                                )}
                              />
                            </Col>
                          </Row> */}
                          <Row className="justify-content-center">
                            <Col lg={5}>
                              {i?.notes || i?.files ? (
                                <a
                                  href="javascript:void(0)"
                                  onClick={() => handleNote(i)}
                                >
                                  <SVG
                                    fill="black"
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/chat-left.svg"
                                    )}
                                  />
                                </a>
                              ) : (
                                ""
                              )}
                            </Col>
                            <Col lg={5}>
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/circle-dash.svg"
                                )}
                              />
                            </Col>
                          </Row>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ExerciseRow;
