import moment from "moment";
import { clinicalRehabSlice } from "./clincalRehabSlice";
import * as requestFromServer from "./clinicalRehabCrud";

const { actions } = clinicalRehabSlice;

export const fetchWeeks = (dateRange, dates) => (dispatch) => {
  dispatch(actions.startCall("weeksLoading"));

  requestFromServer
    .getWeeks(dateRange)
    .then((res) => {
      //   console.log(res);
      const { weeks } = res?.data;
      console.log();
      let weeksArr = [];

      let keys = Object.keys(weeks);

      for (let i = 0; i < keys.length; i++) {
        let k = keys[i];

        const startF = moment(weeks[k].start).format("DD/MM/YY");
        const endF = moment(weeks[k].end).format("DD/MM/YY");
        const range = `${startF} - ${endF}`;

        const weekArr = {
          week: k,
          start: weeks[k].start,
          end: weeks[k].end,
          range: range,
        };
        weeksArr = [...weeksArr, weekArr];
      }

      dispatch(actions.weeksFetched(weeksArr));
    })
    .catch((err) => {
      dispatch(
        actions.catchError({
          error: err.response.data.message,
        })
      );
    });
};

export const setActiveWeek = (weekIndex) => (dispatch) => {
  console.log(weekIndex);
  dispatch(actions.weekSet(weekIndex));
};

export const fetchClinicalRehabList = (activeWeek) => (dispatch) => {
  dispatch(actions.startCall("listLoading"));

  requestFromServer
    .getRehabList(activeWeek)
    .then((res) => {
      dispatch(actions.rehabListFetched(res?.data));
    })
    .catch((err) => {
      dispatch(
        actions.catchError({
          error: err.message,
        })
      );
    });
};

export const statusReset = () => (dispatch) => {
  dispatch(actions.resetStatus());
};

export const fetchExercisePlans = () => (dispatch) => {
  dispatch(actions.startCall("listLoading"));

  requestFromServer
    .getExercisePlans()
    .then((res) => {
      dispatch(actions.exercisePlansFetched(res.data));
    })
    .catch((err) => {
      dispatch(
        actions.catchError({
          error: err.response.data.message,
        })
      );
    });
};

export const fetchPatientNOtes = (patient_id) => (dispatch) => {
  dispatch(actions.startCall("patientNotesLoading"));

  requestFromServer
    .getPatientNotes(patient_id)
    .then((res) => {
      // console.log(res);
      let data = { ...res.data };

      let nTags = [];

      data.case = "Clinical rehab";

      data.tags.map((tag) => {
        const newTag = {
          label: tag.name,
          value: tag.name,
          // isSelected: tag.isSelected,
        };
        if (tag.isSelected == true) {
          nTags = [...nTags, newTag];
        }
      });

      data.tags = nTags;

      dispatch(actions.patientNotesFetched(data));
    })
    .catch((err) => {
      dispatch(
        actions.catchError({
          error: err.response.data.message,
        })
      );
    });
};
export const fetchPatientHistory = (patient_id) => (dispatch) => {
  dispatch(actions.startCall("historyLoading"));

  requestFromServer
    .getPatientHistory(patient_id)
    .then((res) => {
      // console.log(res);
      dispatch(actions.patientHistoryFetched(res.data));
    })
    .catch((err) => {
      dispatch(
        actions.catchError({
          error: err.response.data.message,
        })
      );
    });
};

export const handleFieldChange = (payload) => (dispatch) => {
  // console.log(payload);
  dispatch(actions.noteFieldChanged(payload));
};

export const handleNoteSave = (payload) => (dispatch) => {
  dispatch(actions.startCall("noteSaveLoading"));

  requestFromServer
    .savePatientNotes(payload)
    .then((res) => {
      // console.log(res);
      dispatch(actions.noteSaved(res));
    })
    .catch((err) => {
      dispatch(
        actions.catchError({
          error: err.response.data.message,
        })
      );
    });
};
