import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { confirmConflictError } from "../_redux/coursesActions";

const RequestConflictModal = () => {
  const dispatch = useDispatch();
  const { requestConflict, requestConflictMessage } = useSelector(
    (state) => state.courses
  );

  const handleCloseModal = () => {
    // console.log("close");
    dispatch(confirmConflictError());
  };

  return (
    <Modal
      show={requestConflict}
      //   onHide={handleCloseModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Body>
        <Row className="d-flex justify-content-center my-5">
          <Col lg={11}>
            <span>{requestConflictMessage}</span>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col lg={4}>
            <button
              className="btn btn-primary text-white rounded-pill w-100"
              onClick={() => handleCloseModal()}
            >
              Confirm
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default RequestConflictModal;
