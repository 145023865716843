import React, { useState, useEffect } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useFormik } from "formik";
import { Form, Row, Col, Spinner, Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { resetPassword } from "../_redux/authCrud";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export default function ResetPassword() {
  const { reset_email } = useSelector((state) => state.auth);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const resetSchema = Yup.object().shape({
    token: Yup.string().required("Reset code is required"),
    password: Yup.string()
      .required("Password field is required")
      .matches(/(?=.*[A-Z])/, "Password must contain a capital letter")
      .matches(
        /^(?=.*[!@#\$%\^&\*])/,
        "Password must contain a special character"
      )
      .matches(/^(?=.{6,20}$)\D*\d/, "Password must contain a number"),
    passwordConfirm: Yup.string()
      .required("Password confirmation field is required")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });

  const initialValues = {
    email: reset_email,
    token: "",
    password: "",
    passwordConfirm: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: resetSchema,
    onSubmit: (values) => {
      setLoading(true);
      //   console.log("atay");
      //   console.log("values", values);
      resetPassword(values)
        .then((res) => {
          setSuccess(true);
          //   history.push("/auth");
          setLoading(false);
          //   console.log(res);
        })
        .catch((err) => {
          setLoading(false);
          setError(true);
          setErrorMessage(err.response.data.msg);
          // console.log(err.response.data);
        });
    },
  });

  const closeError = () => {
    setError(false);
    // setError({
    //   ...error,
    //   show: false,
    //   message: "",
    // });
  };

  const proceedToLogin = () => {
    history.push("/auth");
  };

  useEffect(() => {
    if (reset_email == null) {
      history.push("/auth/forgot-password");
    }
  }, [reset_email]);

  return (
    <div
      className="h-100"
      style={{
        background: "linear-gradient(#f0cdc9, #fbf1f0)",
        overflowX: "hidden",
      }}
    >
      <Modal
        show={error}
        onHide={closeError}
        backdrop="static"
        // keyboard={false}
        centered={true}
      >
        <Modal.Body>
          <Row className="text-center mb-5">
            <Col lg={12}>
              <h4>{errorMessage}</h4>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="d-flex">
              <Button className="btn btn-primary m-auto" onClick={closeError}>
                Close
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        show={success}
        // onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={true}
      >
        <Modal.Body>
          <Row className="text-center mb-5">
            <Col lg={12}>
              <h4>Password reset success!</h4>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="d-flex">
              <Button
                className="btn btn-primary m-auto"
                onClick={proceedToLogin}
              >
                Proceed to login
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <div className="row mb-5">
        <Col lg={12}>
          <Row className="m-auto align-items-center">
            <img
              alt="Logo"
              className="img-fluid top-0"
              style={{ width: "110px" }}
              // src={toAbsoluteUrl("/media/logos/inspire-logo-white.png")}
              src={toAbsoluteUrl("/media/logos/logo-only.png")}
            />
            <span style={{ fontSize: "60px" }}>I N S P I R E</span>
          </Row>
        </Col>
      </div>
      <div className="d-flex justify-content-center mt-5">
        <form className="col-lg-3 text-center">
          <div className="form-group">
            <h3>New Password</h3>
          </div>
          <div className="form-group">
            <h4>Check your email to view your reset code</h4>
          </div>
          <div className="form-group">
            <span className="d-flex text-danger">{formik.errors.token}</span>
            <Form.Control
              isInvalid={formik.errors?.token ? true : false}
              name="token"
              {...formik.getFieldProps("token")}
              type="text"
              className="form-control"
              placeholder="Enter reset code"
            />
          </div>
          <div className="form-group">
            <h3>Enter a new password</h3>
          </div>
          {/* <h4>Password must contain:</h4>
          <div className="form-group d-flex justify-content-center">
            <div className="col-lg-6 text-left">
              <ul>
                <li>at least 8 characters</li>
                <li>a capital letter</li>
                <li>a number</li>
                <li>a special character</li>
              </ul>
            </div>
          </div> */}
          <div className="form-group">
            <span className="d-flex text-danger">{formik.errors.password}</span>
            <Form.Control
              isInvalid={formik.errors?.password ? true : false}
              name="password"
              {...formik.getFieldProps("password")}
              type="password"
              className="form-control"
              placeholder="Enter password"
            />
          </div>
          <div className="form-group">
            <span className="d-flex text-danger">
              {formik.errors.passwordConfirm}
            </span>
            <Form.Control
              isInvalid={formik.errors?.passwordConfirm ? true : false}
              name="passwordConfirm"
              {...formik.getFieldProps("passwordConfirm")}
              type="password"
              className="form-control"
              placeholder="Confirm password"
            />
          </div>
          <div className="form-group">
            <button
              disabled={loading}
              type="submit"
              onClick={formik.handleSubmit}
              className="btn w-100 mb-2 text-white d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#393940" }}
            >
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  // style={{
                  //   position: "absolute",
                  //   left: "1rem",
                  //   top: "1.25rem",
                  // }}
                  aria-hidden="true"
                />
              )}
              Confirm password reset
            </button>
            <button className="btn btn-primary w-100">
              {" "}
              Send me a new code
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
