import React from "react";
import { Row } from "react-bootstrap";
import ExerciseRow from "./ExerciseRow";

const FavoriteExercises = ({ exercises, patient_id, readOnly }) => {
  return (
    <div className=" my-5">
      <Row className="justify-content-center mb-5">
        <span style={{ fontSize: "20px" }}>Favourites</span>
      </Row>
      <div className="mb-5">
        {exercises.length > 0 ? (
          exercises.map((exercise) => (
            <ExerciseRow
              exercise={exercise}
              patient_id={patient_id}
              readOnly={readOnly}
            />
          ))
        ) : (
          <Row className="justify-content-center">No exercises found</Row>
        )}
      </div>
    </div>
  );
};

export default FavoriteExercises;
