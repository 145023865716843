import React, { useEffect } from "react";
import { Modal, Row, Col, Spinner } from "react-bootstrap";
import { publish } from "../../_redux/exercisePlanActions";
import { useDispatch, useSelector } from "react-redux";

const PublishExerciseModal = (props) => {
  const { publishLoading, isSuccess } = useSelector(
    (state) => state.exercisePlans
  );

  const dispatch = useDispatch();

  const handlePublish = () => {
    const payload = {
      patient_id: props.patient_id,
      class_id: props.class_id,
    };
    dispatch(publish(payload, props.index));
  };

  useEffect(() => {
    isSuccess && props.onHide();
  }, [isSuccess]);

  return (
    <Modal
      {...props}
      size="md"
      centered
      backdrop={publishLoading && "static"}
      keyboard={!publishLoading}
    >
      <Modal.Header>
        <h3 className="m-auto">Publish exercises</h3>
        <a
          href="javascript:void(0)"
          onClick={() => props.onHide()}
          style={{
            pointerEvents: publishLoading && "none",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
        </a>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center mb-5">
          <Col lg={12} className="text-center">
            Are you sure you'd like to publish these exercises?
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={4}>
            <button
              className="btn btn-secondary text-primary rounded-pill w-100"
              onClick={() => props.onHide()}
              disabled={publishLoading}
            >
              Cancel
            </button>
          </Col>
          <Col lg={4}>
            <button
              className="btn btn-primary text-white rounded-pill w-100"
              onClick={handlePublish}
              disabled={publishLoading}
            >
              {publishLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  style={{
                    position: "absolute",
                    left: "2.3rem",
                    top: "0.9rem",
                  }}
                  aria-hidden="true"
                />
              )}
              Publish
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default PublishExerciseModal;
