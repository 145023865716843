import React, { useState, useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Checkbox, CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getProps } from "../api";
import Grid from "@material-ui/core/Grid";
import { InputGroup, FormControl } from "react-bootstrap";

// import { fetchProps, updateProps } from "../../_redux/exercisePlanActions";

const PropsModal = (props) => {
  const dispatch = useDispatch();

  const [propOptions, setPropOptions] = useState([]);
  const [propsLoading, setPropsLoading] = useState(false);
  const [filteredProps, setFilteredProps] = useState([]);
  const [filter, setFilter] = useState("");

  const [modalProps, setModalProps] = useState([]);

  useEffect(() => {
    props.show && setPropsLoading(true);
    // dispatch(fetchProps(props.variation_id, props.patients_exercise_id));
    const fetchProps = dispatch(
      getProps(props.variation_id, props.patient_exercise_id)
    );

    fetchProps
      .then((res) => {
        const { props } = res.data;
        // console.log(res);
        setPropOptions(props);
        setPropsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPropsLoading(false);
      });

    // formatProps(prop_options);
  }, [props.show]);

  useEffect(() => {
    if (props?.selected_props) {
      let newProps = [];

      propOptions.map((po) => {
        const checkProp = props.selected_props.find((sp) => sp.id == po.id);
        let newProp = { ...po };
        if (checkProp) {
          newProp.checked = true;
        } else {
          newProp.checked = false;
        }
        newProps = [...newProps, newProp];
      });

      setModalProps(newProps);
    }
  }, [propOptions]);

  useEffect(() => {
    let newFilteredProps = modalProps.filter((fp) => {
      return fp.name.toLowerCase().includes(filter.toLowerCase());
    });

    setFilteredProps(newFilteredProps);
  }, [modalProps]);

  const filterProps = () => {};

  const handleCheck = (prop_id) => {
    let newProps = [];
    modalProps.map((mp, idx) => {
      if (mp.id == prop_id) {
        mp.checked = !mp.checked;
      }
      newProps = [...newProps, mp];
    });
    setModalProps(newProps);
  };

  const handleSave = () => {
    let checkedProps = [];
    modalProps.map((mp) => {
      if (mp.checked == true) {
        checkedProps = [...checkedProps, mp];
      }
    });

    props.handlePropUpdate(
      props.exercise_index,
      props.variation_index,
      checkedProps
    );

    props.onHide();
  };

  const handleSearchProp = (e) => {
    setFilter(e);
  };

  useEffect(() => {
    let newFilteredProps = modalProps.filter((fp) => {
      return fp.name.toLowerCase().includes(filter.toLowerCase());
    });

    setFilteredProps(newFilteredProps);
  }, [filter]);

  return (
    <Modal {...props} size="md" centered>
      <Modal.Header>
        <h3 className="m-auto">Add/remove prop</h3>
        <a href="javascript:void(0)" onClick={() => props.onHide()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
        </a>
      </Modal.Header>
      <Modal.Body>
        <Row className="d-flex justify-content-center">
          <div style={{ width: "300px" }}>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text className="border-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                // ref={searchInputRef}
                // disabled={disabled}
                onChange={(e) => {
                  handleSearchProp(e.target.value);
                }}
                placeholder="Search"
                className="border-0"
                style={{ backgroundColor: "#f2f4f8" }}
              />
            </InputGroup>
          </div>
        </Row>
        <Row
          className="justify-content-center align-items-center"
          style={{ minHeight: "200px", maxHeight: "400px", overflow: "auto" }}
        >
          {propsLoading ? (
            <CircularProgress
              style={{
                color: "#ff8a93",
              }}
            />
          ) : (
            <Col lg={10}>
              {filteredProps.length > 0 ? (
                filteredProps.map((pr, index) => (
                  <Row className="justify-content-center mb-5 align-items-center">
                    <Col lg={2} className="text-center">
                      <Checkbox
                        onChange={() => handleCheck(pr.id)}
                        // defaultChecked
                        color="default"
                        // value={pr.label}
                        className="text-primary"
                        // value="checkedG"
                        checked={pr.checked}
                        inputProps={{
                          "aria-label": "checkbox with default color",
                        }}
                      />
                    </Col>
                    <Col lg={5}>{pr.name}</Col>
                    <Col lg={2}>
                      <svg
                        width="20"
                        height="20"
                        viewBox={pr?.propsIcon?.properties?.viewBox}
                        fill="black"
                        xmlns={pr?.propsIcon?.properties?.xmlns}
                      >
                        <path
                          d={pr?.propsIcon?.properties?.path}
                          fill="black"
                        />
                      </svg>
                    </Col>
                  </Row>
                ))
              ) : (
                <Row className="justify-content-center mb-5 align-items-center">
                  No props found
                </Row>
              )}
            </Col>
          )}
        </Row>
        <Row className="justify-content-center">
          <Col lg={4}>
            <button
              className="btn btn-secondary text-primary rounded-pill w-100"
              onClick={() => props.onHide()}
            >
              Cancel
            </button>
          </Col>
          <Col lg={4}>
            <button
              className="btn btn-primary text-white rounded-pill w-100"
              onClick={handleSave}
            >
              Save
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default PropsModal;
