import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";

import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import ScheduleNote from "./ScheduleNote";
import ExerciseRow from "./ExerciseRow";
import VariationRow from "./VariationRow";
import {
  fetchPatientHistory,
  fetchPatientNOtes,
} from "../../_redux/clinicalRehabActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ProgressBar from "../../../Other/ProgressBar";
import { useParams } from "react-router-dom";

const NotesScheduleView = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { historyLoading, patientHistory } = useSelector(
    (state) => state.clinicalRehab
  );

  const [noteUI, setNoteUI] = useState(null);

  const [page, setPage] = useState(0);

  const [currentBlock, setCurrentBlock] = useState(null);

  const handleNextPage = () => {
    if (page == patientHistory.length - 1) {
      return;
    }

    const nextPage = page + 1;
    setPage(nextPage);
  };

  const handlePreviousPage = () => {
    if (page == 0) {
      return;
    }

    const prevPage = page - 1;
    setPage(prevPage);
  };

  const handleFirstPage = () => {
    setPage(0);
  };

  const handleLastPage = () => {
    setPage(patientHistory.length - 1);
  };

  useEffect(() => {
    setCurrentBlock(patientHistory[page]);
    setNoteUI(null);
  }, [page]);

  const handleNote = (notes) => {
    setNoteUI(notes);
  };

  useEffect(() => {
    patientHistory.length > 0 && setCurrentBlock(patientHistory[0]);
  }, [patientHistory]);

  useEffect(() => {
    dispatch(fetchPatientHistory(id));
  }, []);

  return (
    <>
      <Col lg={8}>
        <Card.Body
          className="rounded"
          style={{ backgroundColor: "whitesmoke" }}
        >
          {historyLoading ? (
            <div style={{ minHeight: "300px" }}>
              <ProgressBar />
            </div>
          ) : (
            <>
              <Row className="mb-2">
                <Col lg={2}></Col>
                <Col lg={10}>
                  <Row className="align-items-center">
                    <Col lg={1}>
                      <a href="javascript:void(0)" onClick={handleFirstPage}>
                        <SVG
                          fill="dark"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/chevron-double-left.svg"
                          )}
                        />
                      </a>
                      <a href="javascript:void(0)" onClick={handlePreviousPage}>
                        <SVG
                          fill="dark"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/chevron-left.svg"
                          )}
                        />
                      </a>
                    </Col>

                    <Col
                      lg={9}
                      className="py-2"
                      style={{ backgroundColor: "lightgray" }}
                    >
                      <strong>
                        <span>{currentBlock?.name}</span>
                      </strong>
                    </Col>
                    <Col>
                      <a href="javascript:void(0)" onClick={handleNextPage}>
                        <SVG
                          fill="dark"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/chevron-right.svg"
                          )}
                        />
                      </a>
                      <a href="javascript:void(0)" onClick={handleLastPage}>
                        <SVG
                          fill="dark"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/chevron-double-right.svg"
                          )}
                        />
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="my-5">
                <Col lg={2}></Col>
                <Col lg={10}>
                  <Row>
                    <Col lg={10}>
                      <Row>
                        <Col lg={1}></Col>
                        <Col lg={11}>
                          <Row>
                            <Col>
                              <Row>
                                {[...new Array(8)].map((i, index) => (
                                  // <Col lg={2}>
                                  <Col>
                                    <Row>
                                      <Col className="d-flex justify-content-center">
                                        <strong className="bg-secondary p-2">
                                          Wk {index + 1}
                                        </strong>
                                      </Col>
                                    </Row>
                                  </Col>
                                  // </Col>
                                ))}
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* foreach exercises */}
              {currentBlock?.exercises.length > 0 ? (
                currentBlock?.exercises.map((exercise) =>
                  Array.isArray(exercise) ? (
                    ""
                  ) : (
                    <>
                      <ExerciseRow
                        noteUI={noteUI}
                        // setNoteUI={setNoteUI}
                        handleNote={handleNote}
                        exercise={exercise}
                      />
                      {exercise?.variations.map((variation) => (
                        <VariationRow
                          variation={variation}
                          weeks={exercise?.weeks}
                        />
                      ))}
                    </>
                  )
                )
              ) : (
                <Row className="mb-3 align-items-center">
                  <Col lg={2}></Col>
                  <Col
                    lg={9}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <span>No exercise found for this block</span>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Card.Body>
      </Col>
      {noteUI && (
        <Col lg={4}>
          <ScheduleNote note={noteUI} />
        </Col>
      )}
    </>
  );
};

export default NotesScheduleView;
