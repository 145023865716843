import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import moment from "moment";

const HolidayErrorModal = (props) => {
  const HolidaysDates = () => {
    return props.holidays.map((holiday, index, elements) => (
      <span>
        {index !== 0 && index + 1 !== elements.length && ", "}
        {index > 0 && index + 1 == elements.length && " & "}
        {moment(holiday.holiday_date).format("MMM Do")}
      </span>
    ));
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-90w"
      backdrop="static"
    >
      <Modal.Body>
        <Row className="d-flex justify-content-center">
          <Col lg={9}>
            <p className="text-center">
              There is a public holiday for <HolidaysDates /> - would you like
              to skip adding patients to classes for this day or proceed with
              publishing?
            </p>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col lg={11}>
            <Row>
              <Col lg={6}>
                <button
                  className="btn btn-secondary btn-md rounded-pill w-100 text-primary"
                  onClick={() => props.handleSaveDraftWithHolidays()}
                >
                  Save anyway
                </button>
              </Col>
              <Col lg={6}>
                <button
                  className="btn btn-primary btn-md rounded-pill w-100"
                  onClick={() => props.handleSaveDraftSkipHolidays()}
                >
                  Skip this day
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default HolidayErrorModal;
