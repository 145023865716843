import React, { useEffect, useState, useRef } from "react";
// ui
import Grid from "@material-ui/core/Grid";
import { Image, Spinner, Button, InputGroup } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
// import UserDialog from "../components/dialog-bars/UserDialog";
import SVG from "react-inlinesvg";
import UserDialog from "../components/dialog-bars/UserDialog";

import { useSelector, useDispatch } from "react-redux";
// import { getPermissionList } from "../../Permissions/_redux/permissionActions";
import { getPermissionList } from "../../Permissions/_redux/permissionActions";
import { myDetailsUpdate } from "../_redux/userActions";
import { actions } from "../../Auth";

const MyProfile = () => {
  const dispatch = useDispatch();
  const imageRef = useRef();
  const { auth, permissions, users } = useSelector((state) => state);
  // const { user } = auth;
  const { actionsLoading, errors } = users;
  const defaultImg = toAbsoluteUrl("/media/images/default-profile.png");
  const [previewImage, setPreviewImage] = useState(null);
  const [user, setUser] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    dispatch(getPermissionList());
    setUser(auth.user);
  }, []);

  // useState();
  useEffect(() => {
    if (user?.photo !== null) {
      setPreviewImage(user?.photo);
      return;
    } else {
      setPreviewImage(defaultImg);
      return;
    }
  }, [user?.photo]);

  useEffect(() => {
    console.log(user);
  }, [user]);

  const handleImageButton = () => {
    imageRef.current.click();
  };

  const handleFieldChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (value, action) => {
    setUser({
      ...user,
      [action.name]: value.name,
    });
  };

  const handleImageChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];

    if (fileObj) {
      fileObj.target_name = event.target.name;
      handleFileChange(fileObj);
      // // 👇️ reset file input
      event.target.value = null;
    }
  };

  const handleFileChange = (file) => {
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      // let params = {
      //   target: file.target_name,
      //   val: file,
      //   b64: baseURL,
      // };
      // dispatch(userFieldChage(params));
      setUser({
        ...user,
        photo: baseURL,
        [file.target_name]: file,
      });
    };
  };

  const resetSchema = Yup.object().shape({
    password: Yup.string()
      .matches(/(?=.*[A-Z])/, "Password must contain a capital letter")
      .matches(
        /^(?=.*[!@#\$%\^&\*])/,
        "Password must contain a special character"
      )
      .matches(/^(?=.{6,20}$)\D*\d/, "Password must contain a number"),
    passwordConfirm: Yup.string()
      // .required("Password confirmation field is required")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().required("Password confirmation field is required"),
      })
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });

  const initialValues = {
    password: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: resetSchema,
    onSubmit: (values) => {},
  });

  const handleSave = () => {
    dispatch(myDetailsUpdate(user));
  };

  return (
    <>
      <UserDialog />
      <div className="card border-0">
        <div className="card-header">
          <h4>Edit Details</h4>
        </div>
        <form className="card-body">
          <Row>
            <Button
              style={{ width: "100px", position: "relative" }}
              disabled={actionsLoading || !formik.isValid}
              variant="primary"
              onClick={handleSave}
              className="rounded-pill ml-auto"
            >
              <div className="px-4">
                {actionsLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    style={{
                      position: "absolute",
                      left: "1rem",
                      top: "1rem",
                    }}
                    aria-hidden="true"
                  />
                )}
                Save
              </div>
            </Button>
          </Row>
          <Row>
            <Col>
              <Grid container className="input-group mb-5" spacing={1}>
                <Grid item xs={3} className="d-flex align-items-center">
                  <strong>First name</strong>
                </Grid>
                <Grid item xs={9}>
                  <Form.Control
                    disabled={user?.access !== "Administrator" ? true : false}
                    name="first_name"
                    onChange={handleFieldChange}
                    value={user?.first_name}
                    className={errors?.first_name && `border border-danger`}
                    isInvalid={errors?.first_name ? true : false}
                  />
                  {errors?.first_name && (
                    <span className="position-absolute text-danger">
                      {errors?.first_name[0]}
                    </span>
                  )}
                </Grid>
              </Grid>
              <Grid container className="input-group mb-5" spacing={1}>
                <Grid item xs={3} className="d-flex align-items-center">
                  <strong>Last name</strong>
                </Grid>
                <Grid item xs={9}>
                  <Form.Control
                    disabled={user?.access !== "Administrator" ? true : false}
                    name="last_name"
                    onChange={handleFieldChange}
                    value={user?.last_name}
                    className={errors?.last_name && `border border-danger`}
                    isInvalid={errors?.last_name ? true : false}
                  />
                  {errors?.last_name && (
                    <span className="position-absolute text-danger">
                      {errors?.last_name[0]}
                    </span>
                  )}
                </Grid>
              </Grid>
              <Grid container className="input-group mb-5" spacing={1}>
                <Grid item xs={3} className="d-flex align-items-center">
                  <strong>Access</strong>
                </Grid>
                <Grid item xs={9}>
                  <Select
                    isDisabled={user?.access !== "Administrator" ? true : false}
                    name="access"
                    isLoading={permissions.listLoading}
                    onChange={handleSelectChange}
                    options={permissions.list}
                    value={permissions.list.filter((permission) => {
                      if (permission.name == user?.access) {
                        return permission;
                      }
                    })}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => option.name}
                    aria-labelledby="aria-label"
                    inputId="aria-example-input"
                    // onMenuOpen={onMenuOpen}
                    // onMenuClose={onMenuClose}
                    placeholder="Access List"
                    theme={(theme) => ({
                      ...theme,
                      // borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#ffe5e5",
                        primary: "#ff8a93",
                      },
                    })}
                  />
                </Grid>
              </Grid>
              <Grid container className="input-group mb-5" spacing={1}>
                <Grid item xs={3} className="d-flex align-items-center">
                  <strong>Email</strong>
                </Grid>
                <Grid item xs={9}>
                  <Form.Control
                    disabled={user?.access !== "Administrator" ? true : false}
                    name="email"
                    onChange={handleFieldChange}
                    value={user?.email}
                    className={errors?.email && `border border-danger`}
                    isInvalid={errors?.email ? true : false}
                  />
                  {errors?.email && (
                    <span className="position-absolute text-danger">
                      {errors?.email[0]}
                    </span>
                  )}
                </Grid>
              </Grid>
              <Grid container className="input-group mb-5" spacing={1}>
                <Grid item xs={3} className="d-flex align-items-center">
                  <strong>Password</strong>
                </Grid>
                <Grid item xs={9}>
                  <span className="d-flex text-danger">
                    {formik.errors.password}
                  </span>

                  <InputGroup>
                    <Form.Control
                      // isInvalid={formik.errors?.password ? true : false}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      // onChange={handleFieldChange}
                      {...formik.getFieldProps("password")}
                      //   value={user?.password}
                      placeholder="********"
                      autoComplete="new-password"
                      defaultValue={user?.password}
                      className={errors?.password && `border border-danger`}
                      // isInvalid={errors?.password ? true : false}
                    />
                    <a
                      href="javascript:void(0)"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <i
                        className={`fa ${
                          showPassword ? "fa-eye" : "fa-eye-slash"
                        }`}
                        style={{
                          position: "absolute",
                          right: "15px",
                          top: "20px",
                          zIndex: 5,
                        }}
                      ></i>
                    </a>
                    {errors?.password && (
                      <span className="position-absolute text-danger">
                        {errors?.password[0]}
                      </span>
                    )}
                  </InputGroup>
                </Grid>
              </Grid>
              <Grid container className="input-group mb-5" spacing={1}>
                <Grid item xs={3} className="d-flex align-items-center">
                  <strong>Confirm Password</strong>
                </Grid>
                <Grid item xs={9}>
                  <span className="d-flex text-danger">
                    {formik.errors.passwordConfirm}
                  </span>

                  <InputGroup>
                    <Form.Control
                      // isInvalid={formik.errors?.password ? true : false}
                      type={showConfirmPassword ? "text" : "password"}
                      name="passwordConfirm"
                      // onChange={handleFieldChange}
                      {...formik.getFieldProps("passwordConfirm")}
                      //   value={user?.password}
                      placeholder="********"
                      autoComplete="passwordConfirm"
                      defaultValue={user?.passwordConfirm}
                      className={
                        errors?.passwordConfirm && `border border-danger`
                      }
                      // isInvalid={errors?.password ? true : false}
                    />
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      <i
                        className={`fa ${
                          showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                        }`}
                        style={{
                          position: "absolute",
                          right: "15px",
                          top: "20px",
                          zIndex: 5,
                        }}
                      ></i>
                    </a>
                    {errors?.passwordConfirm && (
                      <span className="position-absolute text-danger">
                        {errors?.password[0]}
                      </span>
                    )}
                  </InputGroup>
                </Grid>
              </Grid>
            </Col>
            <Col>
              <Grid
                container
                className="input-group mb-5 text-center"
                spacing={1}
              >
                <Grid item xs={12}>
                  <input
                    accept="image/*"
                    id="contained-button-file"
                    hidden
                    ref={imageRef}
                    type="file"
                    name="photo_file"
                    onChange={handleImageChange}
                  />
                  <Image
                    // src={toAbsoluteUrl("/media/images/default-exercise.png")}
                    src={previewImage}
                    roundedCircle
                    // fluid
                    // width="30%"
                    width="200px"
                    height="200px"
                    style={{ border: "2px solid #ff8a93" }}
                  />
                  <a href="javascript:void(0)" onClick={handleImageButton}>
                    <SVG
                      style={{
                        width: "20px",
                        position: "absolute",
                        bottom: 0,
                      }}
                      fill="currentColor"
                      src={toAbsoluteUrl("/media/svg/icons/edit.svg")}
                    />
                  </a>
                </Grid>
              </Grid>
            </Col>
          </Row>
        </form>
      </div>
    </>
  );
};

export default MyProfile;
