import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { saveHolidays } from "../../_redux/holiday/holidaysActions";
import { Spinner } from "react-bootstrap";
import HolidayRow from "./HolidayRow";

const EditHolidays = ({
  holidays,
  handleInputChange,
  handleDateChange,
  handleNewHoliday,
  handleCancelEdit,
  handleRemoveHoliday,
}) => {
  const dispatch = useDispatch();
  const { saveLoading, actionsLoading, listLoading } = useSelector(
    (state) => state.holidays
  );

  const handleSave = () => {
    dispatch(saveHolidays(holidays));
  };

  return (
    <>
      <Grid
        container
        style={{
          maxHeight: "450px",
          overflow: "auto",
        }}
        className="mb-5"
      >
        <Grid item lg={11} className="mb-5 m-auto">
          {holidays?.map((holiday, index) => (
            <HolidayRow
              handleInputChange={(e) => handleInputChange(e, index)}
              handleDateChange={(e) => handleDateChange(e, index)}
              handleRemoveHoliday={(e) => handleRemoveHoliday(e, index)}
              holiday={holiday}
            />
          ))}
        </Grid>
        <Grid item lg={11}>
          <Row className="d-flex justify-content-center">
            <button
              className="btn btn-sm btn-primary rounded-pill"
              onClick={handleNewHoliday}
            >
              +
            </button>
          </Row>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={11}>
          <Row className="d-flex justify-content-center">
            <Col lg={4}>
              <button
                className="btn btn-secondary rounded-pill w-100 text-primary"
                onClick={handleCancelEdit}
              >
                Cancel
              </button>
            </Col>
            <Col lg={4}>
              <button
                className="btn btn-primary rounded-pill w-100 position-relative"
                onClick={handleSave}
              >
                {saveLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    style={{
                      position: "absolute",
                      left: "2rem",
                      top: "1rem",
                    }}
                    aria-hidden="true"
                  />
                )}
                Save
              </button>
            </Col>
          </Row>
        </Grid>
      </Grid>
    </>
  );
};

export default EditHolidays;
