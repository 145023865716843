import React from "react";
import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { CoursesPage } from "../../../pages/CoursesPage";
import CourseExportPage from "../../Courses/pages/CourseExportPage";
import ClassesPage from "../../ClassPlans/Classes/pages/ClassesPage";
import TemplatesPage from "../../ClassPlans/Templates/pages/TemplatesPage";
import Exercise from "../../Exercises";
import AdModExercisePage from "../../Exercises/pages/AdModExercisePage";
import UserPage from "../../User";
import AdModUserPage from "../../User/pages/AdModUserPage";
// import ExercisePlansPage from "../../ClinicalRehab/pages/ExercisePlansPage";
import ClinicalRehabPage from "../../ClinicalRehab/pages/ClinicalRehabPage";
import AttendancePage from "../../Attendance/AttendancePage";
import PreviousExportPage from "../../Courses/pages/PreviousExportPage";
import PatientNotesPage from "../../ClinicalRehab/pages/PatientNotesPage";

import { ErrorPage1 } from "../../ErrorsExamples/ErrorPage1";
import ExercisePlansPage from "../../ExercisePlans/pages/ExercisePlansPage";
import AdModTemplate from "../../ClassPlans/Templates/components/AdModTemplate";
import AdModClassPage from "../../ClassPlans/Classes/components/AdModClassPage";

export const linkList = [
  {
    name: "Course Builder",
    link: "/course-builder",
    icon: toAbsoluteUrl("/media/svg/icons/file-export-solid.svg"),
    access: ["Administrator", "Admin Team"],
    component: CoursesPage,
    dropdown: false,
    subLinks: [
      {
        name: "Course Builder",
        link: "/course-builder/export",
        component: CourseExportPage,
      },
      // {
      //   name: "Course Builder",
      //   link: "/course-builder/exercise-plans",
      //   component: ExercisePlansPage,
      // },
      {
        name: "Previous exports",
        link: "/course-builder/previous-export/:id",
        component: PreviousExportPage,
      },
    ],
  },
  {
    name: "Clinical rehab classes",
    link: "/clinical-rehab-classes",
    icon: toAbsoluteUrl("/media/svg/icons/hand-holding-hand-solid.svg"),
    access: ["Administrator", "Physio"],
    component: ClinicalRehabPage,
    dropdown: false,
    subLinks: [
      {
        name: "Exercise Plans",
        link: "/clinical-rehab-classes/exercise-plans/:id/:classId",
        component: ExercisePlansPage,
      },
      {
        name: "Exercise Plans",
        link: "/clinical-rehab-classes/exercise-plans/:id/:classId/:mode",
        component: ExercisePlansPage,
      },
      {
        name: "Patient Notes",
        link: "/clinical-rehab-classes/patient-notes/:id",
        component: PatientNotesPage,
      },
    ],
  },
  // {
  //   name: "Class Plans",
  //   link: "/class-plans",
  //   // link: "/class-plans",
  //   icon: toAbsoluteUrl("/media/svg/icons/people-group-solid.svg"),
  //   access: ["Administrator", "Physio"],
  //   // component: ClassesPage,
  //   dropdown: true,
  //   subMenus: [
  //     {
  //       name: "Class Templates",
  //       link: "/class-plans/templates",
  //       dropdown: true,
  //       icon: toAbsoluteUrl("/media/svg/icons/people-group-solid.svg"),
  //       access: ["Administrator", "Physio"],
  //       component: TemplatesPage,
  //       subLinks: [
  //         {
  //           name: "Class template",
  //           link: "/class-plans/templates/new",
  //           component: AdModTemplate,
  //         },
  //         {
  //           name: "Class template",
  //           link: "/class-plans/templates/:id",
  //           component: AdModTemplate,
  //         },
  //         {
  //           name: "Class template",
  //           link: "/class-plans/templates/:id/:mode",
  //           component: AdModTemplate,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Courses",
  //       link: "/class-plans/courses",
  //       icon: toAbsoluteUrl("/media/svg/icons/people-group-solid.svg"),
  //       access: ["Administrator", "Physio"],
  //       component: ClassesPage,
  //       subLinks: [
  //         {
  //           name: "Courses",
  //           link: "/class-plans/courses/:id",
  //           component: AdModClassPage,
  //         },
  //         {
  //           name: "Courses",
  //           link: "/class-plans/courses/:id/:mode",
  //           component: AdModClassPage,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    name: "Exercise library",
    link: "/exercises",
    icon: toAbsoluteUrl("/media/svg/icons/exercises.svg"),
    access: ["Administrator", "Physio"],
    component: Exercise,
    dropdown: false,
    subLinks: [
      {
        name: "Exercise library",
        link: "/exercises/add",
        component: AdModExercisePage,
      },
      {
        name: "Exercise library",
        link: "/exercises/:id",
        component: AdModExercisePage,
      },
      {
        name: "Exercise library",
        link: "/exercises/:id/:mode",
        component: AdModExercisePage,
      },
    ],
  },
  {
    name: "Attendance",
    link: "/attendance",
    icon: toAbsoluteUrl("/media/svg/icons/clipboard-user-solid.svg"),
    access: ["Administrator", "Physio"],
    component: AttendancePage,
    dropdown: false,
  },
  {
    name: "Users",
    link: "/users",
    icon: toAbsoluteUrl("/media/svg/icons/user-solid.svg"),
    access: ["Administrator"],
    component: UserPage,
    dropdown: false,
    subLinks: [
      {
        name: "Users",
        link: "/users/add",
        component: AdModUserPage,
      },
      {
        name: "Users",
        link: "/users/:id",
        component: AdModUserPage,
      },
      {
        name: "Users",
        link: "/users/:id/:mode",
        component: AdModUserPage,
      },
    ],
  },
];

export const filterLinksByAccess = (access) => {
  let filteredLinks = [];

  linkList.map((link) => {
    if (link.access.includes(access)) {
      filteredLinks = [...filteredLinks, link];
    }
  });

  return filteredLinks;
};
