import axios from "axios";

const holidaysUrl = `${process.env.REACT_APP_API_URL}/public-holidays`;
const holidayUrl = `${process.env.REACT_APP_API_URL}/public-holiday`;

export function getHolidays() {
  return axios.get(holidaysUrl);
}

export function saveHolidays(holidaysData) {
  let data = {
    holidays: holidaysData,
  };

  return axios.post(holidayUrl, data);
}
