import React, { useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { exportToMindBody } from "../_redux/coursesActions";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { ProgressBar } from "react-bootstrap";
import { LinearProgress, Box, Typography, makeStyles } from "@material-ui/core";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { statusReset } from "../_redux/coursesActions";

const useStyles = makeStyles({
  root: {
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#ff8a93",
    },
  },
});

function LinearProgressWithLabel(props) {
  const classes = useStyles();
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          className="bg-secondary"
          classes={{
            root: classes.root,
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          color="text.primary"
          className="text-center"
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const ExportConfirmModal = (props) => {
  const dispatch = useDispatch();
  const {
    exportLoading,
    exportSuccess,
    exportLoadingProgress,
    exportFinished,
    message,
  } = useSelector((state) => state.courses);

  const handleExport = () => {
    // let params = {
    //   class_id: classPatientList.classId,
    //   schedule_id: classPatientList.classScheduleId,
    //   block_date_id: nextBlock.id,
    // };
    dispatch(exportToMindBody());
  };

  useEffect(() => {
    exportFinished && dispatch(statusReset());
  }, [exportFinished]);

  return (
    <Modal
      show={props.show}
      // onHide={() => handleCloseExportConfirm()}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-50w"
      centered
      backdrop="static"
    >
      <Modal.Body>
        {exportLoading ? (
          <>
            <Row className="d-flex justify-content-center">
              {/* <Col lg={9}> */}
              <span>{message}</span>
              {/* </Col> */}
            </Row>
            <Row className="my-5">
              <Col lg={12}>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel value={exportLoadingProgress} />
                </Box>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className="d-flex justify-content-center">
              <Col lg={9}>
                <p className="text-center">
                  Are you sure you'd like to save and export the classes and
                  patients to Mindbody?
                </p>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col lg={11}>
                <Row className="justify-content-center">
                  <>
                    <Col lg={6}>
                      <button
                        className="btn btn-secondary btn-md rounded-pill w-100 text-primary"
                        onClick={() => {
                          props.handleCloseExportConfirm();
                        }}
                      >
                        Cancel
                      </button>
                    </Col>
                    <Col lg={6}>
                      <button
                        className="btn btn-primary btn-md rounded-pill w-100"
                        onClick={() => {
                          handleExport();
                        }}
                      >
                        Export
                      </button>
                    </Col>
                  </>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ExportConfirmModal;
