import { createSlice } from "@reduxjs/toolkit";

const initialHolidaysState = {
  list: [],
  saveLoading: false,
  actionsLoading: false,
  listLoading: false,
  isError: false,
  isInfo: false,
  isSuccess: false,
  message: "",
};

export const holidaySlice = createSlice({
  name: "holidays",
  initialState: initialHolidaysState,
  reducers: {
    startCall: (state, action) => {
      const { loadingType } = action.payload;
      state[loadingType] = true;
    },
    holidayListFetched: (state, action) => {
      //   console.log(action.payload);
      state.list = action.payload;
      state.listLoading = false;
    },
    holidayListSaved: (state, action) => {
      const { data, message } = action.payload;
      state.list = data;
      state.saveLoading = false;
      state.isSuccess = true;
      state.message = message;
    },
    catchError: (state, action) => {
      const { message } = action.payload;
      state.saveLoading = false;
      state.isError = true;
      state.message = message;
    },
    statusReset: (state, action) => {
      state.isError = false;
      state.isSuccess = false;
      state.isInfo = false;
      state.message = false;
    },
  },
});
