import React, { useState, useEffect } from "react";

// ui
import { Card, Col, Row, Table, Spinner } from "react-bootstrap";
import { Grid } from "@material-ui/core";

import PublishExerciseModal from "./modals/PublishExerciseModal";
import DeleteExerciseModal from "./modals/DeleteExerciseModal";
import PropsModal from "./modals/PropsModal";
import AddExerciseModal from "./modals/AddExerciseModal";

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import ProgressBar from "../../Other/ProgressBar";
import ExerciseRow from "./course-tab/ExerciseRow";
import CurrentExercises from "./course-tab/CurrentExercises";
import PreviousBlockExercises from "./course-tab/PreviousBlockExercises";
import FavoriteExercises from "./course-tab/FavoriteExercises";
import { saveDraft } from "../_redux/exercisePlanActions";
import { useDispatch, useSelector } from "react-redux";
import ExerciseContainer from "../../Other/DragDropExercises/ExercisesContainer";

const CourseTab = ({ patient, schedule_id, readOnly, index, class_id, isPublished, enableEdit, disableEdit, resetPatientExercises }) => {
  const dispatch = useDispatch();
  const { saveDraftLoading } = useSelector((state) => state.exercisePlans);
  const [state, setState] = useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
  });
  const [editable, setEditable] = useState(true);
  const [publishable, setPublishable] = useState(false);
  const [publishModal, setPublishModal] = useState(false);
  const [deleteExerciseModal, setDeleteExerciseModal] = useState(false);
  const [propsModal, setPropsModal] = useState(false);
  const [addExerciseModal, setAddExerciseModal] = useState(false);
  const [draft, setDraft] = useState(false);

  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };

  const newExercise = {
    exercise_id: null,
    sets: 0,
    reps: 0,
    springs: "",
    favourits: false,
    variations: [],
    checked: false,
  };

  const handleSave = () => {
    dispatch(saveDraft(patient.exercisesUI.patient_exercises, index));
    if (isPublished) {
      setEditable(false);
    }
  };

  const handleStartEditClass = () => {
    setEditable(true);
  }

  const handleCancelEditClass = () => {
    setEditable(false);
    resetPatientExercises(patient);
  }

  useEffect(() => {
    if (isPublished) {
      setEditable(false);
    }
  }, [])

  useEffect(() => {
    const exerciseUI = JSON.stringify(patient.exercisesUI);
    const exercises = JSON.stringify(patient.exercises);

    let draft = false;

    const patientExercisesCount =
      patient?.exercises?.patient_exercises?.exercises.length;
    const favExercisesCount = patient?.exercises?.favourites?.exercises.length;

    if (patientExercisesCount > 0 || favExercisesCount > 0) {
      draft = true;
    }

    if (exerciseUI !== exercises) {
      // setDraft(true);
      draft = true;
    } else {
      // setDraft(false);
      draft = false;
    }

    setDraft(draft);
  }, [patient.exercisesUI, patient.exercises]);

  useEffect(() => {
    if (!draft) {
      let pub = false;

      const patientExercisesCount =
        patient?.exercises?.patient_exercises?.exercises.length;
      const favExercisesCount =
        patient?.exercises?.favourites?.exercises.length;

      if (patientExercisesCount > 0 || favExercisesCount > 0) {
        pub = true;
      }

      if (pub) {
        setPublishable(true);
      } else {
        setPublishable(false);
      }
    } else {
      setPublishable(false);
    }
  }, [draft]);

  return (
    <Card style={{ backgroundColor: "whitesmoke" }}>
      <PublishExerciseModal
        show={publishModal}
        onHide={() => setPublishModal(false)}
        patient_id={patient.patient_id}
        class_id={class_id}
        index={index}
      />
      <DeleteExerciseModal
        show={deleteExerciseModal}
        onHide={() => setDeleteExerciseModal(false)}
      />
      <PropsModal show={propsModal} onHide={() => setPropsModal(false)} />

      <AddExerciseModal
        patient_id={patient.patient_id}
        schedule_id={schedule_id}
        show={addExerciseModal}
        onHide={() => setAddExerciseModal(false)}
      />
      <Card.Body style={{ minHeight: "150px" }}>
        {patient.exercisesLoading ? (
          <ProgressBar />
        ) : (
          patient?.exercises && (
            <>
              <Grid container>
                <Grid item lg={12} className="mx-auto mt-5">
                  <Table>
                    <CurrentExercises
                      patient_id={
                        patient?.exercisesUI?.patient_exercises?.patient_id
                      }
                      patient_index={index}
                      exercises={
                        patient?.exercisesUI?.patient_exercises?.exercises
                      }
                      patient={patient}
                      schedule_id={schedule_id}
                      readOnly={!editable}
                    />
                    {/* <PreviousBlockExercises
                      exercises={
                        patient?.exercises?.previous_exercises?.exercises
                      }
                      patient={patient}
                      readOnly={readOnly}
                    /> */}
                    <FavoriteExercises
                      exercises={patient?.exercisesUI?.favourites?.exercises}
                      patient={patient}
                      readOnly={!editable}
                    />
                  </Table>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={12}>
                  {/* <Row className="mb-3">
                    <Col lg={12} className="d-flex">
                      <button
                        disabled={readOnly}
                        className="btn btn-primary text-white rounded-pill ml-auto"
                        onClick={() => setAddExerciseModal(true)}
                      >
                        + Add exercise
                      </button>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col lg={12} className="d-flex">
                      <button
                        className="btn btn-primary text-white rounded-pill ml-auto position-relative "
                        onClick={handleSave}
                        hidden={isPublished}
                        disabled={saveDraftLoading || readOnly || !draft}
                        style={{ width: "120px" }}
                      >
                        {saveDraftLoading && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            style={{
                              position: "absolute",
                              left: "0.5rem",
                              top: "0.9rem",
                            }}
                            aria-hidden="true"
                          />
                        )}
                        Save draft
                      </button>
                      <button
                        className="btn btn-primary text-white rounded-pill ml-auto position-relative "
                        onClick={handleSave}
                        hidden={!isPublished}
                        disabled={saveDraftLoading || readOnly || !draft}
                        style={{ width: "120px" }}
                      >
                        {saveDraftLoading && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            style={{
                              position: "absolute",
                              left: "0.5rem",
                              top: "0.9rem",
                            }}
                            aria-hidden="true"
                          />
                        )}
                        Update and Publish
                      </button>
                      {
                        !isPublished ?
                          <button
                            disabled={readOnly || !publishable}
                            className="btn btn-primary text-white rounded-pill ml-3"
                            onClick={() => setPublishModal(true)}
                          >
                            Publish exercises for patient
                          </button>
                          :
                          <>
                            <button
                              hidden={editable}
                              className="btn btn-primary text-white rounded-pill ml-3"
                              onClick={() => handleStartEditClass()}
                            >
                              Edit exercises
                            </button>
                            <button
                              hidden={!editable}
                              className="btn btn-primary text-white rounded-pill ml-3"
                              onClick={() => handleCancelEditClass()}
                            >
                              Cancel
                            </button>
                          </>

                      }

                    </Col>
                  </Row>
                </Grid>
              </Grid>
            </>
          )
        )}
      </Card.Body>
    </Card>
  );
};

export default CourseTab;
