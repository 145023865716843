import React from "react";
// ui
import { Table } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { CircularProgress } from "@material-ui/core";

const PreviousExportsTable = () => {
  const { previousRecords, previousExportLoading } = useSelector(
    (state) => state.courses
  );
  return (
    <div className="col-md-12">
      <Table responsive className="table table-borderless">
        <thead className="bg-secondary">
          <tr>
            <th colSpan={8}>Previous exports</th>
          </tr>
        </thead>
        <tbody>
          <tr className="font-weight-bold">
            <td className="py-5">Date exported</td>
            <td className="py-5">Block date range</td>
            <td className="py-5">User</td>
            <td colSpan={5} style={{ minWidth: "500px" }}></td>
          </tr>
          {previousExportLoading ? (
            <tr>
              <td colSpan={3} className="text-center">
                <CircularProgress
                  style={{
                    color: "#ff8a93",
                  }}
                />
              </td>
            </tr>
          ) : previousRecords.length == 0 ? (
            <tr>
              <td colSpan={3} className="text-center">
                No previous exports.
              </td>
            </tr>
          ) : (
            previousRecords.map((data) => (
              <tr>
                <td>{moment(data.date_exported).format("DD/MM/YYYY")}</td>
                <td>
                  {data.block_date_range.split(" - ").map((d, index) => {
                    return (
                      moment(d).format("DD/MM/YYYY") +
                      `${index == 0 ? " - " : ""}`
                    );
                  })}
                </td>
                <td>{data.user}</td>
                <td>
                  <Link
                    component={RouterLink}
                    color="inherit"
                    className="pr-3"
                    to={`/course-builder/previous-export/${data.block_id}`}
                  >
                    <SVG
                      style={{ width: "24px" }}
                      fill="currentColor"
                      src={toAbsoluteUrl("/media/svg/icons/eye-solid.svg")}
                    />
                  </Link>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default PreviousExportsTable;
