export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      // const {
      //   auth: { authToken },
      // } = store.getState();
      config.headers["X-Requested-With"] = "XMLHttpRequest";
      config.headers["Content-type"] = "application/json";

      config.withCredentials = true;
      // if (authToken) {
      //   config.headers.Authorization = `Bearer ${authToken}`;
      // }
      console.log();

      return config;
    },
    (err) => Promise.reject(err)
  );
}
