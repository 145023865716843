import React, { useState, useEffect } from "react";

import { Table, Form, Row, Col, Modal, Button } from "react-bootstrap";
import Select, { StylesConfig, components } from "react-select";
import Fab from "@material-ui/core/Fab";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

// componenets
import HumanBody from "./HumanBody";

// redux
import { useSelector, useDispatch } from "react-redux";
import { addBodyPart } from "../_redux/exercises/exerciseLibraryActions";
import CustomSelect from "../../Other/CustomSelect";

export default function BodyMapModal(props) {
  const dispatch = useDispatch();
  const [selectedBodyPart, setSelectedBodyPart] = useState(null);
  const [options, setOptions] = useState([]);
  const { tags, exercise } = useSelector((state) => state.exerciseLibrary);

  const colourStyles: StylesConfig<ColourOption, true> = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        ":active": {
          backgroundColor: "#ffe5e5",
        },
        ":focus": {
          backgroundColor: "#ffe5e5",
        },
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        padding: "3px",
        backgroundColor: "#ffe5e5",
      };
    },
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#ff5764 ",
        color: "white",
      },
    }),
  };

  const closeModal = () => {
    props.onHide(true);
  };

  const handleSelectChange = (event) => {
    setSelectedBodyPart(event);
  };

  const handleSave = () => {
    let params = {
      target: "body_parts",
      value: selectedBodyPart,
    };
    dispatch(addBodyPart(params));
    props.onHide();
  };

  const getRemainingOptions = () => {
    let newOptions = [];

    setOptions(tags?.body_parts);
    // if (exercise?.body_parts == null) {
    //   setOptions(tags?.body_parts);
    // }

    // if (tags?.body_parts && exercise?.body_parts) {
    //   // console.log(tags.body_parts,exercise.body_parts);
    //   tags.body_parts.map((body_part) => {
    //     let count = 0;

    //     exercise.body_parts.map((ebp) => {
    //       if (body_part.id == ebp.id) {
    //         count++;
    //       }
    //     });
    //     if (count == 0) {
    //       newOptions = [...newOptions, body_part];
    //     }
    //   });
    //   setOptions(newOptions);
    // }
  };

  useEffect(() => {
    setSelectedBodyPart(exercise?.body_parts);
  }, [exercise?.body_parts]);

  useEffect(() => {
    // setSelectedBodyPart(null);
    getRemainingOptions();
  }, [props]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-90w"
      backdrop="static"
      keyboard={false}
    >
      {/* <Col sm={12}> */}

      {/* </Col> */}
      <Modal.Body>
        <Row>
          <Col sm={12} className="text-center">
            {/* <Modal.Title id="contained-modal-title-vcenter"> */}
            Add on body map
            {/* </Modal.Title> */}
            <Link
              style={{
                position: "absolute",
                right: "15px",
              }}
              color="inherit"
              onClick={() => {
                closeModal();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </Link>
          </Col>
          {/* <Col sm={1}>
            <Typography>
              <Link
                color="inherit"
                onClick={() => {
                  closeModal();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </Link>
            </Typography>
          </Col> */}
        </Row>
        <Row className="p-5">
          <Col className="d-flex justify-content-center" lg={12}>
            <CustomSelect
              isMulti
              name="body_map"
              className="w-100"
              onChange={handleSelectChange}
              value={selectedBodyPart}
              options={options}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => option.id}
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              placeholder="Select body area"
              styles={colourStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#ffe5e5",
                  primary: "#ff8a93",
                },
              })}
            />
          </Col>
        </Row>
        <Form>
          <Form.Group as={Row} controlId="">
            <Col sm="12">
              <img
                src={toAbsoluteUrl("/media/images/body_map_divs.png")}
                alt=""
                className="w-100"
              />
              {/* {page == 1 ? (
                <HumanBody
                  selectedBodyPart={bodyPart}
                  setBodyPart={setBodyPart}
                /> // selecting a body part page
              ) : (
                <Grid container spacing={1} className="input-group mb-5 pb-5">
                  <Grid item xs={12}>
                    <HumanBody
                      selectedBodyPart={bodyPart}
                      setBodyPart={setBodyPart}
                      page={page}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <strong className="text-bottom">Name this:</strong>
                  </Grid>
                  <Grid item xs={9}>
                    <Form.Control placeholder="Start typing" />
                  </Grid>
                </Grid>
              )} */}
            </Col>
          </Form.Group>
          <Row className="d-flex justify-content-center">
            <Col sm="4">
              <Button
                variant="secondary"
                className="rounded-pill w-100 text-primary"
                onClick={() => {
                  closeModal();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col sm="4">
              <Button
                disabled={selectedBodyPart == null}
                variant="primary"
                className="rounded-pill w-100 text-white"
                onClick={handleSave}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
