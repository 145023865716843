import * as requestFromServer from "./userCrud";
import { userSlice, callTypes } from "./userSlice";
// import { updateUser } from "../../Auth/_redux/authCrud";
import { actions as authActions } from "../../Auth";

const { actions } = userSlice;

export const populateNewUser = () => (dispatch) => {
  dispatch(actions.newUserFieldsPopulated());
};

export const fetchUsers = () => (dispatch) => {
  dispatch(actions.startCall({ callType: "list" }));
  return requestFromServer
    .getUsersList()
    .then((res) => {
      dispatch(actions.usersFetched(res.data));
    })
    .catch((err) => {
      console.log(`error: ${err}`);
    });
};

// export const fetchUser = ()=>dispatch=>{
//   dispatch(actions.startCall({callType:"list"}));
//   return requestFromServer
//   .getUser()
//   .then(
//     res=>{

//     }
//   )
//   .catch(
//     err=>{
//       console.log(`error: ${err}`)
//     }
//   )
// }

export const userCreate = (params) => (dispatch) => {
  dispatch(actions.startCall("actionsLoading"));
  return requestFromServer
    .createUser(params)
    .then((res) => {
      console.log(res);
      dispatch(actions.userAdded());
    })
    .catch((err) => {
      dispatch(actions.catchError(err.response.data));
    });
};

export const getUser = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: "list" }));

  return requestFromServer
    .getUser(id)
    .then((response) => {
      dispatch(actions.editUserFieldsPopulated(response.data));
    })
    .catch((error) => {
      if (error.response) {
        dispatch(actions.catchError(error.response.data.message));
      }
    });
};

export const myDetailsUpdate = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: "action" }));
  return requestFromServer
    .updateProfile(params)
    .then((res) => {
      dispatch(authActions.updateUser(res.data));
      dispatch(actions.userUpdated());
    })
    .catch((err) => {
      dispatch(actions.catchError(err.response.data));
    });
};

export const userUpdate = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: "action" }));
  return requestFromServer
    .updateUser(params)
    .then((res) => {
      console.log(res);
      dispatch(actions.userUpdated());
    })
    .catch((err) => {
      dispatch(actions.catchError(err.response.data));
    });
};

export const userFieldChage = (params) => (dispatch) => {
  dispatch(actions.userFieldChanged(params));
};

export const statusReset = () => (dispatch) => {
  dispatch(actions.statusResetted());
};
