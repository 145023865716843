import * as requestFromServer from "./exerciseLibraryCrud";
import { exerciseLibrarySlice } from "./exerciseLibrarySlice";

const { actions } = exerciseLibrarySlice;

export const newExercise = () => (dispatch) => {
  dispatch(actions.newExercisePopulated());
};

export const getExercisesList = () => (dispatch) => {
  dispatch(actions.startCall("listLoading"));
  return requestFromServer
    .getExerciseList()
    .then((response) => {
      dispatch(actions.exerciseListFetched(response.data));
    })
    .catch((error) => {
      console.log("error", error);
      if (error.response) {
        dispatch(actions.catchError(error.response.data.message));
      }
    });
};

export const getExercise = (id) => (dispatch) => {
  dispatch(actions.startCall("listLoading"));
  return requestFromServer
    .getExercise(id)
    .then((response) => {
      dispatch(actions.editExercisePopulated(response.data));
    })
    .catch((error) => {
      console.log("error", error);
      if (error.response) {
        dispatch(actions.catchError(error.response.data.message));
      }
    });
};

export const addBodyPart = (params) => (dispatch) => {
  dispatch(actions.bodyPartAdded(params));
};

export const exerciseFieldChange = (params) => (dispatch) => {
  dispatch(actions.exerciseFieldUpdated(params));
};

export const variationFieldChange = (params) => (dispatch) => {
  dispatch(actions.variationFieldUpdated(params));
};

export const variationFileRemove = (params) => (dispatch) => {
  dispatch(actions.variationFileRemoved(params));
};

export const duplicateVariation = (params) => (dispatch) => {
  dispatch(actions.variationDuplicated(params));
};

export const addBlankVariation = () => (dispatch) => {
  dispatch(actions.blankVariationAdded());
};

export const removeVariation = (params) => (dispatch) => {
  dispatch(actions.variationRemoved(params));
};

export const statusReset = (params) => (dispatch) => {
  dispatch(actions.statusResetted());
};

export const saveReset = () => (dispatch) => {
  dispatch(actions.saveResetted());
};

export const addExercise = (params, hasErrors = false) => (dispatch) => {
  dispatch(actions.startCall("saveExerciseLoading"));
  if (hasErrors) {
    dispatch(actions.catchError("Please input required fields."));
    return;
  }
  return requestFromServer
    .saveExercise(params)
    .then((response) => {
      dispatch(actions.exerciseAdded());
    })
    .catch((error) => {
      console.log("error", error);
      if (error.response) {
        dispatch(actions.catchError(error.response.data.message));
      }
    });
};

export const updateExercise = (params, hasErrors = false) => (dispatch) => {
  dispatch(actions.startCall("saveExerciseLoading"));
  if (hasErrors) {
    dispatch(actions.catchError("Please input required fields."));
    return;
  }
  return requestFromServer
    .updateExercise(params)
    .then((response) => {
      dispatch(actions.exerciseUpdated());
    })
    .catch((error) => {
      console.log("error", error);
      if (error.response) {
        dispatch(actions.catchError(error.response.data.message));
      }
    });
};

// tags
export const fetchTags = (queryParams) => (dispatch) => {
  // dispatch(actions.startCall({callType: callTypes.list}));
  return requestFromServer
    .getTags()
    .then((response) => {
      const { tags } = response.data;
      dispatch(actions.tagsFetched(tags));
    })
    .catch((error) => {
      error.clientMessage = "Cant find tags";
    });
};

export const fetchEquipmentIcons = (queryParams) => (dispatch) => {
  // dispatch(actions.startCall({callType: callTypes.list}));
  return requestFromServer
    .getEquipmentIcons()
    .then((response) => {
      const { equipmentIcons } = response.data;
      dispatch(actions.equipmentIconsFetched(equipmentIcons));
    })
    .catch((error) => {
      error.clientMessage = "Cant find equipment icons";
      //   dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchPropIcons = (queryParams) => (dispatch) => {
  // dispatch(actions.startCall({callType: callTypes.list}));
  return requestFromServer
    .getPropIcons()
    .then((response) => {
      const { propsIcons } = response.data;
      dispatch(actions.propIconsFetched(propsIcons));
    })
    .catch((error) => {
      error.clientMessage = "Cant find prop icons";
      //   dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const saveEquipment = (data) => (dispatch) => {
  dispatch(actions.startCall("saveEquipmentLoading"));
  if (!data.name || !data.icon_id) {
    dispatch(actions.catchError("Please fill all fields."));
    return;
  }

  return requestFromServer
    .saveEquipment(data)
    .then((response) => {
      dispatch(actions.equipmentSaved(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateEquipment = (data) => (dispatch) => {
  dispatch(actions.startCall("saveEquipmentLoading"));
  if (!data.name || !data.icon_id) {
    dispatch(actions.catchError("Please fill all fields."));
    return;
  }
  return requestFromServer
    .updateEquipment(data)
    .then((response) => {
      dispatch(actions.equipmentUpdated(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
};

export const saveProp = (data, variation_index) => (dispatch) => {
  dispatch(actions.startCall("savePropLoading"));
  if (!data.name || !data.icon_id) {
    dispatch(actions.catchError("Please fill all fields."));
    return;
  }

  return requestFromServer
    .saveProp(data)
    .then((response) => {
      dispatch(
        actions.propSaved({
          props: response.data.props,
          variation_index: variation_index,
        })
      );
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateProp = (data, variation_index) => (dispatch) => {
  dispatch(actions.startCall("savePropLoading"));
  if (!data.name || !data.icon_id) {
    dispatch(actions.catchError("Please fill all fields."));
    return;
  }
  return requestFromServer
    .updateProp(data)
    .then((response) => {
      dispatch(
        actions.propUpdated({
          props: response.data.props,
          variation_index: variation_index,
        })
      );
    })
    .catch((error) => {
      console.log(error);
    });
};

export const archiveExercise = (exercise_id) => (dispatch) => {
  dispatch(actions.startCall("actionsLoading"));
  return requestFromServer
    .exerciseArchive(exercise_id)
    .then((response) => {
      dispatch(actions.exerciseArchived(exercise_id));
    })
    .catch((error) => {
      // console.log(error);
      dispatch(actions.catchError(error.response.data.message));
    });
};

export const deleteExercise = (exercise_id) => (dispatch) => {
  dispatch(actions.startCall("actionsLoading"));
  return requestFromServer
    .exerciseDelete(exercise_id)
    .then((response) => {
      dispatch(actions.exerciseDeleted(exercise_id));
    })
    .catch((error) => {
      // console.log(error);
      dispatch(actions.catchError(error.response.data.message));
    });
};

export const reActivateExercise = (exercise_id) => (dispatch) => {
  dispatch(actions.startCall("actionsLoading"));
  return requestFromServer
    .reactivateExercise(exercise_id)
    .then((response) => {
      dispatch(actions.exerciseReactivated());
    })
    .catch((error) => {
      // console.log(error);
      dispatch(actions.catchError(error.response.data.message));
    });
};

export const archiveToggle = () => (dispatch) => {
  dispatch(actions.showArchiveToggled());
};
