import React, { useState, useEffect } from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import TablePagination from "@material-ui/core/TablePagination";
import { Grid } from "@material-ui/core";
import ProgressBar from "../../../../Other/ProgressBar";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

const headRows = [
  {
    id: "start_time",
    numeric: false,
    disablePadding: false,
    label: "Name",
    // label: "Dessert (100g serving)",
  },
  {
    id: "start_date",
    numeric: true,
    disablePadding: false,
    label: "Theme",
  },
  {
    id: "studio",
    numeric: true,
    disablePadding: false,
    label: "Tags",
  },
  {
    id: "physio_name",
    numeric: true,
    disablePadding: false,
    label: "Types",
  },
  {
    id: "updated",
    numeric: true,
    disablePadding: false,
    label: "Level",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Exercises",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Last updated",
  },
  {},
];

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    spacer: {
      flex: "1 1 100%",
    },
    actions: {
      color: theme.palette.text.secondary,
    },
    title: {
      flex: "0 0 auto",
    },
  }));

  const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      ></Toolbar>
    );
  };

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  return (
    <TableHead>
      <TableRow className="bg-secondary">
        {/* <TableCell padding="checkbox">
        </TableCell> */}
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            // align={row.numeric ? "right" : "left"}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));
const ClinicalRehabTable = ({ list }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const classes = useStyles();

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  return (
    // <Grid item lg={12}>
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
        >
          <EnhancedTableHead
            // numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            // onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={list.length}
          />
          <TableBody>
            {/* <tr>
              <td colSpan={9} align="middle" className="p-5">
                No results found
              </td>
            </tr> */}
            {list.length > 0 &&
              list.map((template, index) => (
                <TableRow>
                  <TableCell>{template.name}</TableCell>
                  <TableCell>Theme</TableCell>
                  <TableCell>Tags</TableCell>
                  <TableCell>Types</TableCell>
                  <TableCell>Level</TableCell>
                  <TableCell>Exercises</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Last updated</TableCell>
                  <TableCell>
                    <Link
                      component={RouterLink}
                      to={`/class-plans/templates/${template.id}`}
                      color="inherit"
                      className="pr-3"
                    >
                      {/* <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 svg-icon-sort"> */}
                      <SVG
                        style={{ width: "24px" }}
                        fill="currentColor"
                        src={toAbsoluteUrl("/media/svg/icons/notes.svg")}
                      />
                      {/* </span> */}
                    </Link>
                    <Link
                      to={`/class-plans/templates/${template.id}/read-only`}
                      component={RouterLink}
                      color="inherit"
                      className="pr-3"
                    >
                      <SVG
                        style={{ width: "24px" }}
                        fill="currentColor"
                        src={toAbsoluteUrl("/media/svg/icons/eye-solid.svg")}
                      />
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            {/* {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
          </TableBody>
        </Table>
      </div>
      <>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          // count={rehabList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        {/* <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          /> */}
      </>
    </div>
    // </Grid>
  );
};

export default ClinicalRehabTable;
