import axios from "axios";

const usersUrl = `${process.env.REACT_APP_API_URL}/auth/users`;
const userUrl = `${process.env.REACT_APP_API_URL}/auth/user`;

export function getUsersList() {
  return axios.get(usersUrl);
}

export function createUser(params) {
  return axios.post(`${userUrl}`, params);
}

export function updateUser(params) {
  return axios.put(`${userUrl}/${params.id}`, params);
}

export function updateProfile(params) {
  return axios.put(`${userUrl}`, params);
}

export function getUser(id) {
  return axios.get(`${userUrl}/${id}`);
}
