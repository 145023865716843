import moment from "moment";
import * as requestFromServer from "./coursesCrud";
import { coursesSlice, callTypes } from "./coursesSlice";

const { actions } = coursesSlice;

export const fetchCourses = (queryParams) => (dispatch) => {
  const courses = [
    {
      id: 1,
      name: "Course 1",
      date_range: {
        start: "1/1/2022",
        end: "31/3/2022",
      },
    },
    {
      id: 2,
      name: "Course 2",
      date_range: {
        start: "1/1/2022",
        end: "31/3/2022",
      },
    },
    {
      id: 3,
      name: "Course 3",
      date_range: {
        start: "1/1/2022",
        end: "31/3/2022",
      },
    },
  ];
  dispatch(actions.coursesListFetched(courses));
};

export const fetchCurrentCourse = (queryParams) => (dispatch) => {
  const course = {
    id: 1,
    name: "Course 1",
    date_range: {
      start: "1/1/2022",
      end: "31/3/2022",
    },
  };
  dispatch(actions.currentCourseFetched(course));
};

export const addNextCourse = (queryParams) => (dispatch) => {
  const { startDate, endDate } = queryParams;
  const data = {
    date_range: {
      start: startDate,
      end: endDate,
    },
  };
  dispatch(actions.nextCourseFetched(data));
};

export const fetchExercisePlans = () => (dispatch) => {
  const exercisePlansList = {
    in_progress: [
      {
        class_type: "Pilates",
        studio: "White studio",
        date_range: "1/4/2022 - 30/6/2022",
        date_set: "No",
        physio: "Sarah White",
        last_updated: "1/3/2022",
        status: "Draft",
      },
      {
        class_type: "Pilates",
        studio: "Blue studio",
        date_range: "1/4/2022 - 30/6/2022",
        date_set: "No",
        physio: "Matt Black",
        last_updated: "",
        status: "Not started",
      },
      {
        class_type: "Yoga",
        studio: "White studio",
        date_range: "1/4/2022 - 30/6/2022",
        date_set: "No",
        physio: "Sarah White",
        last_updated: "1/3/2022",
        status: "Draft",
      },
      {
        class_type: "Barre",
        studio: "Blue studio",
        date_range: "1/4/2022 - 30/6/2022",
        date_set: "No",
        physio: "Matt Black",
        last_updated: "",
        status: "Not started",
      },
    ],
    completed: [
      {
        class_type: "Barre",
        studio: "Blue studio",
        date_range: "1/4/2022 - 30/6/2022",
        date_set: "Yes",
        physio: "Matt Black",
        last_updated: "1/1/2022",
        status: "Published",
      },
      {
        class_type: "Barre",
        studio: "White studio",
        date_range: "1/4/2022 - 30/6/2022",
        date_set: "Yes",
        physio: "Sarah White",
        last_updated: "1/1/2022",
        status: "Published",
      },
    ],
  };
  dispatch(actions.exercisePlansFetched(exercisePlansList));
};

export const fetchBlockDates = () => (dispatch) => {
  requestFromServer
    .getBlocks()
    .then((response) => {
      dispatch(actions.blockSettingsFetched(response.data));
    })
    .then((err) => {
      //   dispatch(actions.catchError(err));
    });
};

export const saveBlockSettings = (data) => (dispatch) => {
  let block_setting = {
    block_settings: data,
  };
  dispatch(actions.startCall({ callTypes: callTypes.action }));
  requestFromServer
    .setBlocks(block_setting)
    .then((response) => {
      dispatch(actions.blockSettingsUpdateFetched(response.data));
    })
    .catch((error) => {
      dispatch(
        actions.catchError({
          error: "Cannot save dates.",
          callType: callTypes.action,
        })
      );
    });
};

export const fetchCurrentBlock = (data) => (dispatch) => {
  dispatch(actions.startCurrentBlockLoading("list"));
  requestFromServer
    .getCurrentBlock()
    .then((response) => {
      dispatch(actions.currentBlockClassListFetched(response.data));
    })
    .catch((err) => {
      dispatch(
        actions.catchError({
          error: err.response.data.message,
          callType: callTypes.action,
        })
      );
    });
};

export const fetchClassPatients = (class_id, has_draft) => (dispatch) => {
  dispatch(actions.startClassPatientsLoading());
  let class_patients = [];
  let class_draft = [];
  requestFromServer
    .getClassPatients(class_id)
    .then((response) => {
      // classPatients = response.data.data
      // console.log(response);
      let classPatients = response.data;
      classPatients.data.has_drafts = has_draft;
      dispatch(actions.classPatientListFetched(classPatients));
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        actions.catchError({
          error: err.response.data.message,
          callType: callTypes.action,
        })
      );
    });
};

export const patientSearch = (key) => (dispatch) => {
  dispatch(actions.searchedPatientListLoading());

  if (key == "") {
    dispatch(actions.searchedPatientListFetched({ data: [] }));
    return;
  }
  requestFromServer
    .patientSearch(key)
    .then((response) => {
      dispatch(actions.searchedPatientListFetched(response.data));
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        actions.catchError({
          error: err.response.data.message,
          callType: callTypes.action,
        })
      );
    });
};

export const addPatientFromSearch = (patient, uid) => (dispatch) => {
  let patient_uid = generateUID();
  let patient_data = {
    patient: patient,
    uid: uid,
    patient_uid: patient_uid,
  };
  dispatch(actions.startClassPatientsLoading({ patient_id: patient.id }));
  setTimeout(() => {
    dispatch(actions.addSearchPatientToClass(patient_data));
  }, 1000);
};

export const updatePatientFromSearch = (uid, patient, index) => (dispatch) => {
  let patient_data = {
    patient: patient,
    uid: uid,
    index: index,
  };

  dispatch(actions.startClassPatientsLoading({ patient_id: patient.id }));
  setTimeout(() => {
    dispatch(actions.updateSearchPatientToClass(patient_data));
  }, 1000);
};

export const confirmPatient = (patient_data) => (dispatch) => {
  dispatch(actions.patientsConfirmUpdated(patient_data));
};

export const removePatientFromList = (patient_data) => (dispatch) => {
  dispatch(actions.startClassPatientsLoading());
  setTimeout(() => {
    dispatch(actions.removePatientFromList(patient_data));
  }, 1000);
};

export const saveDraft = (draft_data, skip_holidays) => (dispatch) => {
  dispatch(actions.startDraftLoading());
  // console.log("draft_data");
  // console.log(draft_data);
  requestFromServer
    .saveDraft({ data: draft_data, skip_holidays: skip_holidays })
    .then((response) => {
      if (response.status == 200) {
        dispatch(actions.draftSaved("Draft saved successfully."));
      }
    })
    .catch((err) => {
      if (err.response.status == 409) {
        dispatch(actions.conflictError());
        return;
      }
      dispatch(
        actions.catchError({
          error: err.response.data.message,
          callType: callTypes.action,
        })
      );
    });
};

const exportByDraftId = async (data, dispatch) => {
  let progress = (1 / data.length) * 100;
  let requestConflict = false;
  let lockdownUid = null;

  await requestFromServer
    .startLockDown()
    .then((res) => {
      const { uid } = res.data;
      lockdownUid = uid;
    })
    .catch((err) => {
      if (err.response.status == 409) {
        requestConflict = true;
        const { message } = err.response.data;
        dispatch(actions.conflictError(message));
        return;
      }
    });

  if (!requestConflict) {
    for (let i = 0; i < data.length; i++) {
      await requestFromServer
        .exportByDraftId(data[i].id, lockdownUid)
        .then((res) => {
          dispatch(actions.draftExported(progress));
          if (i == data.length - 1) {
            dispatch(actions.exportFinished());
          }
        })
        .catch((err) => {
          dispatch(
            actions.catchError({
              error: err.response.data.message,
              callType: callTypes.action,
            })
          );
        });
    }
    await requestFromServer.endLockDown();
  }
};

export const exportToMindBody = () => (dispatch) => {
  dispatch(actions.startExportLoading());

  requestFromServer
    .getClassesToExport()
    .then((response) => {
      const { data } = response;
      // console.log(data);
      if (response.status == 200) {
        exportByDraftId(data, dispatch);
      }
    })
    .catch((err) => {
      if (err.response.status == 409) {
        dispatch(actions.conflictError());
        return;
      }
      dispatch(
        actions.catchError({
          error: err.response.data.message,
          callType: callTypes.action,
        })
      );
    });
};

export const validateExport = (message) => (dispatch) => {
  dispatch(actions.exportValidated(message));
};

export const searchReset = () => (dispatch) => {
  // requestFromServer.abortRequests();
  dispatch(actions.resetSearch());
};

export const statusReset = (data) => (dispatch) => {
  dispatch(actions.resetStatus());
};

export const confirmConflictError = (data) => (dispatch) => {
  dispatch(actions.conflictErrorClose());
};

// export const getPatient = async () => ()=>{
//   return await 'test';
// }

export const getPatient = async (classes, dispatch) => {
  for (let i = 0; i < classes.length; i++) {
    let retries = 0;
    let maxRetries = 5;
    let retryDelay = 10000; //10s delay
    let success = false;
    let class_id = classes[i].class_id;
    let error = false;

    for (let it = 0; it <= maxRetries; it++) {
      if (!success && retries !== 5) {
        console.log("run script");
        // error && timeout(retryDelay);
        if (error) {
          console.log("delay request");
          await new Promise((resolve) => setTimeout(resolve, retryDelay));
        }
        error = false;
        await requestFromServer
          .getPatiensByClassId(classes[i])
          .then((res) => {
            success = true;

            res.data.patients.map((patient) => {
              let uid = generateUID();
              patient.uid = uid;
            });

            dispatch(
              actions.classPatientsUpdated({
                patients: res.data.patients,
                uid: classes[i].uid,
              })
            );
          })
          .catch((err) => {
            error = true;
            retries++;
            dispatch(
              actions.catchError({
                error: err?.response?.data?.message,
                callType: callTypes.action,
              })
            );
          });
      }
    }

    if (retries == 5) {
      dispatch(actions.progressUpdate());
    }
  }
};

export const fetchClasses = (data) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  requestFromServer
    .getClasses()
    .then((res) => {
      const { data } = res;
      const { classes, previous_current_block } = data;
      let classesWithinBlock = [];

      classes.map((cls) => {
        let uid = generateUID();
        cls.uid = uid;
        // if (
        //   moment(cls.start_date) >= moment(previous_current_block.date_from) &&
        //   moment(cls.end_date) <= moment(previous_current_block.date_to)
        // ) {
        cls.start_date_time = cls.start_date + " " + cls.start_time;
        //   cls.endDateTime = cls.end_date + " " + cls.end_time;
        //   cls.time =
        //     moment(cls.startDateTime).format("LT") +
        //     " - " +
        //     moment(cls.endDateTime).format("LT");
        classesWithinBlock = [...classesWithinBlock, cls];
        // }
      });
      // sort by datetime
      classesWithinBlock.sort(
        (a, b) => moment(a.start_date) - moment(b.start_date)
      );
      data.classes = classesWithinBlock;
      dispatch(actions.classesFetched(res.data));
      dispatch(actions.startCall({ callType: callTypes.classesLoading }));

      if (classesWithinBlock.length == 0) {
        dispatch(actions.emptyClassFetched());
        return;
      }
      getPatient(classesWithinBlock, dispatch);
    })
    .catch((er) => {
      console.log(er);
      dispatch(
        actions.catchError({
          error: er.response.data.message,
          callType: callTypes.action,
        })
      );
    });
};

export const updatePatientMark = (uid, patient_id, mark_id) => (dispatch) => {
  // console.log(patient_id, mark_id);
  let data = {
    uid: uid,
    patient_id: patient_id,
    mark_id: mark_id,
  };
  dispatch(actions.patientMarkUpdated(data));
};

export const changePatient = (uid, index, patient) => (dispatch) => {
  console.log(uid, index, patient);
};

export const removePatient = (uid, patient_id) => (dispatch) => {
  // console.log(class_id, patient_id);
  let data = {
    uid: uid,
    patient_id: patient_id,
  };
  dispatch(actions.patientRemoved(data));
};

const verifyClassSchedule = async (classes, next_block_id, dispatch) => {
  let progress = (1 / classes.length) * 100;
  let requestConflict = false;
  let lockdownUid = null;

  await requestFromServer
    .startLockDown()
    .then((res) => {
      const { uid } = res.data;
      lockdownUid = uid;
    })
    .catch((err) => {
      if (err.response.status == 409) {
        requestConflict = true;
        const { message } = err.response.data;
        dispatch(actions.conflictError(message));
        return;
      }
    });

  if (!requestConflict) {
    for (let i = 0; i < classes.length; i++) {
      await requestFromServer
        .verifyClass(
          classes[i].schedule_id,
          classes[i].class_id,
          next_block_id,
          lockdownUid
        )
        .then((res) => {
          let dd = {
            classData: classes[i],
            data: res.data,
            progress: progress,
          };
          dispatch(actions.classVerified(dd));
          if (i == classes.length - 1) {
            dispatch(actions.verificationFinished());
          }
        })
        .catch((err) => {
          dispatch(
            actions.classVerified({
              classData: classes[i],
              data: err.response,
              progress: progress,
            })
          );
          if (i == classes.length - 1) {
            dispatch(actions.verificationFinished());
          }
        });
    }

    await requestFromServer.endLockDown();
  }
};

export const verify = (classes, next_block_id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.verifyLoading }));
  verifyClassSchedule(classes, next_block_id, dispatch);
};

export const verifySkipHolidays = (classes, next_block_id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.verifyLoading }));
  let newClasses = classes.map((clx) => ({
    ...clx,
    skip_holidays: true,
  }));

  verifyClassSchedule(newClasses, next_block_id, dispatch);
};

export const resetVerificationProgress = () => (dispatch) => {
  dispatch(actions.verificationProgressReset());
};

export const finishClassLoading = () => (dispatch) => {
  dispatch(actions.classesLoadingFinished());
};

export const transferPatient = (from, to) => (dispatch) => {
  // console.log(data, transfer_to);
  dispatch(actions.patientTransfered({ from: from, to: to }));
};

export const fetchPatientsAfterReset = (classes) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.classesLoading }));

  if (classes.length == 0) {
    dispatch(actions.emptyClassFetched());
    return;
  }
  getPatient(classes, dispatch);
};

export const resetDraft = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.resetDraftLoading }));

  requestFromServer
    .resetDraft()
    .then((res) => {
      dispatch(actions.draftReset());
    })
    .catch((err) => {
      actions.catchError({
        error: err.response.data.message,
        callType: callTypes.action,
      });
    });
};

// latest draft

export const fetchLatesDraft = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.latestDraftLoading }));

  requestFromServer
    .getLatestDraft()
    .then((res) => {
      dispatch(actions.latestDraftFetched(res.data));
    })
    .catch((err) => {
      dispatch(
        actions.catchError({
          error: err.response.data.message,
          callType: callTypes.action,
        })
      );
    });
};

// previous export

export const fetchPreviousRecords = () => async (dispatch) => {
  dispatch(actions.startPreviousExportCall());
  requestFromServer
    .getPreviousExports()
    .then((res) => {
      // console.log(res.data);
      dispatch(actions.previousRecordsFetched(res.data));
    })
    .catch((err) => {
      dispatch(
        actions.catchError({
          error: err.response.data.message,
          callType: callTypes.action,
        })
      );
    });
};

export const fetchPreviousExportClasses = (block_id) => (dispatch) => {
  // console.log("block_id", block_id);
  dispatch(actions.startCall({ callType: callTypes.list }));
  requestFromServer
    .getPreviousExport(block_id)
    .then((res) => {
      dispatch(actions.previousExportClassesFetched(res.data));
    })
    .catch((err) => {
      dispatch(
        actions.catchError({
          error: err.response.data.message,
          callType: callTypes.action,
        })
      );
    });
};

export const generateUID = () => {
  let dt = new Date().getTime();
  let uid = `xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxxx`.replace(/[xy]/g, function(
    c
  ) {
    let r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });

  return uid;
};

export const addClass = (newClassData) => (dispatch) => {
  // console.log(data.physio_name);
  // let time = `${newClassData.start_time}:00 - ${newClassData.end_time}`;
  dispatch(actions.startCall({ callType: callTypes.addClassLoading }));

  let start_date = newClassData.start_date;
  let start_time = `${newClassData.start_time}:00`;
  let end_time = `${newClassData.end_time}:00`;
  let start_time_string = moment(`${start_date} ${start_time}`).format("LT");
  let end_time_string = moment(`${start_date} ${end_time}`).format("LT");
  let start_date_time = `${start_date} ${start_time}`;
  let time = `${start_time_string} - ${end_time_string}`;
  // let uid = Date.now();
  let uid = generateUID();

  let data = {
    class_id: null,
    physio_name: newClassData.physio_name.name,
    staff_id: newClassData.physio_name.id,
    staff_email: newClassData.physio_name.email,
    studio: newClassData.studio,
    max_capacity: newClassData.max_capacity,
    schedule_id: newClassData.schedule_id ?? null,
    start_date: start_date,
    end_date: start_date,
    start_time: start_time,
    start_date_time: start_date_time,
    end_time: end_time,
    time: time,
    patients: [],
    uid: uid,
  };

  setTimeout(() => {
    dispatch(actions.classAdded(data));
  }, 500);
};

export const removeClass = (uid) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.removeClassLoading }));

  setTimeout(() => {
    dispatch(actions.classRemoved(uid));
  }, 500);
};

export const updateScheduleId = (uid, newScheduleId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.updateScheduleIdLoading }));

  setTimeout(() => {
    dispatch(
      actions.scheduleIdUpdated({ uid: uid, newScheduleId: newScheduleId })
    );
  }, 500);
};
