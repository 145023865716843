import React from "react";
import Select, { StylesConfig } from "react-select";

const colourStyles: StylesConfig<ColourOption, true> = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      ":active": {
        backgroundColor: "#ffe5e5",
      },
      ":focus": {
        backgroundColor: "#ffe5e5",
      },
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      padding: "3px",
      backgroundColor: "#ffe5e5",
    };
  },
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#ff5764 ",
      color: "white",
    },
  }),
};

const CustomSel = (props) => {
  return (
    <Select
      {...props}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "#ffe5e5",
          primary: "#ff8a93",
        },
      })}
      styles={colourStyles}
    />
  );
};
const CustomSelect = (props) => {
  return <CustomSel {...props} />;
};

export default CustomSelect;
