import React, { useState, useEffect } from "react";
import { Col, Row, Form, Spinner, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  addPatientFromSearch,
  updatePatientFromSearch,
} from "../../../_redux/coursesActions";
import { patientSearch } from "../../../_redux/coursesActions";
import "../../../styles/search-patient.css";

const PatientSearchModal = ({
  searchToggle,
  handleCloseSearch,
  // selectedClassId,
  patientSearchParams,
}) => {
  const dispatch = useDispatch();
  const {
    classPatientList,
    classPatientListLoading,
    searchedPatientList,
    searchedPatientListLoading,
    searchedPatientAdded,
    classPatientsFetched,
    currentBlockDraft,
  } = useSelector((state) => state.courses);

  const [patientsList, setPatientList] = useState([]);

  useEffect(() => {
    handleCloseSearch();
  }, [searchedPatientAdded]);

  const handleSearch = (e) => {
    let key = e.target.value;
    if (e.key == "Enter") {
      // console.log(key);
      dispatch(patientSearch(key));
    }
  };

  const checkIfAdded = (patient) => {
    let exist = 0;
    patientsList.map((pl) => {
      if (pl.id === patient.id) {
        exist++;
      }
    });
    if (exist > 0) {
    } else {
      return (
        <div className="patient-list">
          <Row
            style={{ height: "40px" }}
            className="my-2 rounded cursor-pointer d-flex align-items-center"
            onClick={() =>
              !classPatientListLoading ? handleAddPatient(patient) : ""
            }
          >
            <Col lg={4}>{patient.first_name}</Col>
            <Col lg={3}>{patient.last_name}</Col>
            <Col lg={3}>{patient.dob}</Col>

            <Col lg={2}>
              {patient.isLoading ? (
                <Spinner animation="border" variant="light" />
              ) : (
                ""
              )}
            </Col>
          </Row>
        </div>
      );
    }
  };

  const handleAddPatient = (patient) => {
    // console.log(patient, selectedClassId);
    if (patientSearchParams.type == "add") {
      dispatch(addPatientFromSearch(patient, patientSearchParams.uid));
    } else if (patientSearchParams.type == "update") {
      dispatch(
        updatePatientFromSearch(
          patientSearchParams.uid,
          patient,
          patientSearchParams.index
        )
      );
    }
    // let new_patient_list = [];
    // patientsList.map((pl) => {
    //   new_patient_list = [...new_patient_list, pl];
    // });
    // let new_patient = {
    //   id: patient.id,
    //   first_name: patient.first_name,
    //   last_name: patient.last_name,
    //   confirmed: true,
    // };
    // new_patient_list = [...new_patient_list, new_patient];

    // dispatch(addPatientFromSearch(new_patient_list));
  };
  return (
    <div>
      <Modal
        show={searchToggle}
        onHide={() => handleCloseSearch()}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-50w"
        centered
      >
        <Modal.Body>
          <div className="d-flex justify-content-end">
            <a href="javascript:void(0)" onClick={() => handleCloseSearch()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="black"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </a>
          </div>
          <Row className="my-5">
            {/* <Col lg={8} className="ml-auto"> */}
            <Form.Control
              type="text"
              placeholder="Search"
              onKeyDown={handleSearch}
            ></Form.Control>
            {/* </Col> */}
          </Row>
          <Row className="pb-3 border-bottom border-dark">
            <Col lg={4}>First name</Col>
            <Col lg={3}>Last name</Col>
            <Col lg={3}>DOB</Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Row className="mb-5 w-100">
              <Col style={{ maxHeight: "275px", overflow: "auto" }} lg={12}>
                {searchedPatientListLoading ? (
                  <Row className="my-5">
                    <Col lg={12} className="text-center">
                      <Spinner animation="border" variant="primary" />
                    </Col>
                  </Row>
                ) : searchedPatientList.length == 0 ? (
                  <Row className="p-5">
                    <Col lg={12} className="text-center">
                      No patient found
                    </Col>
                  </Row>
                ) : (
                  searchedPatientList.map((patient) => checkIfAdded(patient))
                )}
              </Col>
            </Row>
          </Row>
          {/* {searchedPatientList.length > 0 && (
            <Row className="d-flex justify-content-center">
              <Col lg={6}>
                <button className="btn btn-primary btn-md rounded-pill w-100">
                  Add patients to class
                </button>
              </Col>
            </Row>
          )}
          <Col lg={11}>
            <Row>
              <Col lg={6}>
                <button
                  className="btn btn-secondary btn-md rounded-pill w-100 text-primary"
                  onClick={() => {
                    handleCloseSearch();
                  }}
                >
                  Cancel
                </button>
              </Col>
              <Col lg={6}>
                <button className="btn btn-primary btn-md rounded-pill w-100">
                  Reset classes & patients
                </button>
              </Col>
            </Row>
          </Col> */}
          {/* </Row> */}
        </Modal.Body>
        {/* <Modal.Footer>
    <Button onClick={() => setResetConfirm(false)}>Close</Button>
  </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default PatientSearchModal;
