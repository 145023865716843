import { createSlice } from "@reduxjs/toolkit";

const blankVariation = {
  uid: Date.now(),
  name: "",
  equipment_level: null,
  equipment: null,
  springs: "",
  description: "",
  setup: "",
  cues: "",
  faulty_patterns: "",
  props: [],
  image: null,
  image_file: null,
  video: null,
  video_file: null,
  deleted: 0,
};

const blankExercise = {
  name: "",
  equipment: [],
  class_types: [],
  conditions: null,
  body_parts: [],
  precaution: [],
  contraindication: null,
  progression: [],
  regression: [],
  variations: [blankVariation],
};

const initialExerciseLibraryState = {
  list: [],
  exercise: null,
  tags: null,
  equipmentIcons: [],
  propIcons: [],
  showArchived: false,
  actionsLoading: false,
  saveExerciseLoading: false,
  saveExerciseSuccess: false,
  saveEquipmentLoading: false,
  savePropLoading: false,
  listLoading: false,
  message: "",
  isSuccess: false,
  isError: false,
  isInfo: false,
};

export const exerciseLibrarySlice = createSlice({
  name: "exerciseLibrary",
  initialState: initialExerciseLibraryState,
  reducers: {
    exerciseListFetched: (state, action) => {
      const { exercises } = action.payload;
      state.list = exercises;
      state.listLoading = false;
    },
    newExercisePopulated: (state, action) => {
      state.exercise = blankExercise;
    },
    editExercisePopulated: (state, action) => {
      const { exercise } = action.payload;
      exercise.variations.map((variation) => {
        variation.image_file = null;
        variation.video_file = null;
        variation.deleted = 0;
      });

      state.exercise = exercise;
      state.listLoading = false;
    },
    catchError: (state, action) => {
      state.isError = true;
      state.message = action.payload;
    },
    startCall: (state, action) => {
      state.isError = false;
      state[action.payload] = true;
    },
    exerciseFieldUpdated: (state, action) => {
      const { target, value } = action.payload;
      state.exercise = {
        ...state.exercise,
        [target]: value,
      };
    },
    bodyPartAdded: (state, action) => {
      const { target, value } = action.payload;
      // state.exercise.body_parts = [...state.exercise.body_parts, value];
      // console.log(state.exercise.body_parts, value);
      state.exercise.body_parts = value;
      // state.exercise.body_parts = [...state.exercise.body_parts, value];
    },
    variationFieldUpdated: (state, action) => {
      const { index, target, value, b64 = null } = action.payload;

      if (target == "image" || target == "video") {
        const reader = new FileReader();
        reader.readAsDataURL(value);
        if (target == "image") {
          state.exercise.variations[index] = {
            ...state.exercise.variations[index],
            image_file: b64,
            image_link: null,
          };
        }
        if (target == "video") {
          state.exercise.variations[index] = {
            ...state.exercise.variations[index],
            video_file: b64,
            video_link: null,
          };
        }
      }

      state.exercise.variations[index] = {
        ...state.exercise.variations[index],
        [target]: value,
      };
    },
    variationFileRemoved: (state, action) => {
      const { index, target } = action.payload;
      if (target == "image") {
        state.exercise.variations[index] = {
          ...state.exercise.variations[index],
          image_file: null,
          image_link: null,
        };
      }
      if (target == "video") {
        state.exercise.variations[index] = {
          ...state.exercise.variations[index],
          video_file: null,
          video_link: null,
        };
      }
      state.exercise.variations[index] = {
        ...state.exercise.variations[index],
        [target]: null,
      };
    },
    variationDuplicated: (state, action) => {
      const { index } = action.payload;
      let new_variation = {
        ...state.exercise.variations[index],
        id: null,
        uid: Date.now(),
        deleted: 0,
      };

      state.exercise.variations = [...state.exercise.variations, new_variation];
    },
    blankVariationAdded: (state, action) => {
      let new_variation = {
        ...blankVariation,
        uid: Date.now(),
      };
      state.exercise.variations = [...state.exercise.variations, new_variation];
    },
    variationRemoved: (state, action) => {
      let { index } = action.payload;
      let active_variations_count = 0;
      state.exercise.variations.map((variation) => {
        if (variation.deleted == 0) {
          active_variations_count++;
        }
      });
      if (active_variations_count == 1) {
        state.isError = true;
        state.message = "You need atleast 1 variation.";
        return;
      }
      // check if variation exists
      if (state.exercise.variations[index].id) {
        state.exercise.variations[index].deleted = 1;
      } else {
        state.exercise.variations.splice(index, 1);
      }
    },
    newEquipmentInserted: (state, action) => {
      console.log(action.payload);
      console.log("action.payload");
    },
    // get exercise tags
    tagsFetched: (state, action) => {
      const tags = action.payload;
      state.listLoading = false;
      state.isError = false;
      state.tags = tags;
    },
    equipmentIconsFetched: (state, action) => {
      const equipmentIcons = action.payload;
      // state.listLoading = false;
      state.isError = false;
      state.equipmentIcons = equipmentIcons;
    },
    equipmentSaved: (state, action) => {
      state.saveEquipmentLoading = false;

      const { equipment } = action.payload;

      state.exercise.equipment = [...state.exercise.equipment, equipment];
      state.tags.equipments = [...state.tags.equipments, equipment];
      state.message = "Equipment added!";
      state.isSuccess = true;
    },
    equipmentUpdated: (state, action) => {
      state.saveEquipmentLoading = false;

      const { equipment } = action.payload;
      let newExerciseEq = [];
      state.exercise.equipment.map((eq) => {
        if (eq.id == equipment.id) {
          eq = equipment;
        }
        newExerciseEq = [...newExerciseEq, eq];
      });

      let newTagsEq = [];
      state.tags.equipments.map((eq) => {
        if (eq.id == equipment.id) {
          eq = equipment;
        }
        newTagsEq = [...newTagsEq, eq];
      });

      state.exercise.equipment = newExerciseEq;
      state.tags.equipments = newTagsEq;
      state.message = "Equipment updated!";
      state.isSuccess = true;
    },
    propSaved: (state, action) => {
      state.saveEquipmentLoading = false;
      const { props, variation_index } = action.payload;

      state.exercise.variations[variation_index].props = [
        ...state.exercise.variations[variation_index].props,
        props,
      ];
      state.tags.props = [...state.tags.props, props];
      state.message = "Prop added!";
      state.isSuccess = true;
    },
    propUpdated: (state, action) => {
      state.saveEquipmentLoading = false;
      const { props, variation_index } = action.payload;

      // state.exercise.variations[variation_index].props = [
      //   ...state.exercise.variations[variation_index].props,
      //   props,
      // ];
      // state.tags.props = [...state.tags.props, props];
      let newVariationProps = [];
      state.exercise.variations[variation_index].props.map((prop) => {
        if (prop.id == props.id) {
          prop = props;
        }
        newVariationProps = [...newVariationProps, prop];
      });

      let newTagsProps = [];
      state.tags.props.map((prop) => {
        if (prop.id == props.id) {
          prop = props;
        }
        newTagsProps = [...newTagsProps, prop];
      });
      state.exercise.variations[variation_index].props = newVariationProps;
      state.tags.props = newTagsProps;
      state.message = "Prop updated!";
      state.isSuccess = true;
    },
    propIconsFetched: (state, action) => {
      const propIcons = action.payload;
      // state.listLoading = false;
      state.isError = false;
      state.propIcons = propIcons;
    },
    exerciseAdded: (state, action) => {
      state.actionsLoading = false;
      state.isSuccess = true;
      state.saveExerciseLoading = false;
      state.saveExerciseSuccess = true;
      state.message = "Exercise successfully added";
    },
    exerciseUpdated: (state, action) => {
      state.actionsLoading = false;
      state.isSuccess = true;
      state.saveExerciseLoading = false;
      state.message = "Exercise successfully updated";
    },
    exerciseArchived: (state, action) => {
      const exercise_id = action.payload;
      console.log("exercise_id : " + exercise_id);
      state.actionsLoading = false;
      state.isSuccess = true;
      // state.list.splice(index, 1);
      // state.list[index].archived_at = ".";
      state.list.map((row) => {
        if (row.exercise_id == exercise_id) {
          row.archived_at = ".";
        }
      });
      state.message = "Exercise successfully archived";
    },
    exerciseDeleted: (state, action) => {
      const exercise_id = action.payload;

      state.actionsLoading = false;
      state.isSuccess = true;

      let newExercises = [];
      // state.list.splice(index, 1);
      // state.list[index].archived_at = ".";
      state.list.map((row) => {
        if (row.exercise_id !== exercise_id) {
          newExercises = [...newExercises, row];
        }
      });

      state.list = newExercises;

      state.message = "Exercise successfully deleted";
    },
    exerciseReactivated: (state, action) => {
      state.exercise.archived_at = null;
      state.actionsLoading = false;
      state.isSuccess = true;

      state.message = "Exercise successfully re-activated";
    },
    saveResetted: (state, action) => {
      state.saveExerciseSuccess = false;
    },
    showArchiveToggled: (state, action) => {
      state.showArchived = !state.showArchived;
    },
    statusResetted: (state, action) => {
      state.message = "";
      state.saveExerciseLoading = false;
      state.savePropLoading = false;
      state.saveEquipmentLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.isInfo = false;
    },
  },
});
