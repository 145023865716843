import axios from "axios";

const templateApi = `${process.env.REACT_APP_API_URL}/class-plan/template`;
const templateListApi = `${process.env.REACT_APP_API_URL}/class-plan/templates`;

export const getTags = () => {
  return axios.get(`${templateApi}/tags`);
};

export const saveDraft = (payload) => {
  return axios.post(`${templateApi}`, payload);
};

export const getTemplate = (id) => {
  return axios.get(`${templateApi}/${id}`);
};

export const getTemplates = () => {
  return axios.get(`${templateListApi}`);
};
