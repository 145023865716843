import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import ClinicalRehabTable from "../components/ClinicalRehabTable";
import TableFilters from "../components/TableFilters";
import { useDispatch, useSelector } from "react-redux";
import ClinicalRehabSnackbars from "../components/snackbars/ClinicalRehabSnackbars";
import { Card, Col, Row } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import ClinicalRehabWeeks from "../components/ClinicalRehabWeeks";

const ClinicalRehabPage = () => {
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.clinicalRehab);
  const [rehabList, setRehabList] = useState([]);
  const initFilters = {
    physio: "",
    studio: "",
    status: "",
    key: "",
  };
  const [filters, setFilters] = useState(initFilters);

  const handleFilters = (val, target) => {
    // console.log(val, key);
    setFilters((filter) => ({
      ...filter,
      [target]: val,
    }));
  };

  const handleClearFilters = () => {
    setFilters(initFilters);
  };

  useEffect(() => {
    setRehabList(list);
  }, [list]);

  useEffect(() => {
    const filteredList = list.filter((row) => {
      if (
        // row.studio.toLowerCase().includes(filters.key.toLowerCase()) &&
        row.studio.toLowerCase().includes(filters.studio.toLowerCase()) &&
        row.physio_name.toLowerCase().includes(filters.physio.toLowerCase()) &&
        row.status.toLowerCase().includes(filters.status.toLowerCase()) &&
        // add patient fileter
        row.patients.find((patient) =>
          patient.patient_full_name
            .toLowerCase()
            .includes(filters.key.toLowerCase())
        )
      ) {
        return row;
      }
    });
    setRehabList(filteredList);
  }, [filters]);

  return (
    <Grid container>
      <ClinicalRehabSnackbars />
      <Grid item lg={12}>
        <TableFilters
          selectedFilters={filters}
          handleFilters={handleFilters}
          handleClearFilters={handleClearFilters}
        />
      </Grid>
      <Grid item lg={12}>
        <ClinicalRehabWeeks />
      </Grid>
      <Grid item lg={12}>
        <ClinicalRehabTable rehabList={rehabList} />
      </Grid>
    </Grid>
  );
};

export default ClinicalRehabPage;
