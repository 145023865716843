import { exercisePlanSlice } from "./exercisePlanSlice";
import * as requestFromServer from "./exercisePlanCrud";

const { actions } = exercisePlanSlice;

export const generateUID = () => {
  let dt = new Date().getTime();
  let uid = `xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxxx`.replace(/[xy]/g, function(
    c
  ) {
    let r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });

  return uid;
};

export const fetchExercisePlans = (schedule_id, class_id) => (dispatch) => {
  dispatch(actions.startCall("listLoading"));

  requestFromServer
    .getExercisePlans(schedule_id, class_id)
    .then((res) => {
      const { classes } = res.data;
      // classes.map((cls) => {
      if (classes) {
        classes.patients.map((patient) => {
          patient.uid = generateUID();
          patient.exercises = null;
          patient.exercisesLoading = false;
        });
      }
      // });
      // console.log(classes);
      // console.log(res);
      dispatch(actions.exercisePlansFetched(res.data));
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        actions.catchError({
          error: err.response.data.message,
        })
      );
    });
};

export const fetchPatientExercises = (patient, schedule_id, class_id) => (
  dispatch
) => {
  dispatch(actions.startPatientExerciseLoading(patient.uid));
  // if (patient.exercises == null) {
  requestFromServer
    .getPatientExercises(patient.patient_id, schedule_id, class_id)
    .then((res) => {
      if (res?.data?.patient_exercises) {
        res.data.patient_exercises.exercises.map((exercise) => {
          let uid = generateUID();
          exercise.uid = uid;

          exercise.variations.map((variation) => {
            let vUid = generateUID();
            variation.uid = vUid;
          });
        });
        dispatch(
          actions.patientExercisesFetched({
            patient_uid: patient.uid,
            exerciseData: res?.data,
          })
        );
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        actions.catchError({
          error: err?.response?.data?.message,
        })
      );
    });
  // }

  // dispatch(actions.patientExerciseLoadingEnd(patient.uid));
};

export const addToPatientExercises = (payload) => (dispatch) => {
  dispatch(actions.startCall("actionsLoading"));
  dispatch(actions.patientExerciseAdded(payload));
};

export const fetchFilters = () => (dispatch) => {
  requestFromServer
    .getFilters()
    .then((res) => {
      dispatch(actions.filtersFetched(res.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const statusReset = () => (dispatch) => {
  dispatch(actions.resetStatus());
};

export const addVariation = (payload) => (dispatch) => {
  // console.log(patient_id, exercise_id, variation);
  const uid = generateUID();
  let new_variation = { ...payload.variation };
  new_variation.sets = 0;
  new_variation.reps = 0;
  new_variation.uid = uid;
  payload.new_variation = new_variation;
  dispatch(actions.variationAdded(payload));
};

export const removeExistingExercise = (patient_id, exercise_uid) => (
  dispatch
) => {
  dispatch(
    actions.existingExerciseRemoved({
      patient_id: patient_id,
      exercise_uid: exercise_uid,
    })
  );
};

export const removeExercise = (payload) => (dispatch) => {
  // console.log(payload);
  dispatch(actions.exerciseRemoved(payload));
  // dispatch(
  //   actions.exerciseRemoved({
  //     patient_id: patient_id,
  //     exercise_uid: exercise_uid,
  //   })
  // );
};

export const removeVariation = (payload) => (dispatch) => {
  dispatch(actions.variationRemoved(payload));
};

export const handleVariationFieldChange = (payload) => (dispatch) => {
  dispatch(actions.variationFieldUpdated(payload));
};

export const handleVariationIntChange = (
  patient_id,
  exercise_uid,
  variation_uid,
  target,
  val
) => (dispatch) => {
  dispatch(
    actions.variationIntUpdated({
      patient_id: patient_id,
      exercise_uid: exercise_uid,
      variation_uid: variation_uid,
      target: target,
      val: val,
    })
  );
};

export const handleExerciseEquipmentChange = (payload) => (dispatch) => {
  dispatch(actions.equipmentSelected(payload));
};

export const handleCheckExercise = (patient_id, exercise_uid) => (dispatch) => {
  dispatch(
    actions.exerciseChecked({
      patient_id: patient_id,
      exercise_uid: exercise_uid,
    })
  );
};

export const fetchProps = (variation_id, patient_exercise_id) => (dispatch) => {
  dispatch(actions.startCall("propsLoading"));

  requestFromServer
    .getProps(variation_id, patient_exercise_id)
    .then((res) => {
      // console.log(res.data);
      dispatch(actions.propsFetched(res.data));
      // dispatch(actions.draftSaved());
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        actions.catchError({
          error: err.response.data.message,
        })
      );
    });
};

export const updateProps = (payload) => (dispatch) => {
  // console.log(patient_id, exercise_uid, variation_uid);
  dispatch(actions.propsUpdated(payload));
};

export const saveDraft = (payload, index) => (dispatch) => {
  dispatch(actions.startCall("saveDraftLoading"));
  // format props
  // let newExercises = [];
  // payload.exercises.map((exercise) => {
  //   let newExercise = { ...exercise };
  //   let newVariations = [];
  //   newExercise.variations.map((variation) => {
  //     let newVariation = { ...variation };
  //     let newProps = [];
  //     newVariation.props.map((prop) => {
  //       newProps = [...newProps, prop.id];
  //     });
  //     newVariation.props = newProps;
  //     newVariations = [...newVariations, newVariation];
  //   });
  //   newExercise.variations = newVariations;
  //   newExercises = [...newExercises, newExercise];
  // });

  // let newPayload = { ...payload };
  // newPayload.exercises = newExercises;

  requestFromServer
    .saveExercisesDraft(payload)
    .then((res) => {
      dispatch(actions.draftSaved(index));
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        actions.catchError({
          error: err.response.data.message,
        })
      );
    });
};

export const publish = (payload, index) => (dispatch) => {
  dispatch(actions.startCall("publishLoading"));

  requestFromServer
    .publishPatientExercises(payload)
    .then((res) => {
      dispatch(actions.exercisePublished(index));
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        actions.catchError({
          error: err.response.data.message,
        })
      );
    });
};

// new functions

export const reOrderExercises = (payload) => (dispatch) => {
  dispatch(actions.patientExercisesReordered(payload));
};
