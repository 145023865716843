import { createSlice } from "@reduxjs/toolkit";

const initialRehabState = {
  list: [],
  filters: [],
  patientNotes: null,
  exercisePlans: null,
  patientHistory: [],
  weeks: [],
  activeWeek: null,
  patientNotesLoading: false,
  weeksLoading: false,
  listLoading: false,
  historyLoading: false,
  noteSaveLoading: false,
  actionsLoading: false,
  isSuccess: false,
  isError: false,
  isInfo: false,
  message: "",
};

export const clinicalRehabSlice = createSlice({
  name: "clinicalRehab",
  initialState: initialRehabState,
  reducers: {
    catchError: (state, action) => {
      const { error } = action.payload;

      state.isError = true;
      state.message = error;
      state.listLoading = false;
      state.historyLoading = false;
    },
    startCall: (state, action) => {
      state[action.payload] = true;
    },
    weekSet: (state, action) => {
      const weekIndex = action.payload;

      state.activeWeek = state.weeks[weekIndex];
    },
    weeksFetched: (state, action) => {
      const weeks = action.payload;
      state.weeks = weeks;
      state.weeksLoading = false;
    },
    rehabListFetched: (state, action) => {
      const { classes, filters } = action.payload;

      state.list = classes;
      state.filters = filters;
      state.listLoading = false;
    },
    patientHistoryFetched: (state, action) => {
      const data = action.payload;

      state.patientHistory = data;
      state.historyLoading = false;
    },
    patientNotesFetched: (state, action) => {
      const data = action.payload;

      state.patientNotes = data;
      state.patientNotesLoading = false;
    },
    resetStatus: (state, action) => {
      state.isSuccess = false;
      state.isError = false;
      state.isInfo = false;
      state.message = "";
    },
    exercisePlansFetched: (state, action) => {
      state.exercisePlans = action.payload;
      // const { patients } = action.payload;
      // console.log(patients);
    },
    noteFieldChanged: (state, action) => {
      const { target, val } = action.payload;

      state.patientNotes[target] = val;
    },
    noteSaved: (state, action) => {
      state.isSuccess = true;
      state.message = "Note successfullys saved.";
      state.noteSaveLoading = false;
    },
  },
});
