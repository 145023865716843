import React, { useState } from "react";
import { Row, Col, Image, Form } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import CustomSelect from "../../../Other/CustomSelect";
import {
  handleVariationFieldChange,
  handleVariationIntChange,
  removeVariation,
} from "../../_redux/exercisePlanActions";
import { useDispatch } from "react-redux";
import PropsModal from "../modals/PropsModal";

const VariationRow = ({ variation, exercise, patient, readOnly }) => {
  const dispatch = useDispatch();

  const [propsModal, setPropsModal] = useState();

  const handleRemoveVariation = () => {
    dispatch(removeVariation(patient.id, exercise.uid, variation.uid));
  };

  const handleFieldChange = (e) => {
    const target = e.target.name;
    const val = e.target.value;

    dispatch(
      handleVariationFieldChange(
        patient.id,
        exercise.uid,
        variation.uid,
        target,
        val
      )
    );
  };

  const handleIntChange = (e) => {
    const target = e.target.name;
    const val = e.target.value;

    dispatch(
      handleVariationIntChange(
        patient.id,
        exercise.uid,
        variation.uid,
        target,
        val
      )
    );
  };

  return (
    <Row className="justify-content-end mb-3">
      <PropsModal
        show={propsModal}
        onHide={() => setPropsModal(false)}
        variation_id={variation.id}
        patients_exercise_id={exercise.patients_exercise_id}
        selected_props={variation.props}
        exercise_uid={exercise.uid}
        variation_uid={variation.uid}
        patient_id={patient?.id}
      />
      <Col lg={10} className="border border-dark p-3">
        <Row className="align-items-center">
          <Col lg={2}>
            <Row className="justify-content-center">
              <Image
                src={toAbsoluteUrl(
                  variation.image_link
                    ? variation.image_link
                    : "/media/images/default-exercise.png"
                )}
                height={115}
                width={115}
                className="border"
              />
            </Row>
          </Col>
          <Col lg={2}>
            <Row className="mb-3">
              <Col>
                <Form.Control value={variation.name} disabled />
                {/* <CustomSelect placeholder="select variation" 
                    
                /> */}
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  disabled
                  // placeholder="Enter springs"
                  value={variation.springs}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className="mb-3 justify-content-center align-items-center">
              <Col className="text-right">
                {variation.props.map((prop) => (
                  <svg
                    width="20"
                    height="20"
                    viewBox={prop?.properties?.viewBox}
                    fill="black"
                    xmlns={prop?.properties?.xmlns}
                  >
                    <path d={prop?.properties?.path} fill="black" />
                  </svg>
                ))}
              </Col>
              <Col lg={6}>
                <button
                  className="btn btn-sm btn-primary text-white"
                  onClick={() => setPropsModal(true)}
                  disabled={readOnly}
                >
                  + prop
                </button>
              </Col>
            </Row>
            <Row className="justify-content-center align-items-center">
              <Col lg={8}>
                <Row className="justify-content-around">
                  <Col lg={3}>
                    <Row
                      className={`rounded py-1 justify-content-center ${
                        variation.equipment_level == "basic"
                          ? "bg-primary text-white "
                          : "bg-secondary text-dark"
                      }`}
                    >
                      Bas
                    </Row>
                  </Col>
                  <Col lg={3}>
                    <Row
                      className={`rounded py-1 justify-content-center ${
                        variation.equipment_level == "intermediate"
                          ? "bg-primary text-white "
                          : "bg-secondary text-dark"
                      }`}
                    >
                      Int
                    </Row>
                  </Col>
                  <Col lg={3}>
                    <Row
                      className={`rounded py-1 justify-content-center ${
                        variation.equipment_level == "advanced"
                          ? "bg-primary text-white "
                          : "bg-secondary text-dark"
                      }`}
                    >
                      Adv
                    </Row>
                  </Col>
                </Row>
              </Col>

              {/* <ButtonToolbar>
                <ToggleButtonGroup
                  type="radio"
                  name="options"
                  defaultValue={variation.equipment_level}
                >
                  <ToggleButton value="basic">Bas</ToggleButton>
                  <ToggleButton value="intermediate">Int</ToggleButton>
                  <ToggleButton value="advanced">Adv</ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar> */}
            </Row>
          </Col>
          <Col>
            <Row>
              Description based on the variation selection, can be a long
              description but could actionsLoadingbe short but still needs to
              wrap
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Sets"
                  name="sets"
                  min={0}
                  value={variation.sets}
                  onChange={handleIntChange}
                  disabled={readOnly}
                />
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Reps"
                  name="reps"
                  min={0}
                  value={variation.reps}
                  onChange={handleIntChange}
                  disabled={readOnly}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col lg={1} className="d-flex justify-content-center align-items-center">
        <a
          href="javascript:void(0)"
          onClick={() => {
            handleRemoveVariation();
          }}
          style={{
            pointerEvents: readOnly && "none",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-dash-circle"
            viewBox="0 0 16 16"
            style={{ marginLeft: "-15px" }}
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
          </svg>
        </a>
      </Col>
      {/* variation rows */}
    </Row>
  );
};

export default VariationRow;
