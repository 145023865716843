import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
}));

const ProgressBar = () => {
  const classes = useStyles();
  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    function progress() {
      setCompleted((oldCompleted) => {
        if (oldCompleted === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldCompleted + diff, 100);
      });
    }

    const timer = setInterval(progress, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div
      //   className="w-100 text-center"
      style={{
        position: "absolute",
        top: "40%",
        left: "50%",
        zIndex: "1",
      }}
    >
      {/* <CircularProgress className={classes.progress} /> */}
      <CircularProgress
        style={{
          color: "#ff8a93",
        }}
      />
    </div>
  );
};

export default ProgressBar;
