import { createSlice } from "@reduxjs/toolkit";

const initialRehabState = {
  list: null,
  filters: null,
  from: null,
  to: null,
  prop_options: [],
  listLoading: false,
  actionsLoading: false,
  saveDraftLoading: false,
  publishLoading: false,
  propsLoading: false,
  isSuccess: false,
  isError: false,
  isInfo: false,
  message: "",
};

export const exercisePlanSlice = createSlice({
  name: "exercisePlans",
  initialState: initialRehabState,
  reducers: {
    catchError: (state, action) => {
      const { error } = action.payload;

      state.isError = true;
      state.message = error;
    },
    startCall: (state, action) => {
      state[action.payload] = true;
    },
    startPatientExerciseLoading: (state, action) => {
      const patient_uid = action.payload;
      // let newList = [];
      // state.list.map((cls) => {
      state.list.patients.map((patient) => {
        if (patient.uid == patient_uid) {
          patient.exercisesLoading = true;
        }
      });
      // newList = [...newList, cls];
      // // });
      // state.list = newList;
    },
    patientExercisesFetched: (state, action) => {
      const { patient_uid, exerciseData } = action.payload;
      // let newList = [];
      // state.list.map((cls) => {
      state.list.patients.map((patient) => {
        if (patient.uid == patient_uid) {
          patient.exercises = exerciseData;
          patient.exercisesUI = exerciseData;
          patient.exercisesLoading = false;
        }
      });
      // newList = [...newList, cls];
      // });
      // state.list = newList;
    },
    patientExerciseLoadingEnd: (state, action) => {
      const patient_uid = action.payload;
      let newList = [];
      state.list.map((cls) => {
        cls.patients.map((patient) => {
          if (patient.uid == patient_uid) {
            patient.exercisesLoading = false;
          }
        });
        newList = [...newList, cls];
      });
      state.list = newList;
    },
    exercisePlansFetched: (state, action) => {
      const { classes, from, to } = action.payload;
      //   state.exercisePlans = action.payload;
      state.list = classes;
      // state.filters = filters;
      state.from = from;
      state.to = to;
      state.listLoading = false;
      // const { patients } = action.payload;
      // console.log(patients);
    },
    filtersFetched: (state, action) => {
      const { filters } = action.payload;

      state.filters = filters;
    },
    patientExerciseAdded: (state, action) => {
      const { exercises, patient_id } = action.payload;
      console.log("exercises");
      console.log(exercises);
      let newList = [];

      // state.list.map((d) => {
      //   if (d.schedule_id == schedule_id) {
      state.list.patients.map((patient) => {
        if (patient.patient_id == patient_id) {
          patient.exercisesUI.patient_exercises.exercises = [
            ...patient.exercisesUI.patient_exercises.exercises,
            ...exercises,
          ];
        }
      });
      // }
      //   newList = [...newList, d];
      // });

      // console.log(newList);
      // state.list = newList;
    },
    existingExerciseRemoved: (state, action) => {
      const { patient_id, exercise_uid } = action.payload;

      state.list.patients.map((patient) => {
        if (patient.id == patient_id) {
          // let newExercises = [];
          patient.exercisesUI.patient_exercises.exercises.map((exercise) => {
            if (exercise.uid == exercise_uid) {
              // if (exercise.id) {
              // has id
              exercise.hide.value = true;
              console.log("hidden : " + exercise_uid);
              // }
            }
            // newExercises = [...newExercises, exercise];
          });
        }
      });
    },
    exerciseRemoved: (state, action) => {
      const { patient_index, exercise_index } = action.payload;

      state.list.patients[
        patient_index
      ].exercisesUI.patient_exercises.exercises = state.list.patients[
        patient_index
      ].exercisesUI.patient_exercises.exercises.filter(
        (v, i) => exercise_index !== i
      );
    },
    variationAdded: (state, action) => {
      const { patient_index, exercise_index, new_variation } = action.payload;
      // console.log(patient_index, exercise_index, new_variation);
      // state.list.patients.map((patient) => {
      //   if (patient.id == patient_id) {
      //     patient.exercisesUI.patient_exercises.exercises.map((exercise) => {
      //       if (exercise.uid == exercise_uid) {
      //         exercise.variations = [...exercise.variations, new_variation];
      //       }
      //     });
      //   }
      // });

      state.list.patients[
        patient_index
      ].exercisesUI.patient_exercises.exercises[exercise_index].variations = [
        ...state.list.patients[patient_index].exercisesUI.patient_exercises
          .exercises[exercise_index].variations,
        new_variation,
      ];
    },
    variationRemoved: (state, action) => {
      const { patient_index, exercise_index, variation_index } = action.payload;

      state.list.patients[
        patient_index
      ].exercisesUI.patient_exercises.exercises[
        exercise_index
      ].variations = state.list.patients[
        patient_index
      ].exercisesUI.patient_exercises.exercises[
        exercise_index
      ].variations.filter((v, i) => variation_index !== i);

      // state.list.patients.map((patient) => {
      //   if (patient.id == patient_id) {
      //     patient.exercisesUI.patient_exercises.exercises.map((exercise) => {
      //       if (exercise.uid == exercise_uid) {
      //         let newVar = [];
      //         exercise.variations.map((variation) => {
      //           if (variation.uid !== variation_uid) {
      //             // exercise.variations = [...exercise.variations, new_variation];
      //             newVar = [...newVar, variation];
      //           }
      //         });

      //         exercise.variations = newVar;
      //       }
      //     });
      //   }
      // });
    },
    variationFieldUpdated: (state, action) => {
      const {
        patient_index,
        exercise_index,
        variation_index,
        target,
        val,
      } = action.payload;
      // console.log(patient_id, exercise_id, variation_uid, target, val);
      state.list.patients[
        patient_index
      ].exercisesUI.patient_exercises.exercises[exercise_index].variations[
        variation_index
      ][target] = val;
      // state.list.patients.map((patient) => {
      //   if (patient.id == patient_id) {
      //     patient.exercisesUI.patient_exercises.exercises.map((exercise) => {
      //       if (exercise.uid == exercise_uid) {
      //         exercise.variations.map((variation) => {
      //           if (variation.uid == variation_uid) {
      //             // exercise.variations = [...exercise.variations, new_variation];
      //             // newVar = [...newVar, variation];
      //             variation[target] = val;
      //           }
      //         });
      //       }
      //     });
      //   }
      // });
    },
    variationIntUpdated: (state, action) => {
      const {
        patient_id,
        exercise_uid,
        variation_uid,
        target,
        val,
      } = action.payload;
      // console.log(patient_id, exercise_id, variation_uid, target, val);
      state.list.patients.map((patient) => {
        if (patient.id == patient_id) {
          patient.exercisesUI.patient_exercises.exercises.map((exercise) => {
            if (exercise.uid == exercise_uid) {
              exercise.variations.map((variation) => {
                if (variation.uid == variation_uid) {
                  // exercise.variations = [...exercise.variations, new_variation];
                  // newVar = [...newVar, variation];
                  variation[target] = parseInt(val);
                }
              });
            }
          });
        }
      });
    },
    exerciseChecked: (state, action) => {
      const { patient_id, exercise_uid } = action.payload;

      state.list.patients.map((patient) => {
        if (patient.id == patient_id) {
          patient.exercisesUI.patient_exercises.exercises.map((exercise) => {
            if (exercise.uid == exercise_uid) {
              exercise.checked = exercise.checked == 0 ? 1 : 0;
            }
          });
        }
      });
    },
    propsFetched: (state, action) => {
      const { props } = action.payload;
      state.propsLoading = false;
      state.prop_options = props;
    },
    propsUpdated: (state, action) => {
      const {
        patient_index,
        exercise_index,
        variation_index,
        selected_props,
      } = action.payload;

      state.list.patients[
        patient_index
      ].exercisesUI.patient_exercises.exercises[exercise_index].variations[
        variation_index
      ].props = selected_props;

      // state.list.patients.map((patient) => {
      //   if (patient.id == patient_id) {
      //     patient.exercisesUI.patient_exercises.exercises.map((exercise) => {
      //       if (exercise.uid == exercise_uid) {
      //         exercise.variations.map((variation) => {
      //           if (variation.uid == variation_uid) {
      //             // exercise.variations = [...exercise.variations, new_variation];
      //             // newVar = [...newVar, variation];
      //             // variation[target] = val;
      //             variation.props = props;
      //           }
      //         });
      //       }
      //     });
      //   }
      // });
    },
    equipmentSelected: (state, action) => {
      const { patient_index, exercise_index, val } = action.payload;

      state.list.patients[
        patient_index
      ].exercisesUI.patient_exercises.exercises[exercise_index].equipment = val;
    },
    draftSaved: (state, action) => {
      const patient_index = action.payload;

      // state.list;
      state.list.patients[patient_index].exercises =
        state.list.patients[patient_index].exercisesUI;

      state.saveDraftLoading = false;
      state.isSuccess = true;
      state.message = "Draft saved successfully.";
    },
    exercisePublished: (state, action) => {
      const patient_index = action.payload;

      state.list.patients[patient_index].is_published = 1;
      // state.list;
      state.publishLoading = false;
      state.isSuccess = true;
      state.message = "Exercises published successfully.";
    },
    patientExercisesReordered: (state, action) => {
      const { exercises, patient_index } = action.payload;

      console.log(exercises, patient_index);

      state.list.patients[
        patient_index
      ].exercisesUI.patient_exercises.exercises = exercises;
    },
    resetStatus: (state, action) => {
      state.isSuccess = false;
      state.saveDraftLoading = false;
      state.publishLoading = false;
      state.isError = false;
      state.isInfo = false;
      state.message = "";
    },
  },
});
