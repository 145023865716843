import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
// import AddExerciseModal from "../../../other/AddExerciseModal";
// import {
//   addExercisesToTemplate,
//   removeExercise,
//   reOrderExercises,
// } from "../../_redux/templateActions";
import { useDispatch } from "react-redux";
import AddExerciseModal from "./exercise/AddExerciseModal";
import ExerciseRow from "./exercise/ExerciseRow";

const grid = 0;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  //   background: isDragging && "lightgreen",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  //   background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: "100%",
});

const ExerciseContainer = ({
  patient,
  exercises,
  readOnly,
  handleAddExercise,
  handleExerciseFieldChange,
  reOrderExercises,
  handleRemoveExercise,
  handleAddVariation,
  handleRemoveVariation,
  handleIntChange,
  handlePropUpdate,
}) => {
  const dispatch = useDispatch();

  const [staticExercises, setStaticExercises] = useState([]);
  const [addExerciseModal, setAddExerciseModal] = useState(false);

  const dragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newOrder = reorder(
      exercises,
      result.source.index,
      result.destination.index
    );

    // setStaticExercises(newOrder);
    reOrderExercises(newOrder);
    // dispatch(reOrderExercises(newOrder));
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const removeExercise = (index) => {
    handleRemoveExercise(index);
    // console.log(index);
  };

  //   const handleRemoveExercise = (index) => {
  //     // dispatch(removeExercise(index));
  //     // const newList = [...staticExercises];
  //     // newList.splice(index, 1);
  //     // setStaticExercises(newList);
  //   };

  const addExercises = (selectedExercises) => {
    handleAddExercise(selectedExercises);
    // dispatch(addExercisesToTemplate(selectedExercises));
    setAddExerciseModal(false);
  };

  // useEffect(() => {
  //   setStaticExercises(defaultExercise);
  // }, []);

  return (
    <>
      <AddExerciseModal
        show={addExerciseModal}
        onHide={() => setAddExerciseModal(false)}
        addExercises={addExercises}
      />
      {/* {[...new Array(2)].map(() => (
        <ExerciseRow />
      ))} */}
      <DragDropContext onDragEnd={dragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {exercises?.map((exercise, index) => (
                <Draggable
                  key={exercise.uid}
                  draggableId={`${exercise.uid}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      className="mb-3"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <ExerciseRow
                        patient={patient}
                        index={index}
                        provided={provided}
                        exercise={exercise}
                        handleRemoveExercise={handleRemoveExercise}
                        handleExerciseFieldChange={handleExerciseFieldChange}
                        readOnly={readOnly}
                        handleAddVariation={handleAddVariation}
                        handleRemoveVariation={handleRemoveVariation}
                        handleIntChange={handleIntChange}
                        handlePropUpdate={handlePropUpdate}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Row className="mt-3">
        <Col className=" d-flex justify-content-end">
          <button
            className="btn btn-primary text-white rounded-pill"
            disabled={readOnly}
            onClick={() => setAddExerciseModal(true)}
          >
            <span className="px-3">+ Add exercise</span>
          </button>
        </Col>
      </Row>
    </>
  );
};

export default ExerciseContainer;
