import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Spinner, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import { updateScheduleId } from "../../_redux/coursesActions";

const ScheduleIdModal = (props) => {
  const dispatch = useDispatch();
  const { updateScheduleIdLoading, updateScheduleIdSuccess } = useSelector(
    (state) => state.courses
  );
  const [scheduleId, setScheduleId] = useState(null);

  useEffect(() => {
    if (props?.class) {
      setScheduleId(props?.class?.schedule_id);
    }
  }, [props.show]);

  useEffect(() => {
    updateScheduleIdSuccess && props.onHide();
  }, [updateScheduleIdSuccess]);

  const handleFieldChange = (e) => {
    let val = e.target.value;
    setScheduleId(val);
  };

  const handleSave = () => {
    dispatch(updateScheduleId(props.class.uid, scheduleId));
  };

  return (
    <Modal
      show={props.show}
      //   {...props}
      onHide={() => props.onHide()}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <h3 className="m-auto">Add class ID</h3>
        <a href="javascript:void(0)" onClick={() => props.onHide()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
        </a>
      </Modal.Header>
      <Modal.Body>
        <>
          <Grid container className="justify-content-center mb-3">
            <Grid item lg={9}>
              <Row className="mb-5 align-items-center">
                <Col lg={5}>
                  <strong>Class ID</strong>
                </Col>
                <Col lg={7}>
                  <Form.Control
                    type="text"
                    value={scheduleId ?? null}
                    onChange={handleFieldChange}
                  />
                </Col>
              </Row>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={11}>
              <Row className="d-flex justify-content-center">
                <Col lg={4}>
                  <button
                    className="btn btn-secondary rounded-pill w-100 text-primary"
                    onClick={() => props.onHide()}
                  >
                    Cancel
                  </button>
                </Col>
                <Col lg={4}>
                  <button
                    className="btn btn-primary rounded-pill w-100 position-relative"
                    onClick={handleSave}
                  >
                    {updateScheduleIdLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        style={{
                          position: "absolute",
                          left: "2rem",
                          top: "1rem",
                        }}
                        aria-hidden="true"
                      />
                    )}
                    Save
                  </button>
                </Col>
              </Row>
            </Grid>
          </Grid>
        </>
      </Modal.Body>
    </Modal>
  );
};

export default ScheduleIdModal;
