import React, { useState, useEffect } from "react";

// ui
import { Form, Row, Col, Modal, Button, Spinner } from "react-bootstrap";
import Fab from "@material-ui/core/Fab";
import Select from "react-select";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
// redyx
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {
  saveProp,
  updateProp,
} from "../_redux/exercises/exerciseLibraryActions";

export default function PropsModal(props) {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [changeIcon, setChangeIcon] = useState(false);
  const [icon, setSelectedIcon] = useState(null);
  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);

  const [prop, setProp] = useState({
    name: "",
    icon: null,
  });

  const { propIcons, savePropLoading, isSuccess } = useSelector(
    (state) => state.exerciseLibrary,
    shallowEqual
  );

  const { selected } = props;
  const { variation_index } = props;

  const title = selected !== null ? "Edit Prop" : "Add Prop";

  const customStyles = {
    option: (base) => ({
      ...base,
      width: "30%",
      border: "1px solid",
      margin: 5,
      float: "left",
      textAlign: "center",
    }),
    container: (base) => ({
      ...base,
    }),
  };

  const customIcons = (data) => {
    let { properties } = data;
    if (typeof properties == "string") {
      properties = JSON.parse(properties);
    }
    return (
      <svg
        width="20"
        height="20"
        viewBox={properties.viewBox}
        fill="black"
        xmlns={properties.xmlns}
      >
        <path d={properties.path} fill="black" />
      </svg>
    );
  };

  const handleHide = () => {
    props.onHide();
    setChangeIcon(false);
  };

  const handleFieldChange = (event) => {
    let target = event.target.name;
    let val = event.target.value;

    setProp((prop) => ({
      ...prop,
      [target]: val,
    }));
  };

  const handleSelectChange = (value, action) => {
    let target = action.name;
    setProp((prop) => ({
      ...prop,
      [target]: value,
    }));
  };

  const handleSave = () => {
    if (selected) {
      let params = {
        id: selected.id,
        name: prop.name,
        icon_id: prop.icon.id,
      };
      dispatch(updateProp(params, variation_index));
    } else {
      let params = {
        name: prop.name,
        icon_id: prop.icon.id,
      };
      dispatch(saveProp(params, variation_index));
    }
  };

  useEffect(() => {
    isSuccess && props.onHide();
  }, [isSuccess]);

  useEffect(() => {
    if (selected) {
      selected && setSelectedIcon(selected?.propsIcon?.properties);
      setProp((prop) => ({
        ...prop,
        name: selected.name,
        icon: selected.propsIcon,
      }));
      return;
    }
    setProp((prop) => ({
      ...prop,
      name: "",
      icon: null,
    }));
    setChangeIcon(false);
  }, [selected]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-90w"
    >
      <Modal.Header
        closeButton
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "none",
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group as={Row} controlId="">
            <Form.Label column sm="2">
              Name
            </Form.Label>
            <Col sm="10">
              {/* <Form.Control plaintext readOnly defaultValue="email@example.com" /> */}
              <Form.Control
                placeholder="Name"
                name="name"
                value={prop?.name}
                onChange={handleFieldChange}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="">
            <Form.Label column sm="2">
              Icon
            </Form.Label>
            {selected !== null && changeIcon === false ? (
              <Col sm={10} className="d-flex align-items-center">
                <span>
                  <svg
                    width="20"
                    height="20"
                    viewBox={icon?.viewBox}
                    fill="black"
                    xmlns={icon?.xmlns}
                  >
                    <path d={icon?.path} fill="black" />
                  </svg>
                  {/* <SVG
                    src={toAbsoluteUrl("/media/svg/icons/classes-calendar.svg")}
                    fill="currentColor"
                    width="25px"
                    height="25px"
                  /> */}
                </span>
                <span className="ml-3">
                  <a
                    className="color-primary"
                    onClick={() => {
                      setChangeIcon(true);
                    }}
                  >
                    Change
                  </a>
                </span>
              </Col>
            ) : (
              <Col sm={10}>
                <Select
                  options={propIcons}
                  // getOptionLabel={option=>`${option.name}`}
                  getOptionValue={(option) => option.id}
                  value={prop?.icon}
                  aria-labelledby="aria-label"
                  inputId="aria-example-input"
                  name="icon"
                  onChange={handleSelectChange}
                  onMenuOpen={onMenuOpen}
                  onMenuClose={onMenuClose}
                  placeholder="Icon List"
                  theme={(theme) => ({
                    ...theme,
                    // borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#ffe5e5",
                      primary: "#ff8a93",
                    },
                  })}
                  styles={customStyles}
                  // components={{customIcons}}
                  formatOptionLabel={customIcons}
                />
              </Col>
            )}
          </Form.Group>
          <Form.Group
            as={Row}
            controlId=""
            className="d-flex justify-content-center"
          >
            <Col sm="5">
              <Button
                variant="secondary"
                size="medium"
                // color="primary"
                aria-label="Add"
                // className={classes.margin}
                className="btn-secondary text-primary w-100 rounded-pill"
                onClick={handleHide}
              >
                Cancel
              </Button>
            </Col>
            <Col sm="5">
              <Button
                disabled={savePropLoading}
                variant="primary"
                onClick={() => handleSave()}
                className="rounded-pill w-100"
              >
                {savePropLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    style={{
                      position: "absolute",
                      left: "2rem",
                      top: "1rem",
                    }}
                    aria-hidden="true"
                  />
                )}
                Save
              </Button>
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
