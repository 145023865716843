import React, { useState, useEffect } from "react";
import { Col, Row, Spinner, Button, Modal } from "react-bootstrap";
import { Grid, makeStyles } from "@material-ui/core";
import ProgressBar from "../components/course-export/ProgressBar";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { LinearProgress, Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import HolidayList from "../components/course-export/HolidayList";
import MissingScheduleErrorModal from "../components/course-export/MissingScheduleErrorModal";
import { useHistory } from "react-router-dom";
import RequestConflictModal from "../components/RequestConflictModal";

import {
  fetchClasses,
  fetchCurrentBlock,
  validateExport,
  getPatientsWithDraft,
  exportToMindBody,
  statusReset,
  fetchPatients,
  resetVerificationProgress,
  finishClassLoading,
  saveDraft,
  verify,
  verifySkipHolidays,
  fetchPatientsAfterReset,
  resetDraft,
} from "../_redux/coursesActions";
import moment from "moment";
import ExportDialog from "../components/dialog-bars/ExportDialog";
import CourseClasses from "../components/CourseClasses";
import Filter from "../../Other/Filter";
import VerificationModal from "../components/VerificationModal";
import ExportConfirmModal from "../components/ExportConfirmModal";
import HolidayErrorModal from "../components/course-export/HolidayErrorModal";
import AddClassModal from "../components/course-export/AddClassModal";

const useStyles = makeStyles({
  root: {
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#ff8a93",
    },
  },
});

function LinearProgressWithLabel(props) {
  const classes = useStyles();
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          className="bg-secondary"
          classes={{
            root: classes.root,
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          color="text.primary"
          className="text-center"
        >
          Loading classes {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const CourseExportPage = () => {
  const dispatch = useDispatch();
  const {
    classes,
    // filters,
    holidays,
    previousCurrentBlock,
    verifyLoadingFinish,
    nextBlock,
    listLoading,
    classesLoading,
    classesLoadingProgress,
    draftLoading,
    verifyLoading,
    isVerified,
    exportLoading,
    exportSuccess,
    actionsLoading,
    resetDraftLoading,
    resetDraftSuccess,
  } = useSelector((state) => state.courses);

  const filterOptions = useSelector((state) => state.courses.filters);
  // const [classPatients, setClassPatients] = useState([]);
  // const [saveReady, setSaveReady] = useState(false);
  const history = useHistory();
  const [currentHolidays, setCurrentHolidays] = useState([]);
  const [exportConfirm, setExportConfirm] = useState(false);
  const [holidayErrorModal, setHolidayErrorModal] = useState(false);
  const [resetConfirm, setResetConfirm] = useState(false);
  const [verificationModal, setVerificationModal] = useState(false);
  const [addClassModal, setAddClassModal] = useState(false);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [missingSchedModal, setMissingSchedModal] = useState(false);
  const [filters, setFilters] = useState({
    physio: null,
    location: null,
    key: "",
  });
  const [progress, setProgress] = useState(0);

  const { physio, location } = filterOptions;

  // const { next_block } = currentBlockClassList;
  // const { patients, has_drafts } = classPatientList;

  useEffect(() => {
    dispatch(fetchClasses());
  }, [dispatch]);

  useEffect(() => {
    if (resetDraftSuccess) {
      setResetConfirm(false);
      dispatch(fetchPatientsAfterReset(classes));
    }
  }, [resetDraftSuccess]);

  useEffect(() => {
    setFilteredClasses(classes);
    if ((holidays, previousCurrentBlock)) {
      let newHolidays = [];
      // holidays &&
      //   holidays.map((holiday) => {
      //     let eventDate = moment(holiday.event_date).format(
      //       "YYYY-MM-DD HH:mm:ss"
      //     );
      //     let dateFrom = moment(previousCurrentBlock.date_from).format(
      //       "YYYY-MM-DD HH:mm:ss"
      //     );
      //     let dateTo = moment(previousCurrentBlock.date_to).format(
      //       "YYYY-MM-DD HH:mm:ss"
      //     );
      //     // console.log(eventDate, dateFrom, dateTo);
      //     if (eventDate >= dateFrom && eventDate <= dateTo) {
      //       // console.log("inside");
      //       newHolidays = [...newHolidays, holiday];
      //     }
      //   });

      // setCurrentHolidays(newHolidays);
    }
  }, [classes]);

  useEffect(() => {
    // console.log(filters);
    handleSearch();
  }, [filters]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 10 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    // setProgress(classesLoadingProgress);
    // console.log("loading updated", classesLoadingProgress);
    if (Math.round(classesLoadingProgress) === 100) {
      setTimeout(() => {
        dispatch(finishClassLoading());
      }, 500);
    }
  }, [classesLoadingProgress]);

  useEffect(() => {
    exportSuccess &&
      setTimeout(() => {
        history.push("/course-builder");
      }, 1000);
  }, [exportSuccess]);
  // useEffect(() => {
  //   console.log(progress);
  // }, [progress]);
  // useEffect(() => {
  //   if (typeof classPatientList.classId !== "undefined") {
  //     setSaveReady(true);
  //   }
  // }, [classPatientList]);

  const handleSave = () => {
    // if (holidays.length > 0) {
    //   setHolidayErrorModal(true);
    //   return;
    // }
    let draft = [];

    classes.map((cls) => {
      cls.patients.map((patient) => {
        let newObj = {
          schedule_id: cls.schedule_id,
          start_date: cls.start_date,
          start_time: cls.start_time,
          end_time: cls.end_time,
          class_id: cls.class_id,
          patient_id: patient.Id,
          patient_last_name: patient.LastName,
          patient_first_name: patient.FirstName,
          mark_as_id: patient.mark_as_id,
          staff_id: cls.staff_id,
          staff_email: cls.staff_email,
          physio_name: cls.physio_name,
          max_capacity: cls.max_capacity,
          studio: cls.studio,
        };
        draft = [...draft, newObj];
      });
    });
    dispatch(saveDraft(draft, false));
  };

  const handleVerify = () => {
    let missing_schedule_id_count = 0;
    classes.map((cls) => {
      if (cls.schedule_id == null || cls.schedule_id == "") {
        missing_schedule_id_count++;
      }
    });

    if (missing_schedule_id_count > 0) {
      setMissingSchedModal(true);
      return;
    }
    dispatch(verify(classes, nextBlock.id));
  };

  const handleSaveDraftWithHolidays = () => {
    setHolidayErrorModal(false);
    let draft = [];

    classes.map((cls) => {
      cls.patients.map((patient) => {
        let newObj = {
          schedule_id: cls.schedule_id,
          start_date: cls.start_date,
          start_time: cls.start_time,
          end_time: cls.end_time,
          class_id: cls.class_id,
          patient_id: patient.Id,
          patient_last_name: patient.LastName,
          patient_first_name: patient.FirstName,
          mark_as_id: patient.mark_as_id,
          staff_id: cls.staff_id,
          staff_email: cls.staff_email,
          physio_name: cls.physio_name,
          max_capacity: cls.max_capacity,
          studio: cls.studio,
        };
        draft = [...draft, newObj];
      });
    });
    dispatch(saveDraft(draft, false));
    // dispatch(verify(classes, nextBlock.id));
  };

  const handleSaveDraftSkipHolidays = () => {
    setHolidayErrorModal(false);
    let draft = [];

    classes.map((cls) => {
      cls.patients.map((patient) => {
        let newObj = {
          schedule_id: cls.schedule_id,
          start_date: cls.start_date,
          start_time: cls.start_time,
          end_time: cls.end_time,
          class_id: cls.class_id,
          patient_id: patient.Id,
          patient_last_name: patient.LastName,
          patient_first_name: patient.FirstName,
          mark_as_id: patient.mark_as_id,
          staff_id: cls.staff_id,
          staff_email: cls.staff_email,
          physio_name: cls.physio_name,
          max_capacity: cls.max_capacity,
          studio: cls.studio,
        };
        draft = [...draft, newObj];
      });
    });
    dispatch(saveDraft(draft, true));
    // dispatch(verifySkipHolidays(classes, nextBlock.id));
  };

  // array string options/value
  const optionsArrayString = (options) => {
    let opts = [];
    options &&
      options.map((option) => {
        option = {
          label: option,
          value: option,
        };
        opts = [...opts, option];
      });

    return opts;
  };

  const valueArrayString = (option) => {
    // console.log(option);
    return option;
    // return {
    //   label: option,
    //   value: option,
    // };
  };
  // end array string
  const handleFilterClear = () => {
    console.log("filters cleared");
    setFilters((prev) => ({
      ...prev,
      physio: null,
      location: null,
      key: "",
    }));
    // setFilteredClasses(classes);
  };

  const handleFilters = (val, target) => {
    setFilters((prev) => ({
      ...prev,
      [target.name]: val,
    }));
  };

  const handleSearch = () => {
    // first filters, filters -> physio && location
    let firstFilteredList = [];
    // let tempClasses = [];
    classes.map((cls) => {
      let new_class = {
        class_id: cls.class_id,
        schedule_id: cls.schedule_id,
        staff_id: cls.staff_id,
        staff_email: cls.staff_email,
        physio_name: cls.physio_name,
        studio: cls.studio,
        class_type: cls.class_type,
        time: cls.time,
        max_capacity: cls.max_capacity,
        total_booked: cls.total_booked,
        total_signed_id: cls.total_signed_id,
        total_booked_waitlist: cls.total_booked_waitlist,
        day: cls.day,
        start_date: cls.start_date,
        end_date: cls.end_date,
        start_time: cls.start_time,
        end_time: cls.end_time,
        patients: cls.patients,
      };
      // has physio filter
      if (filters.physio && filters.location == null) {
        if (cls.physio_name == filters.physio.value) {
          let filtr = firstFilteredList.find(
            (listItem) => listItem.class_id === cls.class_id
          );
          if (!filtr) {
            firstFilteredList = [...firstFilteredList, new_class];
          }
        }
      }
      // has location filter
      if (filters.location && filters.physio == null) {
        if (cls.studio == filters.location.value) {
          let filtr = firstFilteredList.find(
            (listItem) => listItem.class_id === cls.class_id
          );
          if (!filtr) {
            firstFilteredList = [...firstFilteredList, new_class];
          }
        }
      }
      // has both
      if (filters.location && filters.physio) {
        if (
          cls.studio == filters.location.value &&
          cls.physio_name == filters.physio.value
        ) {
          let filtr = firstFilteredList.find(
            (listItem) => listItem.class_id === cls.class_id
          );
          if (!filtr) {
            firstFilteredList = [...firstFilteredList, new_class];
          }
        }
      }
      // empty filters for both
      if (filters.location == null && filters.physio == null) {
        // setFilteredClasses()
        firstFilteredList = [...firstFilteredList, new_class];
      }
    });
    // console.log(firstFilteredList);
    // console.log(firstFilteredList);
    let filteredList = [];

    firstFilteredList.map((cls) => {
      if (filters.key) {
        let new_class = {
          class_id: cls.class_id,
          schedule_id: cls.schedule_id,
          staff_id: cls.staff_id,
          staff_email: cls.staff_email,
          physio_name: cls.physio_name,
          studio: cls.studio,
          class_type: cls.class_type,
          time: cls.time,
          max_capacity: cls.max_capacity,
          total_booked: cls.total_booked,
          total_signed_id: cls.total_signed_id,
          total_booked_waitlist: cls.total_booked_waitlist,
          day: cls.day,
          start_date: cls.start_date,
          end_date: cls.end_date,
          start_time: cls.start_time,
          end_time: cls.end_time,
          patients: [],
        };
        cls.patients.map((patient) => {
          let fullname = `${patient.FirstName} ${patient.LastName}`;
          if (fullname.toLowerCase().includes(filters.key.toLowerCase())) {
            let filtr = filteredList.find(
              (listItem) => listItem.class_id === cls.class_id
            );
            if (filtr) {
              filtr.patients = [...filtr.patients, patient];
            } else {
              new_class.patients = [...new_class.patients, patient];
              filteredList = [...filteredList, new_class];
            }
          }
        });
      } else {
        filteredList = [...filteredList, cls];
      }
    });

    setFilteredClasses(filteredList);
  };

  const handleCloseExportConfirm = () => {
    setExportConfirm(false);
    setTimeout(() => {
      dispatch(statusReset());
    }, [500]);
  };

  const handleReset = () => {
    setResetConfirm(true);
  };

  const handleResetConfirm = () => {
    dispatch(resetDraft());
  };

  useEffect(() => {
    if (verifyLoadingFinish == true) {
      dispatch(resetVerificationProgress());
    }
  }, [verifyLoadingFinish]);

  return (
    <div className="position-relative h-100">
      <ExportDialog />
      <RequestConflictModal />
      <MissingScheduleErrorModal
        show={missingSchedModal}
        onHide={() => setMissingSchedModal(false)}
      />
      <HolidayErrorModal
        show={holidayErrorModal}
        onHide={() => setHolidayErrorModal(false)}
        holidays={holidays}
        handleSaveDraftWithHolidays={handleSaveDraftWithHolidays}
        handleSaveDraftSkipHolidays={handleSaveDraftSkipHolidays}
      />
      <VerificationModal handleExport={() => setExportConfirm(true)} />
      <AddClassModal
        show={addClassModal}
        onHide={() => setAddClassModal(false)}
      />
      <Modal
        show={resetConfirm}
        onHide={() => setResetConfirm(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-50w"
        centered
      >
        <Modal.Body>
          <Row className="d-flex justify-content-center">
            <Col lg={9}>
              <p className="text-center">
                Are you sure you'd like to reset all classes and patients? Doing
                this will remove all changes.
              </p>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col lg={11}>
              <Row>
                <Col lg={6}>
                  <button
                    disabled={resetDraftLoading}
                    className="btn btn-secondary btn-md rounded-pill w-100 text-primary"
                    onClick={() => {
                      setResetConfirm(false);
                    }}
                  >
                    Cancel
                  </button>
                </Col>
                <Col lg={6}>
                  <button
                    disabled={resetDraftLoading}
                    className="btn btn-primary btn-md rounded-pill w-100 position-relative"
                    onClick={handleResetConfirm}
                  >
                    {resetDraftLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        style={{
                          position: "absolute",
                          left: ".2rem",
                          top: "1rem",
                        }}
                        aria-hidden="true"
                      />
                    )}
                    Reset classes & patients
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* Export confirm modal */}
      <ExportConfirmModal
        show={exportConfirm}
        handleCloseExportConfirm={handleCloseExportConfirm}
      />
      {listLoading ? (
        <ProgressBar />
      ) : (
        <>
          <Grid container spacing={2} className="mb-5">
            <Grid item lg={4}>
              <Row>
                <Col>
                  <Typography variant="h6">
                    Date range of next block:{" "}
                  </Typography>
                </Col>
                <Col>
                  <Row>
                    <Col lg={10} className="border p-4 text-center ">
                      <span>
                        {nextBlock?.date_from
                          ? moment(nextBlock.date_from).format("Do MMMM ")
                          : ""}{" "}
                        -{" "}
                        {nextBlock?.date_to
                          ? moment(nextBlock.date_to).format("Do MMMM ")
                          : ""}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Grid>
            {/* <Grid item lg={2} className="text-center">
            </Grid> */}
            {holidays.length > 0 && <HolidayList currentHolidays={holidays} />}
            <Grid item lg={4} className="ml-auto">
              <Filter
                handleSearch={(e) => handleFilters(e, { name: "key" })}
                disabled={classesLoading}
              />
              <Row>
                <Col lg={10}>
                  <Row>
                    <Col lg={6}>
                      <Select
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        isDisabled={classesLoading}
                        name="physio"
                        options={optionsArrayString(physio)}
                        value={filters.physio}
                        onChange={handleFilters}
                        placeholder="Physio"
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: "#ff8a93",
                            primary: "#ffe5e5",
                          },
                        })}
                      />
                    </Col>
                    <Col lg={6}>
                      <Select
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        isDisabled={classesLoading}
                        name="location"
                        options={optionsArrayString(location)}
                        value={filters?.location}
                        onChange={handleFilters}
                        placeholder="Location"
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: "#ff8a93",
                            primary: "#ffe5e5",
                          },
                        })}
                      />
                    </Col>
                  </Row>
                </Col>
                <u className="d-flex align-items-end">
                  <a
                    href="javscript:void(0)"
                    style={{ color: "black" }}
                    onClick={handleFilterClear}
                  >
                    Clear filters
                  </a>
                </u>
              </Row>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            className="justify-content-end"
            alignItems="center"
            spacing={5}
          >
            <Grid item className="d-flex align-items-center">
              <span
                className="mr-2"
                style={{
                  backgroundColor: "#93a4ff",
                  height: "15px",
                  width: "15px",
                  borderRadius: "50%",
                  display: "inline-block",
                }}
              ></span>
              <span>Confirmed, payment pending</span>
            </Grid>
            <Grid item className="d-flex align-items-center">
              <span
                className="mr-2"
                style={{
                  backgroundColor: "#05b602",
                  height: "15px",
                  width: "15px",
                  borderRadius: "50%",
                  display: "inline-block",
                }}
              ></span>
              Paid
            </Grid>
            <Grid item className="d-flex align-items-center">
              <span
                className="mr-2"
                style={{
                  backgroundColor: "#ffba53",
                  height: "15px",
                  width: "15px",
                  borderRadius: "50%",
                  display: "inline-block",
                }}
              ></span>
              Payment plan
            </Grid>
            <Grid item className="d-flex align-items-center">
              <span
                className="mr-2"
                style={{
                  backgroundColor: "#f57ada",
                  height: "15px",
                  width: "15px",
                  borderRadius: "50%",
                  display: "inline-block",
                }}
              ></span>
              TAC/Workcover/NDIS
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-5">
            <Grid item lg={12}>
              <TransitionGroup>
                {Math.round(classesLoadingProgress) === 100 &&
                classesLoading == false ? (
                  <CSSTransition
                    key="courseclasses"
                    classNames="example"
                    timeout={{ enter: 500, exit: 300 }}
                  >
                    <CourseClasses classes={filteredClasses} />
                  </CSSTransition>
                ) : (
                  <CSSTransition
                    key="loading"
                    classNames="example"
                    timeout={{ enter: 500, exit: 300 }}
                  >
                    <>
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="100%"
                        height="550px"
                      />
                      <Box sx={{ width: "100%" }}>
                        <LinearProgressWithLabel
                          value={classesLoadingProgress}
                        />
                      </Box>
                    </>
                  </CSSTransition>
                )}
              </TransitionGroup>
            </Grid>
          </Grid>
          <Grid
            container
            className="position-absolute bottom-0 start-0 justify-content-between"
          >
            <Grid item lg={4}>
              <Grid container spacing={2}>
                <Grid item lg={3}>
                  <button className="btn btn-secondary rounded-pill w-100 text-primary">
                    Exit
                  </button>
                </Grid>
                <Grid item lg={3}>
                  <button
                    onClick={() => setAddClassModal(true)}
                    className="btn btn-primary rounded-pill w-100 text-white"
                    disabled={classesLoading || actionsLoading ? true : false}
                  >
                    Add class
                  </button>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="d-flex align-items-end">
              <u>
                <a
                  style={{ color: "black" }}
                  href="javascript:void(0)"
                  onClick={!classesLoading && handleReset}
                >
                  Reset all classes
                </a>
              </u>
            </Grid>
            <Grid item lg={5}>
              <Grid container spacing={2}>
                <Grid item lg={3} className="ml-auto">
                  <Button
                    variant="primary"
                    disabled={classesLoading || actionsLoading ? true : false}
                    onClick={() => handleSave()}
                    className="rounded-pill w-100 position-relative"
                  >
                    {draftLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        style={{
                          position: "absolute",
                          left: "2rem",
                          top: "1rem",
                        }}
                        aria-hidden="true"
                      />
                    )}
                    Save
                  </Button>
                </Grid>
                <Grid item lg={3} className="ml-auto">
                  <Button
                    variant="primary"
                    disabled={classesLoading || actionsLoading ? true : false}
                    // disabled={!classPatientsFetched}
                    // disabled
                    onClick={() => handleVerify()}
                    className="rounded-pill w-100 position-relative"
                  >
                    {verifyLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        style={{
                          position: "absolute",
                          left: "2rem",
                          top: "1rem",
                        }}
                        aria-hidden="true"
                      />
                    )}
                    Verify
                  </Button>
                </Grid>
                <Grid item lg={6}>
                  <button
                    className="btn btn-primary rounded-pill w-100 position-relative"
                    onClick={() => {
                      setExportConfirm(true);
                    }}
                    disabled={!isVerified || actionsLoading}
                  >
                    {exportLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        style={{
                          position: "absolute",
                          left: "2rem",
                          top: "1rem",
                        }}
                        aria-hidden="true"
                      />
                    )}
                    Export to MindBody
                  </button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default CourseExportPage;
