import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Row, Col } from "react-bootstrap";
import "../styles/blocks.css";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import { amber, green } from "@material-ui/core/colors";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";

const variantIcon = {
  info: InfoIcon,
};

const useStyles1 = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

const useStyles2 = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

const BlocksDatepicker = ({
  block,
  setActiveDatepicker,
  handleBlockUpdate,
  handleNextBlockUpdate,
  year,
}) => {
  const classes = useStyles2();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectionComplete, toggleSelectionComplete] = useState(false);
  const [dateRange, setDateRange] = useState("");
  const [snackBar, setSnackBar] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleDateChange = (date) => {
    if (
      (!selectionComplete && !startDate) ||
      (!selectionComplete && startDate && endDate)
    ) {
      //on first date selection, set the start date

      setStartDate(date);
      setEndDate(null);
      return;
    }

    if (!selectionComplete && startDate && !endDate) {
      //on second date selection, set the end date

      //   console.log(moment(startDate)._d, moment(date)._d);
      if (
        moment(startDate).format("DD/MM/YYYY") ===
        moment(date).format("DD/MM/YYYY")
      ) {
        setStartDate(null);
      } else if (startDate > date) {
        // dispatch(validateDates("End date must be greater than start date."));
        setSnackBar(true);
      } else {
        setEndDate(date);
        toggleSelectionComplete(true);
      }

      //do stuff with date range

      return;
    }

    if (selectionComplete && startDate && endDate) {
      //on third date selection, begin selection of a new date range
      //reset the start date and clear the end date.

      setStartDate(date);
      setEndDate(undefined);
      toggleSelectionComplete(false);
      return;
    }

    if (selectionComplete && startDate) {
      setStartDate(date);
      setEndDate(undefined);
      toggleSelectionComplete(false);
      return;
    }
  };

  const handleSelect = (date) => {
    //onChange is not fired if selecting same date - workaround to fire handleDateChange
    if (
      !selectionComplete &&
      startDate &&
      !endDate &&
      sameDay(date, startDate)
    ) {
      handleDateChange(date);
    }
  };

  const sameDay = (d1, d2) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  const cancelDatepick = () => {
    setActiveDatepicker(null);
  };

  const handleClose = () => {
    setSnackBar(false);
  };

  function updateBlocks() {
    let start_date = moment(startDate).format("DD/MM/YYYY");
    let end_date = moment(endDate).format("DD/MM/YYYY");
    setDateRange(`${start_date} - ${end_date}`);
    // setDatepicker(false);
    if (handleBlockUpdate) {
      //fif block has id
      if (block?.id) {
        handleBlockUpdate({
          block: {
            id: block.id ? block.id : null,
            block_no: block.block_no,
            date_from: moment(startDate).format("YYYY-MM-DD"),
            date_to: moment(endDate).format("YYYY-MM-DD"),
            year: block?.year,
          },
        });
      } else {
        handleBlockUpdate({
          block: {
            block_no: block?.block_no,
            date_from: moment(startDate).format("YYYY-MM-DD"),
            date_to: moment(endDate).format("YYYY-MM-DD"),
            year: block.year,
          },
        });
      }
    } else {
      if (block?.id) {
        handleNextBlockUpdate({
          block: {
            id: block.id ? block.id : null,
            block_no: block?.block_no,
            date_from: moment(startDate).format("YYYY-MM-DD"),
            date_to: moment(endDate).format("YYYY-MM-DD"),
            year: block?.year,
          },
        });
      } else {
        handleNextBlockUpdate({
          block: {
            block_no: block.block_no,
            date_from: moment(startDate).format("YYYY-MM-DD"),
            date_to: moment(endDate).format("YYYY-MM-DD"),
            year: block.year,
          },
        });
      }
    }
  }

  useEffect(() => {
    if (block?.date_from !== null && block?.date_to !== null) {
      let start_date = moment(block?.date_from);
      let end_date = moment(block?.date_to);

      setStartDate(start_date._d);
      setEndDate(end_date._d);
      //   toggleSelectionComplete(true);
    } else {
      setDateRange("Dates not set.");

      //   if (year == "next") {
      // setStartDate(
      //   moment()
      //     .add(1, "year")
      //     .startOf("year")._d
      // );
      // toggleSelectionComplete(true);
      //   }
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    endDate && updateBlocks();
  }, [endDate]);

  useEffect(() => {
    selectionComplete && setActiveDatepicker(null);
  }, [selectionComplete]);

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackBar}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          variant="info"
          className={classes.margin}
          message="End date must be greater than start date."
        />
      </Snackbar>
      <Row>
        <Col>
          <Row className="d-flex justify-content-center">
            <Col className="d-flex">
              Block {block?.block_no}
              <span className="m-auto">{dateRange}</span>
            </Col>
            <Col lg={1}>
              <a href="javascript:void(0)" onClick={() => cancelDatepick()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  fill="black"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </a>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center" id="datepicker">
            <Col>
              {loading ? (
                <>
                  <Skeleton
                    height={275}
                    animation="wave"
                    variant="rectangular"
                  />
                </>
              ) : (
                <DatePicker
                  classname="w-100"
                  selected={startDate}
                  onChange={handleDateChange}
                  onSelect={handleSelect}
                  selectsEnd={!startDate}
                  startDate={startDate}
                  endDate={endDate}
                  minDate={
                    year == "next" &&
                    moment()
                      .add(1, "year")
                      .startOf("year")._d
                  }
                  inline
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default BlocksDatepicker;
