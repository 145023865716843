import { templateSlice } from "./templateSlice";
import * as requestFromServer from "./templateCrud";

const { actions } = templateSlice;

export const fetchTags = () => (dispatch) => {
  requestFromServer
    .getTags()
    .then((res) => {
      dispatch(templateSlice.actions.tagsFetched(res.data));
    })
    .catch((er) => {
      console.log("tags error");
      console.log(er.response.data.message);
    });
};

export const mountDefaultTemplate = () => (dispatch) => {
  dispatch(templateSlice.actions.defaultTemplateMounted());
};

export const getTemplates = () => (dispatch) => {
  dispatch(actions.startCall("listLoading"));

  requestFromServer
    .getTemplates()
    .then((res) => {
      dispatch(actions.templatesFetched(res.data));
    })
    .catch((err) => {
      // console.log(err.response.data);
      dispatch(actions.catchError(err.response.data));
    });
};

export const getTemplate = (id) => (dispatch) => {
  dispatch(actions.startCall("templateLoading"));

  requestFromServer
    .getTemplate(id)
    .then((res) => {
      dispatch(actions.templateFetched(res.data));
    })
    .catch((err) => {
      // console.log(err.response.data);
      dispatch(actions.catchError(err.response.data));
    });
};

export const addExercisesToTemplate = (selectedExercises) => (dispatch) => {
  dispatch(templateSlice.actions.templateExercisesAdded(selectedExercises));
};

export const removeExercise = (index) => (dispatch) => {
  dispatch(templateSlice.actions.exerciseRemoved(index));
};

export const reOrderExercises = (exercises) => (dispatch) => {
  dispatch(templateSlice.actions.exercisesReordered(exercises));
};

export const addVariation = (index, variation) => (dispatch) => {
  let newVars = { ...variation };
  newVars.set = 0;
  newVars.rep = 0;

  const paylaod = {
    index: index,
    variation: newVars,
  };
  dispatch(templateSlice.actions.variationAdded(paylaod));
};

export const removeVariation = (exercise_index, index) => (dispatch) => {
  const payload = {
    exercise_index: exercise_index,
    index: index,
  };
  dispatch(templateSlice.actions.variationRemoved(payload));
};

export const handleTemplateFieldChange = (payload) => (dispatch) => {
  dispatch(templateSlice.actions.templateFieldUpdated(payload));
};

export const handleVariationIntChange = (
  exercise_index,
  index,
  target,
  val
) => (dispatch) => {
  const payload = {
    exercise_index: exercise_index,
    index: index,
    target: target,
    val: parseInt(val),
  };

  dispatch(actions.variantIntChanged(payload));
};

export const handleSaveDraft = (payload) => (dispatch) => {
  dispatch(templateSlice.actions.startCall("saveDraftLoading"));

  requestFromServer
    .saveDraft(payload)
    .then((res) => {
      dispatch(actions.draftSaved(res.data));
    })
    .catch((err) => {
      // console.log(err.response.data);
      dispatch(actions.catchError(err.response.data));
    });
};

export const statusReset = () => (dispatch) => {
  dispatch(actions.resetStatus());
};
