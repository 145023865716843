import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { useSelector } from "react-redux";
import { filterLinksByAccess } from "./modules/Other/utils/navigationLinks";
import MyProfile from "./modules/User/pages/MyProfile";
import { ErrorPage1 } from "./modules/ErrorsExamples/ErrorPage1";
import TextFieldsExamplesPage from "./modules/GoogleMaterialExamples/inputs/TextFieldsExamplesPage";
import MenusExamplesPage from "./modules/GoogleMaterialExamples/navigation/MenusExamplesPage";
import LinksExamplesPage from "./modules/GoogleMaterialExamples/navigation/LinksExamplesPage";
import FormsExamplesPage from "./modules/ReactBootstrapExamples/FormsExamplesPage";
import TooltipsExamplesPage from "./modules/ReactBootstrapExamples/TooltipsExamplesPage";
import TablesExamplesPage from "./modules/GoogleMaterialExamples/data-displays/TablesExamplesPage";
import ImagesExamplesPage from "./modules/ReactBootstrapExamples/ImagesExamplesPage";
// FormsExamplesPage

//examples
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  const { access } = useSelector((state) => state.auth.user);
  const filteredLinks = filterLinksByAccess(access);

  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to={filteredLinks[0].link} />
        }
        {filteredLinks.map((link) => {
          let routes = [];

          if (link.dropdown == false) {
            routes = [
              ...routes,
              <ContentRoute
                exact={true}
                path={link.link}
                component={link.component}
              />,
            ];
          }

          if (link.subLinks) {
            link.subLinks.map((sublink) => {
              routes = [
                ...routes,
                <ContentRoute
                  exact={true}
                  path={sublink.link}
                  component={sublink.component}
                />,
              ];
            });
          }

          if (link.subMenus) {
            link.subMenus.map((subMenu) => {
              routes = [
                ...routes,
                <ContentRoute
                  exact={true}
                  path={subMenu.link}
                  component={subMenu.component}
                />,
              ];

              if (subMenu.subLinks) {
                subMenu.subLinks.map((subLink) => {
                  routes = [
                    ...routes,
                    <ContentRoute
                      exact={true}
                      path={subLink.link}
                      component={subLink.component}
                    />,
                  ];
                });
              }
            });
          }
          return routes;
        })}
        <ContentRoute exact={true} path="/my-profile" component={MyProfile} />
        <ContentRoute
          exact={true}
          path="/test"
          component={TablesExamplesPage}
        />
        {/* <Redirect to="404" /> */}
        <Route path="*" exact={true} component={ErrorPage1} />
      </Switch>
    </Suspense>
  );
}
