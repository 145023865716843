import { createSlice } from "@reduxjs/toolkit";

const initialUser = {
  first_name: "",
  last_name: "",
  access: "Physio",
  email: "",
  password: "",
  photo: "",
  photo_file: "",
};

const initialUsersState = {
  list: [],
  user: null,
  listLoading: false,
  actionsLoading: false,
  customerForEdit: undefined,
  lastError: null,
  createUserSuccess: false,
  isSuccess: false,
  isError: false,
  isInfo: false,
  message: "",
  errors: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const userSlice = createSlice({
  name: "users",
  initialState: initialUsersState,
  reducers: {
    catchError: (state, action) => {
      const { message, errors } = action.payload;
      state.isError = true;
      state.message = message;
      state.errors = errors;
      state.actionsLoading = false;
    },
    startCall: (state, action) => {
      state.errors = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    editUserFieldsPopulated: (state, action) => {
      const { user } = action.payload;
      state.user = user;
      state.listLoading = false;
    },
    newUserFieldsPopulated: (state, action) => {
      state.user = initialUser;
    },
    // existingUserFieldsPopulated:(state,action)=>{
    //   state.user =
    // },
    usersFetched: (state, action) => {
      // const { totalCount, entities } = action.payload;
      const { users } = action.payload;
      state.list = users;
      state.listLoading = false;
      state.errors = null;
    },
    userCreated: (state, action) => {
      state.actionsLoading = false;
      state.isSuccess = true;
      state.message = "User successfully added";
    },
    userUpdated: (state, action) => {
      state.actionsLoading = false;
      state.isSuccess = true;
      state.message = "User successfully updated";
    },
    userFieldChanged: (state, action) => {
      const { val, target, b64 = null } = action.payload;

      if (state.errors) {
        delete state.errors[target];
      }

      if (target == "photo_file" || target == "video") {
        const reader = new FileReader();
        reader.readAsDataURL(val);
        if (target == "photo_file") {
          state.user = {
            ...state.user,
            photo: b64,
            photo_file: null,
          };
        }
        // if (target == "video") {
        //   state.exercise.variations[index] = {
        //     ...state.exercise.variations[index],
        //     video_file: b64,
        //     video_link: null,
        //   };
        // }
      }

      state.user[target] = val;
    },
    userAdded: (state, action) => {
      state.actionsLoading = false;
      state.createUserSuccess = true;
      state.isSuccess = true;
      state.message = "User successfully added.";
    },
    statusResetted: (state, action) => {
      state.message = "";
      state.isSuccess = false;
      state.createUserSuccess = false;
      state.isError = false;
      state.isInfo = false;
    },
  },
});
