import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import { Grid } from "@material-ui/core";
import HolidayListModal from "./HolidayListModal";

const HolidayList = ({ currentHolidays }) => {
  const [groupedHolidays, seGroupedHolidays] = useState([]);
  const [listModal, setListModal] = useState(false);

  useEffect(() => {
    groupHolidays(currentHolidays);
  }, []);

  const groupHolidays = (holidays) => {
    // console.log(holidays);
    let newHolidays = [[]];
    // console.log(newHolidays);
    holidays.map((holiday, index) => {
      if (newHolidays[newHolidays.length - 1].length !== 3) {
        newHolidays[newHolidays.length - 1] = [
          ...newHolidays[newHolidays.length - 1],
          holiday,
        ];
      } else {
        newHolidays = [...newHolidays, [holiday]];
        // newHolidays[index] = [...newHolidays[index], holiday];
      }
    });

    seGroupedHolidays(newHolidays);
  };

  const handleListModal = () => {
    setListModal(true);
  };

  return (
    <>
      <HolidayListModal
        show={listModal}
        onHide={() => setListModal(false)}
        holidays={currentHolidays}
      />
      {groupedHolidays.map(
        (holidayGroup, index) =>
          index <= 1 && (
            <Grid item lg={2}>
              <Row className="justify-content-center">
                <Col
                  lg={10}
                  className="p-5"
                  style={{
                    backgroundColor: "#ffba53",
                  }}
                >
                  {index == 0 && (
                    <Row>
                      <Col lg={12}>Public holiday(s) identified:</Col>
                    </Row>
                  )}
                  <Row className="d-flex align-items-center">
                    <nav>
                      <ul>
                        {holidayGroup?.map((holiday) => (
                          <li
                            style={{ listStyleType: "none" }}
                            className="my-2"
                          >
                            {moment(holiday.holiday_date).format("MMM Do")} -{" "}
                            {holiday.name}
                          </li>
                        ))}
                      </ul>
                      {index == 1 && currentHolidays.length > 6 && (
                        <Row className="justify-content-center">
                          <a
                            href="#"
                            style={{ color: "black" }}
                            onClick={handleListModal}
                          >
                            <u>View all</u>
                          </a>
                        </Row>
                      )}
                    </nav>
                  </Row>
                </Col>
              </Row>
            </Grid>
          )
      )}
    </>
  );
};

export default HolidayList;
