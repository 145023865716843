import React, { useEffect } from "react";
import { Modal, Row, Col, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { removeClass } from "../../_redux/coursesActions";

const RemoveClassModal = (props) => {
  const dispatch = useDispatch();
  const { removeClassLoading, removeClassSuccess } = useSelector(
    (state) => state.courses
  );

  useEffect(() => {
    if (removeClassSuccess) {
      props.onHide();
      console.log("sucess");
    }
  }, [removeClassSuccess]);

  const handleConfirm = () => {
    console.log(props.uid);
    dispatch(removeClass(props.uid));
  };

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-90w"
      backdrop="static"
    >
      <Modal.Body>
        <Row className="d-flex justify-content-center">
          <Col lg={9}>
            <p className="text-center">
              Are you sure you want to remove this class?
            </p>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col lg={11}>
            <Row>
              <Col lg={6}>
                <button
                  className="btn btn-secondary btn-md rounded-pill w-100 text-primary"
                  onClick={() => props.onHide()}
                >
                  Cancel
                </button>
              </Col>
              <Col lg={6}>
                <button
                  className="btn btn-primary btn-md rounded-pill w-100"
                  onClick={handleConfirm}
                >
                  {removeClassLoading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      style={{
                        position: "absolute",
                        left: "1.5rem",
                        top: "1rem",
                      }}
                      aria-hidden="true"
                    />
                  )}
                  Confirm
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default RemoveClassModal;
