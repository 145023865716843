import React, { useState, useEffect } from "react";
import { Modal, Table, Row, Col } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import DatePicker from "react-datepicker";
import BlocksRow from "./BlocksRow";
import { useSelector, useDispatch } from "react-redux";
import { saveBlockSettings } from "../_redux/coursesActions";
import BlocksDialog from "./dialog-bars/BlocksDialog";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import moment from "moment";
import { CloseButton } from "react-bootstrap";
import BlocksDatepicker from "./BlocksDatepicker";

const BlocksModal = (props) => {
  const { show } = props;
  const dispatch = useDispatch();

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [blocks, setBlocks] = useState([]);
  const [nextBlocks, setNextBlocks] = useState([]);
  const [activeDatepicker, setActiveDatepicker] = useState(null);
  const currentYear = moment().year();

  const handleBlockUpdate = ({ block }) => {
    let new_blocks = blocks;
    new_blocks[block.block_no - 1] = block;

    setBlocks(new_blocks);
  };

  const handleNextBlockUpdate = ({ block }) => {
    let new_blocks = nextBlocks;
    new_blocks[block.block_no - 1] = block;

    setNextBlocks(new_blocks);
  };

  const { blockSettings, actionsLoading } = useSelector(
    (state) => state.courses
  );

  useEffect(() => {
    blockFiller(blockSettings);
    setActiveDatepicker(null);
  }, [blockSettings, show]);

  useEffect(() => {
    console.log(nextBlocks);
  }, [nextBlocks]);

  const blockFiller = (blocks_setting) => {
    let new_blocks = [];
    let next_blocks = [];
    let curYear = moment().year();
    let nexYear = moment()
      .add(1, "year")
      .year();

    if (blocks_setting.length > 1) {
      blocks_setting.map((block) => {
        if (block.year == curYear) {
          for (let i = 0; i < 6; i++) {
            new_blocks[i] = {
              block_no: i + 1,
              date_from: null,
              date_to: null,
              year: block?.year,
            };
          }
          block.blocks.map((blk) => {
            new_blocks[blk.block_no - 1] = blk;
          });
          setBlocks(new_blocks);
        }
      });
      // setting next blocks
      let nextBlockYearCount = 0;
      blocks_setting.map((block_setting) => {
        if (block_setting.year == currentYear + 1) {
          nextBlockYearCount++;
        }
      });
      if (nextBlockYearCount == 0) {
        //if walang block dates for next year fill in new blocks
        for (let i = 0; i < 6; i++) {
          next_blocks[i] = {
            block_no: i + 1,
            date_from: null,
            date_to: null,
            year: currentYear + 1,
          };
        }
      } else {
        //if may existing na block dates for the next year
        blocks_setting.map((block_setting) => {
          if (block_setting.year == currentYear + 1) {
            // fill empty blocks
            for (let i = 0; i < 6; i++) {
              next_blocks[i] = {
                block_no: i + 1,
                date_from: null,
                date_to: null,
                year: currentYear + 1,
              };
            }
            // set existing blocks
            block_setting.blocks.map((block) => {
              next_blocks[block.block_no - 1] = block;
            });
          }
        });
      }
    }
    setNextBlocks(next_blocks);
  };

  const handleSaveDates = () => {
    let blocks_with_dates = [];
    let next_blocks_with_dates = [];

    blocks.map((block) => {
      if (block.date_from && block.date_to) {
        blocks_with_dates = [...blocks_with_dates, block];
      }
    });

    nextBlocks.map((block) => {
      if (block.date_from && block.date_to) {
        next_blocks_with_dates = [...next_blocks_with_dates, block];
      }
    });

    // console.log(blocks_with_dates, next_blocks_with_dates);
    let block_settings = [
      {
        year: currentYear,
        blocks: blocks_with_dates,
      },
      {
        year: currentYear + 1,
        blocks: next_blocks_with_dates,
      },
    ];

    dispatch(saveBlockSettings(block_settings));
  };

  return (
    <>
      <BlocksDialog />
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        backdrop="static"
        // keyboard={false}
      >
        {actionsLoading && <ModalProgressBar />}
        <Modal.Header>
          <h3 className="m-auto">Block Dates</h3>
          <a href="javascript:void(0)" onClick={props.onHide}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="black"
              class="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
            </svg>
          </a>
        </Modal.Header>
        <Modal.Body>
          {blockSettings.map((blockSetting) =>
            blockSetting.year == currentYear ? (
              <>
                <Row
                  style={{ backgroundColor: "#d9d9d9", padding: "5px" }}
                  className="mb-5"
                >
                  This year - {blockSetting?.year}
                </Row>
                <Grid>
                  {activeDatepicker?.year == blockSetting?.year ? (
                    <BlocksDatepicker
                      block={activeDatepicker}
                      setActiveDatepicker={setActiveDatepicker}
                      handleBlockUpdate={handleBlockUpdate}
                      year="now"
                    />
                  ) : (
                    blocks.map((block) => (
                      <BlocksRow
                        block={block}
                        // year="now"
                        year="now"
                        handleBlockUpdate={handleBlockUpdate}
                        setActiveDatepicker={setActiveDatepicker}
                      />
                    ))
                  )}
                  {/* {activeDatepicker?.year == blockSetting?.year ? (
                  <BlocksDatepicker
                    block={activeDatepicker}
                    setActiveDatepicker={setActiveDatepicker}
                    handleBlockUpdate={handleBlockUpdate}
                    year="now"
                  />
                ) : (
                  blocks.map((block) => (
                    <BlocksRow
                      block={block}
                      year="now"
                      handleBlockUpdate={handleBlockUpdate}
                      setActiveDatepicker={setActiveDatepicker}
                    />
                  ))
                )} */}
                </Grid>
              </>
            ) : (
              ""
            )
          )}

          <>
            <Row
              style={{ backgroundColor: "#d9d9d9", padding: "5px" }}
              className="mb-5"
            >
              Next year - {currentYear + 1}
            </Row>
            <Grid>
              {activeDatepicker?.year == currentYear + 1 ? (
                <BlocksDatepicker
                  block={activeDatepicker}
                  setActiveDatepicker={setActiveDatepicker}
                  handleBlockUpdate={handleNextBlockUpdate}
                  year="next"
                />
              ) : (
                nextBlocks.map((block) => (
                  <BlocksRow
                    block={block}
                    year="next"
                    handleNextBlockUpdate={handleNextBlockUpdate}
                    setActiveDatepicker={setActiveDatepicker}
                  />
                ))
              )}
            </Grid>
          </>

          {/* <Row
          style={{ backgroundColor: "#d9d9d9", padding: "5px" }}
          className="mb-5"
        >
          Next year - 2023
        </Row> */}
          {/* {blockSettings.map((blockSetting) => ( */}
          {/* ))} */}
          <Row>
            <Col className="text-right">
              <button
                className="btn btn-secondary rounded-pill w-50 text-primary"
                onClick={props.onHide}
              >
                Cancel
              </button>
            </Col>
            <Col>
              <button
                className="btn btn-primary rounded-pill w-50"
                onClick={handleSaveDates}
              >
                Save Dates
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BlocksModal;
