import axios from "axios";

// const clinicalRehabUrl = `${process.env.REACT_APP_API_URL}/clinical-rehab/courses`;
const clinicalRehabUrl = `${process.env.REACT_APP_API_URL}/clinical-rehab`;

export function getWeeks(dateRange) {
  return axios.get(`${clinicalRehabUrl}/list`, {
    params: {
      date: dateRange,
    },
  });
}

export function getRehabList(activeWeek) {
  return axios.get(`${clinicalRehabUrl}/list/classes`, {
    params: {
      // date: dateRange,
      dateFrom: activeWeek.start,
      dateTo: activeWeek.end,
    },
  });
}

export function getExercisePlans() {
  return axios.get(clinicalRehabUrl);
}

export function getPatientHistory(patient_id) {
  return axios.get(`${clinicalRehabUrl}/patient-history/${patient_id}`);
}

export function getPatientNotes(patient_id) {
  return axios.get(`${clinicalRehabUrl}/patient/notes`, {
    params: {
      patient_id: patient_id,
    },
  });
}

export function savePatientNotes(payload) {
  return axios.post(`${clinicalRehabUrl}/patient/notes`, payload);
}
