import React, { useState } from "react";
import { Table, Col, Row } from "react-bootstrap";
import { makeStyles } from "@material-ui/styles";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import moment from "moment";
import { Typography } from "@material-ui/core";
// redux
import { useSelector } from "react-redux";
// compomenents
import BlocksModal from "./BlocksModal";
import HolidaysModal from "./HolidaysModal";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));
const CourseTable = (props) => {
  const { latestDraft, latestDraftLoading } = useSelector(
    (state) => state.courses
  );
  const classes = useStyles();

  const filterDate = (date) => {
    return moment(date).format("Do MMMM");
  };

  const [blocksModal, setBlocksModal] = useState(false);
  const [holidaysModal, setHolidaysModal] = useState(false);

  const handleBlocksModalClose = () => {
    setBlocksModal(false);
  };

  const handleHolidaysModalClose = () => {
    setHolidaysModal(false);
  };

  return (
    <div className="col-md-12">
      <BlocksModal show={blocksModal} onHide={handleBlocksModalClose} />
      <HolidaysModal show={holidaysModal} onHide={handleHolidaysModalClose} />
      <Table responsive className="table table-borderless">
        <thead className="bg-secondary">
          <tr>
            <th colSpan={6}>Patient to MindBody export</th>
          </tr>
        </thead>
        <tbody></tbody>
      </Table>

      {latestDraftLoading ? (
        <Row className="align-items-center justify-content-center my-3">
          <CircularProgress
            style={{
              color: "#ff8a93",
            }}
          />
        </Row>
      ) : (
        <Row className="align-items-center px-3 my-3">
          <>
            <Col lg={2} className="font-weight-bold align-middle">
              <span>Last export of patients</span>
            </Col>
            <Col lg={3} className="font-weight-bold align-middle">
              <span>
                {latestDraft &&
                  moment(latestDraft.last_export_of_patients).format(
                    "DD/MM/YYYY"
                  )}
              </span>
            </Col>
            <Col lg={2}>
              <button
                class="btn btn-primary rounded-pill w-100"
                onClick={() => {
                  setBlocksModal(true);
                }}
              >
                Set blocks
              </button>
            </Col>
            <Col lg={2}>
              <button
                class="btn btn-primary rounded-pill w-100"
                onClick={() => {
                  setHolidaysModal(true);
                }}
              >
                Set holidays
              </button>
            </Col>
            <Col lg={2}>
              <Link
                component={RouterLink}
                to="/course-builder/export"
                color="inherit"
              >
                <button className="btn btn-primary rounded-pill w-100">
                  Copy patients to next block
                </button>
              </Link>
            </Col>
            <Col lg={1}>
              <span className="font-italic">{latestDraft?.current_status}</span>
            </Col>
          </>
        </Row>
      )}
    </div>
  );
};
export default CourseTable;
