import React, { useState, useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { useSelector } from "react-redux";
import { fetchHolidays } from "../../_redux/holiday/holidaysActions";

const ViewHolidays = ({
  list,
  holidays,
  separatedHolidayYears,
  handleEditDates,
}) => {
  return (
    <>
      {list.length == 0 && (
        <Grid container>
          <Grid item lg={11} className="mb-5 d-flex">
            <span className="m-auto">No public holidays</span>
          </Grid>
        </Grid>
      )}
      {separatedHolidayYears?.map((hhh) => (
        <>
          <Row
            style={{ backgroundColor: "#d9d9d9", padding: "5px" }}
            className="mb-5"
          >
            Year - {hhh.year}
          </Row>
          <Grid container>
            <Grid item lg={11} className="mb-5">
              {hhh.holidays.map((holiday) => (
                <Row className="d-flex justify-content-center my-3">
                  <Col lg={6}>
                    <strong>{holiday.name}</strong>
                  </Col>
                  <Col lg={5} className="d-flex justify-content-center">
                    {moment(holiday.holiday_date).format("DD/MM/YYYY")}
                  </Col>
                </Row>
              ))}
            </Grid>
          </Grid>
        </>
      ))}
      <Grid container className="">
        <Grid item lg={11}>
          <Row className="d-flex justify-content-center">
            {/* <Col lg={5} className="">
              <button className="btn btn-primary rounded-pill">+</button>
            </Col>
            <Col lg={5} className="d-flex justify-content-center"> */}
            <button
              className="btn btn-primary rounded-pill"
              onClick={handleEditDates}
            >
              {" "}
              Edit dates
            </button>
            {/* </Col> */}
          </Row>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewHolidays;
