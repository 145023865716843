import React, { useState, useEffect } from "react";
// ui
import {
  Button,
  Card,
  Accordion,
  Table,
  Row,
  Col,
  Tab,
  Nav,
  Sonnet,
} from "react-bootstrap";
import { Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { styled } from "@material-ui/core";
import Select from "react-select";
// compoennts
import CourseTab from "../components/CourseTab";
import Filter from "../../Other/Filter";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchExercisePlans,
  fetchPatientExercises,
  fetchFilters,
} from "../_redux/exercisePlanActions";
import ProgressBar from "../../Other/ProgressBar";
import moment from "moment";
import ExercisePlansSnackbar from "../components/snackbars/ExercisePlansSnackbar";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

const StyledNav = styled(Nav)({
  width: "100%",
  "& a.active": {
    backgroundColor: "lightgray !important",
  },
});

const ExercisePlansPage = () => {
  const { id, classId, mode } = useParams();
  const dispatch = useDispatch();
  const { list, listLoading, filters } = useSelector(
    (state) => state.exercisePlans
  );
  const [open, setOpen] = useState(false);
  const [physio, setPhysio] = useState([]);
  const [defaultPatientId, setDefaultPatientId] = useState(null);
  const location = useLocation();
  const defaultTab = new URLSearchParams(location.search).get("patient_id");
  const history = useHistory();

  const [readOnly, setReadOnly] = useState(false);
  const [patientWasLoaded, setPatientWasLoaded] = useState(false);
  const [restartData, setRestartData] = useState(false);

  useEffect(() => {
    dispatch(fetchExercisePlans(id, classId));
    dispatch(fetchFilters());
    setDefaultPatientId(defaultTab);
  }, []);

  useEffect(() => {
    if (list !== null) {
      fetchSelectedPatientExercises(defaultPatientId);
    }
  }, [list]);

  // useEffect(() => {
  //   if (mode === "read-only") {
  //     console.log(mode);
  //     setReadOnly(true);
  //   }
  // }, [mode]);

  useEffect(() => {
    if (list !== null && !listLoading) {
      if (!patientWasLoaded) {
        fetchSelectedPatientExercises(defaultPatientId);
      }
    }
  }, [defaultPatientId]);

  
  function handlePublishExercises() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleSelectPhysio(value) {
    setPhysio(value);
    // setPhysio();
  }

  const fetchSelectedPatientExercises = (patient_id) => {
    if (!patientWasLoaded) {
      let patient = list.patients.find((patient) => patient.id == patient_id);

      if (patient) {
        dispatch(fetchPatientExercises(patient, id, classId));
        setPatientWasLoaded(true);
      }
    }
  };

  const enableEdit = () => {
    setReadOnly(false);
  }

  const disableEdit = () => {
    setReadOnly(true);

  }
  
  const resetPatientExercises = (patient) => {
    dispatch(fetchPatientExercises(patient, id, classId));
  }

  function patientClick(patient_id) {
    history.push({
      search: `?patient_id=${patient_id}`,
    });
    setPatientWasLoaded(false);
    setDefaultPatientId(patient_id);
  }

  return (
    <div>
      <ExercisePlansSnackbar />
      {listLoading && <ProgressBar />}
      <div className="card border-0">
        {/* <Filter /> */}
        {readOnly && (
          <Row className="justify-content-end mb-3">
            <Col lg={3} className="d-flex">
              <Link
                component={RouterLink}
                // to={`/exercises/${id}`}
                to={`/clinical-rehab-classes/exercise-plans/${id}/${classId}}`}
                color="inherit"
                className="ml-auto"
              >
                <Button
                  onClick={enableEdit}
                  variant="contained"
                  className="bg-primary rounded-pill text-white"
                  size="large"
                >
                  Edit Exercise Plans
                </Button>
              </Link>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <h4>Exercise plans</h4>
          </Col>
          {/* <Col lg={4} className="ml-auto d-flex justify-content-end">
            <a href="" className="mr-5 d-flex align-items-end">
              See all physios
            </a>
            <Select
              placeholder="Physio name"
              options={filters?.physio}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => option.id}
              className="w-50"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#ffe5e5",
                  primary: "#ff8a93",
                },
              })}
            />
          </Col> */}
        </Row>
        {!listLoading && list !== null && (
          <>
            <Accordion defaultActiveKey="0" className="pt-5">
              <Card className="border-0">
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="0"
                  className={`p-3 ${
                    list.patients.every((patient) => patient.is_published == 1)
                      ? "bg-success text-white"
                      : "bg-secondary text-black"
                  } d-flex rounded font-weight-bold`}
                >
                  <span className="d-flex align-items-center">
                    {`${list.studio}, `} {list.time}{" "}
                    {/* {moment(list.start_date).format("dddd")} -{" "} */}
                    {list.day} - {moment(list.start_date).format("Do MMMM")} -{" "}
                    {moment(list.end_date).format("Do MMMM")}
                  </span>
                  <span className="ml-auto d-flex align-items-center">
                    {list.physio_name}
                  </span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey={`${defaultTab}`}
                      onSelect={(key) => patientClick(key)}
                    >
                      <Row className="d-flex">
                        <Col lg={2} className="position-relative">
                          <StyledNav variant="pills" className="flex-column">
                            {list.patients.map((patient) => (
                              <Nav.Item>
                                <Row className="align-items-center">
                                  <Col lg={1}>
                                    {patient.newly_added && (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="#ffba53"
                                        class="bi bi-patch-exclamation-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                      </svg>
                                    )}
                                  </Col>
                                  <Col lg={9}>
                                    <Nav.Link
                                      eventKey={patient.id}
                                      className="text-dark"
                                    >
                                      <Col
                                        lg={10}
                                        style={{paddingRight: 0, paddingLeft: 0}}
                                      >{`${patient.patient_first_name} ${patient.patient_last_name}`}</Col>
                                      <Col className="text-center" lg={2}>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-book"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                                        </svg>
                                      </Col>
                                    </Nav.Link>
                                  </Col>
                                  <Col lg={1}>
                                    {patient.is_published == 1 && (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="#05b602"
                                        class="bi bi-check-circle-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                      </svg>
                                    )}
                                  </Col>
                                </Row>
                              </Nav.Item>
                            ))}
                          </StyledNav>
                        </Col>
                        <Col lg={10}>
                          <Tab.Content>
                            {list.patients.map((patient, index) => (
                              <Tab.Pane eventKey={`${patient.id}`}>
                                <CourseTab
                                  enableEdit={enableEdit}
                                  disableEdit={disableEdit}
                                  resetPatientExercises={resetPatientExercises}
                                  isPublished={patient.is_published}
                                  readOnly={readOnly}
                                  patient={patient}
                                  schedule_id={list.schedule_id}
                                  index={index}
                                  class_id={list.class_id}
                                />
                              </Tab.Pane>
                            ))}
                            <Tab.Pane eventKey="default">
                              <Row>
                                {list.published ? (
                                  <Col className="d-flex justify-content-end align-items-center">
                                    <button className="btn bg-success btn-sm text-white">
                                      <span>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-check2"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                        </svg>
                                        &nbsp; Exercises published
                                      </span>
                                    </button>
                                    <span className="svg-icon svg-icon-sm svg-icon-primary ml-3 svg-icon-sort">
                                      <SVG
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/edit.svg"
                                        )}
                                      />
                                    </span>
                                  </Col>
                                ) : (
                                  ""
                                  // <button
                                  //   className="btn bg-primary btn-sm ml-auto text-white rounded-pill"
                                  //   onClick={handlePublishExercises}
                                  // >
                                  //   <span>Add exercises</span>
                                  // </button>
                                )}
                              </Row>
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </>
        )}

        {/* </div> */}
      </div>
    </div>
  );
};

export default ExercisePlansPage;
