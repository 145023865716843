import * as requestFromServer from "./permissionCrud";
import { permissionSlice } from "./presmissionSlice";

const { actions } = permissionSlice;

export const getPermissionList = () => (dispatch) => {
  dispatch(actions.startCall("listLoading"));

  return requestFromServer
    .getPermissions()
    .then((res) => {
      dispatch(actions.permissionsFetched(res.data));
    })
    .catch((err) => {
      console.log("error", err);
      if (err.response) {
        dispatch(actions.catchError(err.response.data.message));
      }
    });
};
