import React, { useState, useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Checkbox, CircularProgress } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import ProgressBar from "../../../Other/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { fetchProps, updateProps } from "../../_redux/exercisePlanActions";

const PropsModal = (props) => {
  const dispatch = useDispatch();

  const { prop_options, propsLoading } = useSelector(
    (state) => state.exercisePlans
  );

  const [modalProps, setModalProps] = useState([]);

  useEffect(() => {
    props.show &&
      dispatch(fetchProps(props.variation_id, props.patients_exercise_id));
    // formatProps(prop_options);
  }, [props.show]);

  useEffect(() => {
    if (props?.selected_props) {
      let newProps = [];

      prop_options.map((po) => {
        const checkProp = props.selected_props.find((sp) => sp.id == po.id);
        let newProp = { ...po };
        if (checkProp) {
          newProp.checked = true;
        } else {
          newProp.checked = false;
        }
        newProps = [...newProps, newProp];
      });

      setModalProps(newProps);
    }
  }, [prop_options]);

  const handleCheck = (index) => {
    let newProps = [];
    modalProps.map((mp, idx) => {
      if (idx == index) {
        mp.checked = !mp.checked;
      }
      newProps = [...newProps, mp];
    });
    setModalProps(newProps);
  };

  const handleSave = () => {
    let checkedProps = [];
    modalProps.map((mp) => {
      if (mp.checked == true) {
        checkedProps = [...checkedProps, mp];
      }
    });

    let payload = {
      patient_id: props.patient_id,
      exercise_uid: props.exercise_uid,
      variation_uid: props.variation_uid,
      props: checkedProps,
    };
    dispatch(updateProps(payload));

    props.onHide();
  };

  return (
    <Modal {...props} size="md" centered>
      <Modal.Header>
        <h3 className="m-auto">Add/remove prop</h3>
        <a href="javascript:void(0)" onClick={() => props.onHide()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
        </a>
      </Modal.Header>
      <Modal.Body>
        <Row
          className="justify-content-center align-items-center"
          style={{ minHeight: "200px" }}
        >
          {propsLoading ? (
            <CircularProgress
              style={{
                color: "#ff8a93",
              }}
            />
          ) : (
            <Col lg={10}>
              {modalProps.length > 0 ? (
                modalProps.map((pr, index) => (
                  <Row className="justify-content-center mb-5 align-items-center">
                    <Col lg={2} className="text-center">
                      <Checkbox
                        onChange={() => handleCheck(index)}
                        // defaultChecked
                        color="default"
                        // value={pr.label}
                        className="text-primary"
                        // value="checkedG"
                        checked={pr.checked}
                        inputProps={{
                          "aria-label": "checkbox with default color",
                        }}
                      />
                    </Col>
                    <Col lg={5}>{pr.name}</Col>
                    <Col lg={2}>
                      <svg
                        width="20"
                        height="20"
                        viewBox={pr?.propsIcon?.properties?.viewBox}
                        fill="black"
                        xmlns={pr?.propsIcon?.properties?.xmlns}
                      >
                        <path
                          d={pr?.propsIcon?.properties?.path}
                          fill="black"
                        />
                      </svg>
                    </Col>
                  </Row>
                ))
              ) : (
                <Row className="justify-content-center mb-5 align-items-center">
                  No props found
                </Row>
              )}
            </Col>
          )}
        </Row>
        <Row className="justify-content-center">
          <Col lg={4}>
            <button className="btn btn-secondary text-primary rounded-pill w-100">
              Cancel
            </button>
          </Col>
          <Col lg={4}>
            <button
              className="btn btn-primary text-white rounded-pill w-100"
              onClick={handleSave}
            >
              Save
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default PropsModal;
