import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import ExerciseRow from "./ExerciseRow";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import ExerciseContainer from "../../../Other/DragDropExercises/ExercisesContainer";
import {
  addToPatientExercises,
  addVariation,
  handleExerciseEquipmentChange,
  handleVariationFieldChange,
  reOrderExercises,
  removeExercise,
  removeVariation,
  updateProps,
} from "../../_redux/exercisePlanActions";
import { useDispatch } from "react-redux";

const CurrentExercises = ({
  patient_index,
  exercises,
  patient,
  readOnly,
  schedule_id,
}) => {
  const [filteredExercises, setFilteredExercises] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    exercises && filterExercises(exercises);
  }, [exercises]);

  const filterExercises = (e) => {
    let newExercises = [];

    e.map((exercise) => {
      if (exercise.hide.value == false) {
        newExercises = [...newExercises, exercise];
      }
    });

    setFilteredExercises(newExercises);
  };

  const handleAddExercise = (e) => {
    const payload = {
      schedule_id: schedule_id,
      patient_id: patient.patient_id,
      exercises: e,
    };

    dispatch(addToPatientExercises(payload));
  };

  const handleReorder = (e) => {
    // need to set filtered exercises first to prevent flickering on UI
    setFilteredExercises(e);
    const payload = {
      patient_index: patient_index,
      exercises: e,
    };
    dispatch(reOrderExercises(payload));
  };

  const handleExerciseFieldChange = (exercise_index, val) => {
    const payload = {
      patient_index: patient_index,
      exercise_index: exercise_index,
      val: val,
    };

    dispatch(handleExerciseEquipmentChange(payload));
  };

  const handleRemoveExercise = (exercise_index) => {
    const payload = {
      patient_index: patient_index,
      exercise_index: exercise_index,
    };

    dispatch(removeExercise(payload));
  };

  const handleAddVariation = (exercise_index, variation) => {
    // console.log(exercise_index, variation);
    const payload = {
      patient_index: patient_index,
      exercise_index: exercise_index,
      variation: variation,
    };
    dispatch(addVariation(payload));
  };

  const handleRemoveVariation = (exercise_index, variation_index) => {
    const payload = {
      patient_index: patient_index,
      exercise_index: exercise_index,
      variation_index: variation_index,
    };

    dispatch(removeVariation(payload));
  };

  const handleIntChange = (exercise_index, index, target, val) => {
    const payload = {
      patient_index: patient_index,
      exercise_index: exercise_index,
      variation_index: index,
      target: target,
      val: parseInt(val),
    };

    dispatch(handleVariationFieldChange(payload));
  };

  const handlePropUpdate = (exercise_index, variation_index, e) => {
    const payload = {
      patient_index: patient_index,
      exercise_index: exercise_index,
      variation_index: variation_index,
      selected_props: e,
    };
    // console.log(payload);
    dispatch(updateProps(payload));
  };

  return (
    <div className="border-bottom">
      <Row className="justify-content-center mb-5">
        <span style={{ fontSize: "20px" }}>Current exercises</span>
      </Row>
      <div className="mb-5">
        <TransitionGroup>
          <ExerciseContainer
            patient={patient}
            exercises={filteredExercises}
            readOnly={readOnly}
            handleAddExercise={handleAddExercise}
            handleExerciseFieldChange={handleExerciseFieldChange}
            reOrderExercises={handleReorder}
            handleRemoveExercise={handleRemoveExercise}
            handleAddVariation={handleAddVariation}
            handleRemoveVariation={handleRemoveVariation}
            handleIntChange={handleIntChange}
            handlePropUpdate={handlePropUpdate}
          />
          {/* {filteredExercises.length > 0 ? (
            <ExerciseContainer
              exercises={filteredExercises}
              readOnly={readOnly}
              handleAddExercise={handleAddExercise}
              reOrderExercises={handleReorder}
              handleRemoveExercise={handleRemoveExercise}
              handleAddVariation={handleAddVariation}
              handleRemoveVariation={handleRemoveVariation}
              handleIntChange={handleIntChange}
            />
          ) : (
            filteredExercises.map(
              (exercise) => (
                <CSSTransition>
                  <ExerciseRow
                    exercise={exercise}
                    patient={patient}
                    readOnly={readOnly}
                  />
                </CSSTransition>
                // exercise.hide.value == false && (
              )
            )
            // )
            <Row className="justify-content-center">No exercises found</Row>
          )} */}
        </TransitionGroup>
      </div>
    </div>
  );
};

export default CurrentExercises;
