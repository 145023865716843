import { createSlice } from "@reduxjs/toolkit";

const defaultTemplate = {
  name: "",
  class_types: [],
  tags: [],
  themes: [],
  level: "",
  exercises: [],
};

const initialTemplateState = {
  list: [],
  template: null,
  templateUI: null,
  tags: null,
  // patientNotes: null,
  // exercisePlans: null,
  // weeks: [],
  // activeWeek: null,
  saveDraftLoading: false,
  templateLoading: false,
  // patientNotesLoading: false,
  // weeksLoading: false,
  draftSaved: false,
  listLoading: false,
  actionsLoading: false,
  isSuccess: false,
  isError: false,
  isInfo: false,
  message: "",
};

export const templateSlice = createSlice({
  name: "templateSlice",
  initialState: initialTemplateState,
  reducers: {
    catchError: (state, action) => {
      const { message } = action.payload;

      state.saveDraftLoading = false;
      state.isError = true;
      state.message = message;
      state.listLoading = false;
      state.templateLoading = false;
    },
    startCall: (state, action) => {
      state[action.payload] = true;
    },
    tagsFetched: (state, action) => {
      state.tags = action.payload;
    },
    defaultTemplateMounted: (state, action) => {
      state.templateUI = defaultTemplate;
    },
    templatesFetched: (state, action) => {
      const { data } = action.payload;

      state.list = data;
      state.listLoading = false;
    },
    templateFetched: (state, action) => {
      const { data } = action.payload;

      state.template = data;
      state.templateUI = data;
      state.templateLoading = false;
    },
    templateExercisesAdded: (state, action) => {
      state.templateUI.exercises = [
        ...state.templateUI.exercises,
        ...action.payload,
      ];
    },
    exerciseRemoved: (state, action) => {
      state.templateUI.exercises.splice(action.payload, 1);
    },
    exercisesReordered: (state, action) => {
      state.templateUI.exercises = action.payload;
    },
    variationAdded: (state, action) => {
      const { index, variation } = action.payload;

      console.log("var add slice");
      console.log(index, variation);

      state.templateUI.exercises[index].variations = [
        ...state.templateUI.exercises[index].variations,
        variation,
      ];
    },
    variationRemoved: (state, action) => {
      const { exercise_index, index } = action.payload;

      state.templateUI.exercises[exercise_index].variations.splice(index, 1);
    },
    templateFieldUpdated: (state, action) => {
      const { target, val } = action.payload;

      state.templateUI[target] = val;
    },
    variantIntChanged: (state, action) => {
      const { exercise_index, index, target, val } = action.payload;

      state.templateUI.exercises[exercise_index].variations[index][
        target
      ] = val;
    },
    draftSaved: (state, action) => {
      const { data } = action.payload;
      state.saveDraftLoading = false;
      state.template = data;
      state.isSuccess = true;
      state.message = "Draft saved.";
      state.draftSaved = true;
    },
    resetStatus: (state, action) => {
      state.isSuccess = false;
      state.saveDraftLoading = false;
      state.draftSaved = false;
      // state.publishLoading = false;
      state.isError = false;
      state.isInfo = false;
      state.templateLoading = false;
      state.message = "";
    },
  },
});
