import { createSlice } from "@reduxjs/toolkit";

const initialPermissionState = {
  list: [],
  actionsLoading: false,
  listLoading: false,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const permissionSlice = createSlice({
  name: "permissions",
  initialState: initialPermissionState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.isError = false;
      state[action.payload] = true;
    },
    permissionsFetched: (state, action) => {
      const { permissions } = action.payload;
      state.list = permissions;
      state.listLoading = false;
    },
  },
});
