import React, { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { Table, Form, Row, Col, Card } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { makeStyles, styled } from "@material-ui/styles";
import Select, { StylesConfig, components } from "react-select";
import Button from "@material-ui/core/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import Fab from "@material-ui/core/Fab";
import Checkbox from "@material-ui/core/Checkbox";
import PropsModal from "./PropsModal";
import EquipmentModal from "./EquipmentModal";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const Input = styled("input")({
  display: "none",
});

const VariationsComponent = ({
  readOnly,
  variation,
  index,
  handleVariationFieldChange,
  handleVariationSelectChange,
  handleVariationCheckChange,
  handleVariationFileChange,
  handleVariationFileRemove,
  handleVariationSingleSelectChange,
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const imageRef = useRef();
  const videoRef = useRef();

  const [propsModal, setPropsModal] = useState(false);
  const [selectedProps, setSelectedProps] = useState(null);
  const [equipmentModalShow, setEquipmentModalShow] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewVideo, setPreviewVideo] = useState(null);
  const [errors, setErrors] = useState({
    image: null,
    video: null,
  });
  const imageFileTypes = ["png", "jpeg", "jpg"];
  const videoFileTypes = ["mp4"];

  const { tags, exercise } = useSelector(
    (state) => state.exerciseLibrary,
    shallowEqual
  );

  const variationValidationSchema = Yup.object().shape({
    variationName: Yup.string().required("This field is required"),
  });

  const initialValues = {
    variationName: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: variationValidationSchema,
    onSubmit: (values) => {},
  });

  const colourStyles: StylesConfig<ColourOption, true> = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        ":active": {
          backgroundColor: "#ffe5e5",
        },
        ":focus": {
          backgroundColor: "#ffe5e5",
        },
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        padding: "3px",
        backgroundColor: "#ffe5e5",
      };
    },
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#ff5764 ",
        color: "white",
      },
    }),
  };

  const handleEquipModalClose = () => {
    setEquipmentModalShow(false);
    setTimeout(() => {
      setSelectedEquipment(null);
    }, 100);
  };

  const handlePropsModalClose = () => {
    setPropsModal(false);
    setTimeout(() => {
      setSelectedProps(null);
    }, 100);
  };

  const handlePropsModal = () => {
    setPropsModal(true);
  };

  const handleImageButton = () => {
    imageRef.current.click();
  };

  const handleVideoButton = () => {
    videoRef.current.click();
  };

  const req_size = 10000000; //30mb

  const handleImageChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    let acceptableFileTypes = [];
    imageFileTypes.map((fileType) => {
      acceptableFileTypes = [...acceptableFileTypes, `image/${fileType}`];
    });

    if (fileObj) {
      if (!acceptableFileTypes.includes(fileObj.type)) {
        return;
      }
      if (fileObj.size > req_size) {
        setErrors((prev) => ({
          ...prev,
          image: "File size should be less than 10Mb",
        }));
        handleVariationFileRemove("image");
        return;
      }
      setErrors((prev) => ({
        ...prev,
        image: null,
      }));
      fileObj.target_name = event.target.name;
      handleVariationFileChange(fileObj);
      // // 👇️ reset file input
      event.target.value = null;
    }
  };

  const handleVideoChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    let acceptableFileTypes = [];
    videoFileTypes.map((fileType) => {
      acceptableFileTypes = [...acceptableFileTypes, `video/${fileType}`];
    });

    if (fileObj) {
      if (!acceptableFileTypes.includes(fileObj.type)) {
        console.log("file type not supported");
        return;
      }
      if (fileObj.size > req_size) {
        setErrors((prev) => ({
          ...prev,
          video: "File size should be less than 10Mb",
        }));
        handleVariationFileRemove("video");
        return;
      }
      setErrors((prev) => ({
        ...prev,
        video: null,
      }));
      fileObj.target_name = event.target.name;
      handleVariationFileChange(fileObj);
      // // 👇️ reset file input
      event.target.value = null;
    }
  };

  const handleMultiValueClick = (data) => {
    // console.log('A multi value has been clicked', data);
    // setSelectedEquipment(data);
    setSelectedProps(data);
    handlePropsModal();
  };

  const MultiValueLabel = ({ children, ...props }) => {
    const onMouseDown: MouseEventHandler = (e) => {
      e.preventDefault();
      e.stopPropagation();
      handleMultiValueClick(props.data);
    };
    const innerProps = { ...props, onMouseDown };
    return (
      <components.MultiValueLabel {...props} innerProps={innerProps}>
        <span style={{ fontSize: "85%", padding: "3px", cursor: "pointer" }}>
          {children}
        </span>
      </components.MultiValueLabel>
    );
  };

  const optionFromId = () => {
    let values = [];
    tags?.props &&
      tags.props.map((option) => {
        if (variation?.props.includes(option.id)) {
          values = [...values, option];
        }
      });
    return values;
  };

  const valueFromId = () => {
    let value = null;
    tags?.equipments &&
      variation.equipment &&
      tags.equipments.map((option) => {
        if (option.id == variation.equipment) {
          value = option;
        }
        // if (variation?.equipment.includes(option.id)) {
        //   return option;
        // }
      });

    return value;
  };

  useEffect(() => {
    if (variation.image_link) {
      setPreviewImage(variation.image_link);
      return;
    }
    if (!variation["image"]) {
      setPreviewImage(undefined);
      return;
    }

    const imgUrl = URL.createObjectURL(variation["image"]);

    setPreviewImage(imgUrl);

    console.log("revoking image..");
    return () => URL.revokeObjectURL(imgUrl);
  }, [variation.image, variation.image_link]);

  useEffect(() => {
    if (variation.video_link) {
      setPreviewVideo(variation.video_link);
      return;
    }

    if (!variation["video"]) {
      setPreviewVideo(undefined);
      return;
    }

    const videoUrl = URL.createObjectURL(variation["video"]);

    setPreviewVideo(videoUrl);

    console.log("revoking video..");
    return () => URL.revokeObjectURL(videoUrl);
  }, [variation.video, variation.video_link]);

  return (
    <Row className="p-5">
      <PropsModal
        variation_index={index}
        show={propsModal}
        onHide={handlePropsModalClose}
        selected={selectedProps}
      />
      <EquipmentModal
        show={equipmentModalShow}
        onHide={handleEquipModalClose}
        selected={selectedEquipment}
      />
      <Col lg={9}>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Form.Group
            className="mb-3 position-relative"
            controlId="formBasicEmail"
          >
            <Form.Label>
              <strong>Name *</strong>
            </Form.Label>
            <Form.Control
              readOnly={readOnly}
              type="text"
              placeholder="Name"
              name="name"
              value={variation.name}
              onChange={handleVariationFieldChange}
            />
            {variation?.name == "" && (
              <svg
                style={{
                  position: "absolute",
                  top: "35px",
                  right: "-20px",
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="red"
                class="bi bi-exclamation-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
              </svg>
            )}
            {/* <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                      </Form.Text> */}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              <strong className="mr-3">Equipment </strong>
            </Form.Label>
            <span style={{ fontSize: "10px" }}>
              Choose equipment if this variation only relates to this piece of
              equipment
            </span>
            <Select
              isDisabled={readOnly}
              name="equipment"
              onChange={handleVariationSingleSelectChange}
              options={exercise?.equipment}
              value={valueFromId()}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => option.id}
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              placeholder="Equipments"
              styles={colourStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#ffe5e5",
                  primary: "#ff8a93",
                },
              })}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              <strong className="mr-3">Springs</strong>
            </Form.Label>
            <span style={{ fontSize: "10px" }}>
              Select a starting point for patients to start with
            </span>
            <Form.Control
              readOnly={readOnly}
              type="text"
              placeholder="Free type"
              name="springs"
              value={variation.springs}
              onChange={handleVariationFieldChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <strong>Description</strong>
            </Form.Label>
            <Form.Control
              readOnly={readOnly}
              as="textarea"
              name="description"
              value={variation.description}
              onChange={handleVariationFieldChange}
              rows={3}
              placeholder="Description of basic variation"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <strong>Set up</strong>
            </Form.Label>
            <Form.Control
              readOnly={readOnly}
              as="textarea"
              rows={3}
              placeholder="Set up"
              name="setup"
              value={variation.setup}
              onChange={handleVariationFieldChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <strong>Cues</strong>
            </Form.Label>
            <Form.Control
              readOnly={readOnly}
              as="textarea"
              rows={3}
              placeholder="Cues"
              name="cues"
              value={variation.cues}
              onChange={handleVariationFieldChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <strong>Faulty patterns</strong>
            </Form.Label>
            <Form.Control
              readOnly={readOnly}
              as="textarea"
              rows={3}
              placeholder="Faulty patterns"
              name="faulty_patterns"
              value={variation.faulty_patterns}
              onChange={handleVariationFieldChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              <strong>Props</strong>
            </Form.Label>
            <Row>
              <Col lg={9}>
                <Select
                  isDisabled={readOnly}
                  name="props"
                  value={variation.props}
                  onChange={handleVariationSelectChange}
                  options={tags?.props}
                  getOptionLabel={(option) => `${option.name}`}
                  getOptionValue={(option) => option.id}
                  aria-labelledby="aria-label"
                  inputId="aria-example-input"
                  placeholder="Props"
                  isMulti
                  styles={colourStyles}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#ff8a93",
                      primary: "#ffe5e5",
                    },
                  })}
                  components={{
                    MultiValueLabel,
                  }}
                />
              </Col>
              {!readOnly && (
                <Col>
                  <button
                    className="btn btn-primary rounded-pill"
                    onClick={handlePropsModal}
                  >
                    + Add new prop
                  </button>
                </Col>
              )}
            </Row>
          </Form.Group>
        </Form>
        <Row className="my-5 d-flex align-items-center">
          <Col lg={3}>
            <strong>{!readOnly && "Add"} Image</strong>
          </Col>
          {!readOnly && (
            <Col lg={4}>
              <button
                className="btn btn-primary rounded-pill w-50"
                onClick={handleImageButton}
              >
                Upload
              </button>
            </Col>
          )}
          <Col
            lg={5}
            className="d-flex align-items-center"
            // style={{
            //   display: "inline-block",
            //   overflow: "hidden",
            //   whiteSpace: "nowrap",
            // }}
          >
            {/* {errors.image && errors.image ? (
              <span className="mb-0 mx-5 font-italic">{errors.image}</span>
            ) : (
              ""
            )} */}
            {previewImage ? (
              <>
                <a href={previewImage} target="_blank">
                  <img src={previewImage} width={150} />
                </a>
                {/* <span
                  className="font-weight-bold"
                  style={{
                    display: "inline-flex",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: "200px",
                  }}
                >
                  {variation.image && variation.image?.name}
                </span> */}
                {!readOnly && (
                  <a
                    name="image"
                    href="javascript:void(0)"
                    className="ml-3"
                    onClick={() => {
                      handleVariationFileRemove("image");
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-x-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                    </svg>
                  </a>
                )}
              </>
            ) : (
              `File type should be ${imageFileTypes.toString()} and less than 10Mb`
            )}
            <Input
              accept="image/*"
              id="contained-button-file"
              hidden
              ref={imageRef}
              type="file"
              name="image"
              onChange={handleImageChange}
            />
          </Col>
        </Row>
        <Row className="my-5 d-flex align-items-center">
          <Col lg={3}>
            <strong>{!readOnly && "Add"} Video</strong>
          </Col>
          {!readOnly && (
            <Col lg={4}>
              <button
                className="btn btn-primary rounded-pill w-50"
                onClick={handleVideoButton}
              >
                Upload
              </button>
            </Col>
          )}
          <Col lg={5} className="d-flex align-items-center">
            {/* {errors.video && errors.video ? (
              <span className="mb-0 mx-5 font-italic">{errors.video}</span>
            ) : (
              ""
            )} */}
            {previewVideo ? (
              <>
                <a href={previewVideo} target="_blank">
                  <video src={previewVideo} width={150} />
                </a>
                {/* <span
                  className="font-weight-bold"
                  style={{
                    display: "inline-flex",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: "200px",
                  }}
                >
                  {variation.image && variation.image?.name}
                </span> */}
                {!readOnly && (
                  <a
                    name="video"
                    href="javascript:void(0)"
                    className="ml-3"
                    onClick={() => {
                      handleVariationFileRemove("video");
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-x-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                    </svg>
                  </a>
                )}
              </>
            ) : (
              `File should be ${videoFileTypes.toString()} and less than 10Mb`
            )}
            <Input
              accept="video/*"
              id="contained-button-file"
              hidden
              // multiple
              ref={videoRef}
              type="file"
              name="video"
              onChange={handleVideoChange}
            />
          </Col>
        </Row>
        {/* <Grid container spacing={1} className="input-group mb-5">
          <Grid item xs={2}>
            <strong>Add image</strong>
          </Grid>
          <Grid item xs={6}>
            <label className="w-100">
              <Fab
                component="span"
                variant="extended"
                size="medium"
                // color="primary"
                aria-label="Add"
                // className={classes.margin}
                className="bg-primary text-white"
              >
                <span className="px-5">Upload</span>
              </Fab>
              <span className="mb-0 mx-5 font-italic">
                {formik.touched.image && formik.errors.image
                  ? formik.errors.image
                  : null}
              </span>
              <span className="font-weight-bold">
                {formik.values.image !== "" && formik.values.image?.name
                  ? formik.values.image.name
                  : null}
              </span>
              <Input
                accept="image/*"
                id="contained-button-file"
                hidden
                // multiple
                type="file"
                name="image"
                onChange={(event) =>
                  formik.setFieldValue("image", event.target.files[0])
                }
              />
            </label>
          </Grid>
        </Grid> */}
      </Col>
      <Col lg={3}>
        <Row className="align-items-center">
          <Col lg={2}>
            <Checkbox
              disabled={readOnly}
              value="basic"
              color="primary"
              name="equipment_level"
              onChange={handleVariationCheckChange}
              checked={variation.equipment_level == "basic" ? true : false}
              //   style={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
              size="large"
              inputProps={{
                "aria-label": "primary checkbox",
              }}
            />
          </Col>
          <Col>Basic</Col>
        </Row>
        <Row className="align-items-center">
          <Col lg={2}>
            <Checkbox
              disabled={readOnly}
              value="intermediate"
              color="primary"
              name="equipment_level"
              // defaultChecked
              onChange={handleVariationCheckChange}
              checked={
                variation.equipment_level == "intermediate" ? true : false
              }
              // checked={true}
              //   style={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
              size="large"
              inputProps={{
                "aria-label": "primary checkbox",
              }}
            />
          </Col>
          <Col>Intermediate</Col>
        </Row>
        <Row className="align-items-center">
          <Col lg={2}>
            <Checkbox
              disabled={readOnly}
              value="advanced"
              color="primary"
              name="equipment_level"
              onChange={handleVariationCheckChange}
              checked={variation.equipment_level == "advanced" ? true : false}
              // defaultChecked
              //   style={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
              size="large"
              inputProps={{
                "aria-label": "primary checkbox",
              }}
            />
          </Col>
          <Col>Advanced</Col>
        </Row>
      </Col>
    </Row>
  );
};

export default VariationsComponent;
