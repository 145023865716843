/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Redirect, Route, BrowserRouter } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import ResetPassword from "./ResetPassword";

export function AuthPage() {
  return (
    <Switch>
      <Route exact={true} component={ResetPassword} path="/auth/reset" />
      <div className="d-flex flex-column flex-root overflow-hidden">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid"
          style={{ background: "linear-gradient(#f0cdc9, #fbf1f0)" }}
          id="kt_login"
        >
          <div className="row">
            <div className="col-lg-6 d-none d-md-block d-lg-block">
              <Link to="/auth/login" className="flex-column-auto pb-lg-0 ">
                <img
                  alt="Logo"
                  className="img-fluid "
                  src={toAbsoluteUrl("/media/logos/madison-lavern.png")}
                  // style={{ width: "55%" }}
                />
              </Link>
            </div>
            <div className="col-12 col-md-6 col-lg-6 d-flex align-items-center justify-content-center">
              {/* <Switch> */}
              <ContentRoute path="/auth/login" component={Login} />
              <ContentRoute
                path="/auth/registration"
                component={Registration}
              />
              <ContentRoute
                path="/auth/forgot-password"
                component={ForgotPassword}
              />
              <ContentRoute path="/auth/reset" component={ResetPassword} />
              <Redirect from="/auth" exact={true} to="/auth/login" />
              <Redirect to="/auth/login" />
              {/* </Switch> */}
            </div>
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </Switch>
  );
}
