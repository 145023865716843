import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initalCoursesState = {
  classes: [],
  holidays: [],
  perviousExportData: null,
  latestDraft: null,
  previousRecords: [],
  filters: [],
  staffs: [],
  previousCurrentBlock: null,
  nextBlock: null,
  blockSettings: [],
  searchedPatientList: [],
  addClassLoading: false,
  addClassSuccess: false,
  updateScheduleIdLoading: false,
  updateScheduleIdSuccess: false,
  removeClassLoading: false,
  removeClassSuccess: false,
  previousExportLoading: false,
  resetDraftLoading: false,
  resetDraftSuccess: false,
  exportLoading: false,
  exportLoadingProgress: 0,
  exportSuccess: false,
  verifyLoading: false,
  verifyLoadingProgress: 0,
  verifyLoadingFinish: false,
  verificationErrors: [],
  verificationMessage: "",
  searchedPatientListLoading: false,
  latestDraftLoading: false,
  searchedPatientAdded: false,
  blockSettingsUpdated: false,
  listLoading: false,
  classesLoading: false,
  classesLoadingProgress: 0,
  requestConflict: false,
  requestConflictMessage: "",
  draftLoading: false,
  actionsLoading: false,
  isVerified: false,
  isError: false,
  isInfo: false,
  isSuccess: false,
  message: "",
};

export const callTypes = {
  list: "list",
  resetDraftLoading: "resetDraftLoading",
  latestDraftLoading: "latestDraftLoading",
  updateScheduleIdLoading: "updateScheduleIdLoading",
  classesLoading: "classesLoading",
  verifyLoading: "verifyLoading",
  addClassLoading: "addClassLoading",
  action: "action",
};

export const coursesSlice = createSlice({
  name: "courses",
  initialState: initalCoursesState,
  reducers: {
    catchError: (state, action) => {
      state.isError = true;
      state.message = `${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    conflictError: (state, action) => {
      state.requestConflict = true;
      state.requestConflictMessage =
        "Course export in progress by another user or in another tab. Unable to save or verify export data until current course export is completed.";
      // state.requestConflictMessage = action.payload;
    },
    conflictErrorClose: (state, action) => {
      state.requestConflict = false;
      state.verifyLoading = false;
      state.verifyLoadingProgress = 0;
      state.exportLoading = false;
      state.exportLoadingProgress = 0;
      state.draftLoading = false;
      state.actionsLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.classesLoading) {
        state.classesLoading = true;
        state.classesLoadingProgress = 0;
      } else if (action.payload.callType === callTypes.verifyLoading) {
        state.actionsLoading = true;
        state.verifyLoading = true;
      } else if (action.payload.callType === callTypes.resetDraftLoading) {
        state.resetDraftLoading = true;
      } else if (action.payload.callType === callTypes.addClassLoading) {
        state.addClassLoading = true;
      } else if (action.payload.callType === callTypes.removeClassLoading) {
        state.removeClassLoading = true;
      } else if (
        action.payload.callType === callTypes.updateScheduleIdLoading
      ) {
        state.updateScheduleIdLoading = true;
      } else if (action.payload.callType === callTypes.latestDraftLoading) {
        state.latestDraftLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    resetStatus: (state, action) => {
      state.isInfo = false;
      state.isError = false;
      state.isSuccess = false;
      state.exportSuccess = false;
      state.draftLoading = false;
      state.actionsLoading = false;
      state.searchedPatientAdded = false;
      state.verifyLoadingFinish = false;
      state.resetDraftSuccess = false;
      state.updateScheduleIdSuccess = false;
      state.addClassSuccess = false;
      state.removeClassSuccess = false;
      state.requestConflict = false;
      state.isVerified = false;
      state.verificationErrors = [];
      state.exportLoading = false;
      state.exportLoadingProgress = 0;
      state.verifyLoadingProgress = 0;
      state.message = "";
    },
    latestDraftFetched: (state, action) => {
      state.latestDraftLoading = false;
      state.latestDraft = action.payload;
    },
    coursesListFetched: (state, action) => {
      const courses = action.payload;
      state.list = courses;
    },
    currentCourseFetched: (state, action) => {
      const course = action.payload;
      state.currentCourse = course;
    },
    nextCourseFetched: (state, action) => {
      const course = action.payload;
      state.nextCourse = course;
    },
    exercisePlansFetched: (state, action) => {
      const exercisePlansList = action.payload;
      state.exercisePlansList = exercisePlansList;
    },
    blockSettingsFetched: (state, action) => {
      const { block_settings } = action.payload;

      state.blockSettings = block_settings;
    },
    previousRecordsFetched: (state, action) => {
      const { results } = action.payload;

      state.previousExportLoading = false;
      state.previousRecords = results;
    },
    startPreviousExportCall: (state, action) => {
      state.previousExportLoading = true;
    },
    blockSettingsUpdateFetched: (state, action) => {
      const { block_settings } = action.payload;

      state.actionsLoading = false;
      state.isSuccess = true;
      state.message = "Block dates successfully saved.";
      state.blockSettings = block_settings;
    },
    currentBlockClassListFetched: (state, action) => {
      const data = action.payload;

      state.currentBlockClassList = data;
      state.currentBlockClassListLoading = false;
    },
    startDraftLoading: (state, action) => {
      state.draftLoading = true;
      state.actionsLoading = true;
    },
    // startCurrentBlockLoading: (state, action) => {
    //   if (action.payload == "list") {
    //     state.currentBlockClassListLoading = true;
    //   } else {
    //     state.currentBlockClassActionLoading = true;
    //   }
    // },
    classPatientListFetched: (state, action) => {
      const { data } = action.payload;

      state.classPatientList = data;
      state.classPatientListLoading = false;
      state.classDraftSaved = false;
      state.classPatientsFetched = true;
    },
    startClassPatientsLoading: (state, action) => {
      const { patient_id } = action.payload;
      let newSearchedPatientList = [];

      state.searchedPatientList.map((spl) => {
        if (spl.id == patient_id) {
          spl.isLoading = true;
        }
        newSearchedPatientList = [...newSearchedPatientList, spl];
      });

      state.searchedPatientList = newSearchedPatientList;
      state.classPatientListLoading = true;
      state.classPatientsFetched = false;
    },
    searchedPatientListFetched: (state, action) => {
      const { data } = action.payload;

      state.searchedPatientList = data;
      state.searchedPatientListLoading = false;
    },
    addSearchPatientToClass: (state, action) => {
      const { patient, uid, patient_uid } = action.payload;
      let tempClasses = [];
      state.classes.map((cls) => {
        if (cls.uid == uid) {
          let pt = {
            uid: patient_uid,
            Id: patient.id,
            FirstName: patient.first_name,
            LastName: patient.last_name,
            mark_as_id: 1,
          };
          cls.patients = [...cls.patients, pt];
        }
        tempClasses = [...tempClasses, cls];
        state.isSuccess = true;
        state.searchedPatientAdded = true;
        state.message = "Patient added to class.";
      });

      state.classPatientListLoading = false;
    },
    updateSearchPatientToClass: (state, action) => {
      const { patient, uid, index } = action.payload;
      let tempClasses = [];
      state.classes.map((cls) => {
        if (cls.uid == uid) {
          let pt = {
            uid: uid,
            Id: patient.id,
            FirstName: patient.first_name,
            LastName: patient.last_name,
            mark_as_id: 1,
          };
          cls.patients[index] = pt;
          // cls.patients = [...cls.patients, pt];
        }
        tempClasses = [...tempClasses, cls];
        state.isSuccess = true;
        state.searchedPatientAdded = true;
        state.message = "Patient updated to class.";
      });
      state.classPatientListLoading = false;
    },
    patientsConfirmUpdated: (state, action) => {
      state.classPatientListChanges = true;
      state.classPatientList.patients = action.payload;
    },
    removePatientFromList: (state, action) => {
      state.classPatientList.patients = action.payload;

      state.classPatientListLoading = false;
    },
    searchedPatientListLoading: (state) => {
      state.searchedPatientListLoading = true;
    },
    exportValidated: (state, action) => {
      state.isInfo = true;
      state.message = action.payload;
    },
    startExportLoading: (state, action) => {
      state.message = "Exporting to Mindbody";
      state.exportLoading = true;
      state.actionsLoading = true;
    },
    draftExported: (state, action) => {
      // state.message = "Starting export";
      state.exportLoadingProgress += action.payload;
    },
    exportFinished: (state, action) => {
      state.exportSuccess = true;
      // state.exportLoading = false;
      state.isSuccess = true;
      state.message = "Course and patients exported successfully.";
    },
    courseExported: (state, action) => {
      state.exportLoading = false;
      state.exportSuccess = true;
      state.message = action.payload;
    },
    draftSaved: (state, action) => {
      state.isSuccess = true;
      state.message = action.payload;
      state.currentBlockClassActionLoading = false;
      state.classPatientListChanges = false;
      state.classDraftSaved = true;
      state.draftLoading = false;
      state.actionsLoading = false;
    },
    currentBlockDraftFetched: (state, action) => {
      state.currentBlockDraft = action.payload;
    },
    resetSearch: (state, action) => {
      state.searchedPatientList = [];
    },
    // v2
    classesFetched: (state, action) => {
      const {
        classes,
        filters,
        previous_current_block,
        next_block,
        holidays,
        staffs,
      } = action.payload;
      // console.log("classes", classes);
      state.classes = classes;
      state.filters = filters;
      state.holidays = holidays;
      state.previousCurrentBlock = previous_current_block;
      state.nextBlock = next_block;
      state.staffs = staffs;
      state.classesLoading = true;
      state.classesLoadingProgress = 0;

      state.listLoading = false;
    },
    emptyClassFetched: (state, action) => {
      state.classesLoadingProgress = 100;
      state.isInfo = true;
      state.message = "No classes were found within the block.";
    },
    progressUpdate: (state, action) => {
      let progress = (1 / state.classes.length) * 100;

      state.classesLoadingProgress = state.classesLoadingProgress + progress;
    },
    classPatientsUpdated: (state, action) => {
      const { patients, uid } = action.payload;

      // if there are no classes make it load 100%
      let progress = (1 / state.classes.length) * 100;

      // let clz = state.classes.find((clx) => {
      //   return clx.class_id == class_id;
      // });

      // console.log(clz);
      state.classes.map((cls) => {
        if (cls.uid == uid) {
          cls.patients = patients;
        }
      });

      state.classesLoadingProgress = state.classesLoadingProgress + progress;
    },
    classVerified: (state, action) => {
      const { data, classData, progress } = action.payload;
      if (data?.status == false) {
        data.classData = classData;
        // let error = {
        //   message: message,
        //   error: data.data,
        //   classData: classData,
        // };
        state.verificationErrors = [...state.verificationErrors, data];
      }
      state.verifyLoadingProgress += progress;
    },
    verificationFinished: (state, action) => {
      state.verifyLoadingFinish = true;
      state.verifyLoading = false;
      state.actionsLoading = false;
      if (state.verificationErrors.length == 0) {
        state.isVerified = true;
        state.verificationMessage =
          "Classes and patients verified successfully";
      } else {
        state.verificationErrors.sort(
          (a, b) =>
            moment(a.classData.start_date_time) -
            moment(b.classData.start_date_time)
        );
        state.verificationMessage =
          "The below classes has patients in mindbody already or the class for the next block cannot be found; therefore, export cannot be completed.";
      }
    },
    verificationProgressReset: (state, action) => {
      state.verifyLoadingProgress = 0;
    },
    patientMarkUpdated: (state, action) => {
      const { uid, patient_id, mark_id } = action.payload;
      // console.log(patient_id, mark_id);
      let tempClasses = [];

      state.classes.map((cls) => {
        if (cls.uid == uid) {
          cls.patients.map((patient) => {
            if (patient.Id == patient_id) {
              patient.mark_as_id = mark_id;
            }
          });
        }
        tempClasses = [...tempClasses, cls];
      });
      state.classes = tempClasses;
    },
    patientRemoved: (state, action) => {
      const { uid, patient_id } = action.payload;
      let tempClasses = [];

      state.classes.map((cls) => {
        if (cls.uid == uid) {
          let new_patients = [];
          cls.patients.map((patient) => {
            if (patient.Id != patient_id) {
              new_patients = [...new_patients, patient];
            }
          });
          cls.patients = new_patients;
          tempClasses = [...tempClasses, cls];
        } else {
          tempClasses = [...tempClasses, cls];
        }
        state.classes = tempClasses;
      });
    },
    classesLoadingFinished: (state, action) => {
      state.classesLoading = false;
    },
    patientTransfered: (state, action) => {
      const { from, to } = action.payload;
      const cls = state.classes.find((clx) => clx.uid == from.uid);
      const patient = cls.patients.splice(from.index, 1);
      // insert to where the user dropped
      // cls.patients
      const to_class = state.classes.find((clx) => clx.uid == to.uid);
      to_class.patients.splice(to.index, 0, ...patient);

      // state.classes = tempClasses;
    },
    previousExportClassesFetched: (state, action) => {
      const data = action.payload;

      state.perviousExportData = data;
      state.listLoading = false;
    },
    classAdded: (state, action) => {
      // state.classes = [...state.classes, action.payload];
      let newClasses = [];

      state.classes.map((cls) => {
        newClasses = [...newClasses, cls];
      });
      newClasses = [...newClasses, action.payload];
      newClasses.sort((a, b) => moment(a.start_date) - moment(b.start_date));
      state.classes = newClasses;
      state.addClassSuccess = true;
      state.addClassLoading = false;
      state.isSuccess = true;
      state.message = "New class added.";
    },
    scheduleIdUpdated: (state, action) => {
      let newClasses = [];
      const { uid, newScheduleId } = action.payload;

      state.classes.map((cls) => {
        if (cls.uid == uid) {
          cls.schedule_id = newScheduleId;
        }
        newClasses = [...newClasses, cls];
      });

      state.classes = newClasses;
      state.updateScheduleIdLoading = false;
      state.updateScheduleIdSuccess = true;
      state.isSuccess = true;
      state.message = "Class ID added";
    },
    classRemoved: (state, action) => {
      // const removingClass = state.classes.find(
      //   (clx) => clx.class_id == action.payload
      // );
      let newClasses = [];
      state.classes.map((cls) => {
        if (cls.uid !== action.payload) {
          // return cls;
          newClasses = [...newClasses, cls];
        }
      });
      state.classes = newClasses;
      // console.log(newClasses);
      state.removeClassLoading = false;
      state.removeClassSuccess = true;
      state.isSuccess = true;
      state.message = "Class removed";
    },
    draftReset: (state, action) => {
      state.resetDraftLoading = false;
      state.resetDraftSuccess = true;
      state.isSuccess = true;
      state.message = "Draft reset successfully.";
    },
  },
});
