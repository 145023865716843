import axios from "axios";

// const coursesUrl = `${process.env.REACT_APP_API_URL}/exercise/tags`
const blocksUrl = `${process.env.REACT_APP_API_URL}/block-settings`;
const courseUrl = `${process.env.REACT_APP_API_URL}/course`;
const patientUrl = `${process.env.REACT_APP_API_URL}/patients`;
const classUrl = `${process.env.REACT_APP_API_URL}/export/v2/classes`;
const exportUrl = `${process.env.REACT_APP_API_URL}/export/v2`;

export function getBlocks() {
  return axios.get(blocksUrl);
}

export function getLatestDraft() {
  return axios.get(exportUrl);
}

export function setBlocks(payload) {
  return axios.post(blocksUrl, payload);
}

export function getCurrentBlock() {
  return axios.get(`${courseUrl}/class-ids/current-block`);
}

export function getClassPatients(id) {
  return axios.get(`${courseUrl}/get-patients/${id}`);
}

export function getDraft(next_block_id) {
  return axios.get(`${courseUrl}/draft/${next_block_id}`);
}

export function saveDraft(payload) {
  return axios.post(`${classUrl}/patients`, payload);
}

export function patientSearch(key) {
  return axios.post(`${patientUrl}/search`, {
    keyword: key,
  });
}

export function exportToMindBody(params) {
  return axios.post(`${classUrl}/export-from-draft/mindbody`, params);
}

// course builder v2

export function getClasses() {
  return axios.get(classUrl);
}

export function getPatiensByClassId(cls) {
  return axios.get(
    `${classUrl}/patients/${cls.class_id}`,
    {
      params: {
        sd: cls.start_date,
        st: cls.start_time,
        et: cls.end_time,
        pid: cls.staff_id,
      },
    },
    {
      retry: 5,
      retryDelay: 1000,
    }
  );
}

export function verifyClass(schedule_id, class_id, next_block_id, lockdownUid) {
  return axios.get(
    `${exportUrl}/verify/${schedule_id}/${class_id}/${lockdownUid}`,
    {
      // return axios.get(`${exportUrl}/verify/547/31912`, {
      next_block_id: next_block_id,
    }
  );
}

export function getClassesToExport() {
  return axios.get(`${exportUrl}/start`);
}

export function exportByDraftId(id, lockdownUid) {
  return axios.post(`${exportUrl}/draft/${id}/${lockdownUid}`);
}

export function resetDraft() {
  return axios.delete(`${exportUrl}/reset-course-drafts`);
}

export function getExportsList() {
  return axios.get(`${exportUrl}/previous/records`);
}

export function getPreviousExports() {
  return axios.get(`${exportUrl}/previous/records`);
}

export function getPreviousExport(block_id) {
  return axios.get(`${exportUrl}/previous/record/${block_id}`);
}

export function startLockDown() {
  return axios.get(`${exportUrl}/start-lockdown`);
}

export function endLockDown() {
  return axios.delete(`${exportUrl}/end-lockdown`);
}
