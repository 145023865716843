/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { styled } from "@material-ui/core";
import { filterLinksByAccess } from "../../../../../app/modules/Other/utils/navigationLinks";
import { useSelector } from "react-redux";

const StyledSVG = styled(SVG)({
  "& :hover": {
    fill: "black",
  },
});

const svgtest = {
  fill: "currentColor",
  path:
    "M187.691 291C254.644 277.267 305 218.014 305 147C305 91.423 274.158 43.0496 228.655 18.0622C227.725 19.1413 226.782 20.2067 225.825 21.2582C203.145 46.1862 173.043 63.2984 139.851 69.9762C106.658 76.654 72.2811 72.5136 41.7254 58.2958C41.4902 58.1864 41.2553 58.0763 41.0205 57.9657C31.2418 70.794 23.5251 85.279 18.3651 100.926C48.2488 123.375 85.4438 133.98 122.764 130.595C161.444 127.086 197.288 108.818 222.855 79.5822L222.875 79.5591L228.447 84.43C226.466 86.696 224.427 88.8991 222.331 91.0379C196.055 117.859 161.021 134.556 123.433 137.966C85.8445 141.375 48.3776 131.255 17.704 109.6C17.169 109.222 16.636 108.841 16.1051 108.456C12.7762 120.74 11 133.663 11 147C11 154.912 11.6251 162.678 12.8286 170.252C29.1237 186.06 48.763 198.041 70.321 205.295C93.4859 213.09 118.186 215.196 142.336 211.437C166.487 207.678 189.378 198.163 209.078 183.697C228.777 169.23 244.707 150.236 255.523 128.318L261.043 131.042L261.036 131.055C259.708 133.746 258.305 136.396 256.831 139C245.817 158.452 230.794 175.386 212.721 188.658C192.225 203.709 168.409 213.608 143.283 217.519C118.157 221.43 92.4589 219.238 68.358 211.129C48.6317 204.492 30.4495 194.049 14.8203 180.44C20.2669 203.853 31.3082 225.118 46.4585 242.751C64.1444 256.025 84.5318 265.306 106.214 269.917C130.121 275.001 154.899 274.259 178.458 267.753C202.018 261.247 223.666 249.168 241.575 232.536C259.484 215.904 273.129 195.207 281.357 172.193L287.153 174.265C286.141 177.096 285.05 179.893 283.882 182.653C275.174 203.242 262.194 221.788 245.764 237.046C227.131 254.35 204.608 266.917 180.097 273.686C155.586 280.455 129.806 281.227 104.934 275.938C91.993 273.186 79.4956 268.835 67.7179 263.018C85.1868 276.631 105.811 286.385 128.309 291H0V300H317V291H187.691ZM158 0C180.893 0 202.564 5.23325 221.882 14.5687C200.093 39.2595 170.786 56.2031 138.391 62.7205C107.092 69.0174 74.6879 65.224 45.7976 52.0236C72.7621 20.2003 113.022 0 158 0Z",
  width: "317",
  xmlns: "http://www.w3.org/2000/svg",
  height: "300",
  viewBox: "0 0 305 513",
};

export function AsideMenuList({ layoutProps }) {
  const { access } = useSelector((state) => state.auth.user);
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const filteredLinks = filterLinksByAccess(access);

  return (
    <div className="container">
      {/* begin::Menu Nav */}
      <ul
        className={`menu-nav ${layoutProps.ulClasses} mx-auto justify-content-center`}
      >
        {filteredLinks.map((link) =>
          link.dropdown ? (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                link.link,
                true
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link menu-toggle" to={link.link}>
                <span className="svg-icon menu-icon">
                  <SVG src={link.icon} fill="currentColor" />
                </span>
                <span className="menu-text">{link.name}</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  {link.subMenus.map((sub) => (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        sub.link,
                        false
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to={sub.link}>
                        {/* <i className="menu-bullet">
                          <span />
                        </i> */}
                        <span className="menu-text">{sub.name}</span>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          ) : (
            <li
              className={`menu-item ${getMenuItemActive(link.link, false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to={link.link}>
                <span className="svg-icon menu-icon">
                  <SVG src={link.icon} fill="currentColor" />
                </span>
                <span className="menu-text">{link.name}</span>
              </NavLink>
            </li>
          )
        )}
      </ul>

      {/* end::Menu Nav */}
    </div>
  );
}
