/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";
import { filterLinksByAccess } from "../../../../../app/modules/Other/utils/navigationLinks";

export function HeaderMenu({ layoutProps }) {
  const { access } = useSelector((state) => state.auth.user);
  const filteredLinks = filterLinksByAccess(access);
  const [headerName, setHeaderName] = useState();

  const location = useLocation();

  useEffect(() => {
    let loc_name = location.pathname;
    let headerName = null;

    filteredLinks.map((filteredLink) => {
      if (filteredLink.subMenus) {
        const found = filteredLink.subMenus.find(
          (submenu) => submenu.link == loc_name
        );
        if (found) {
          headerName = found.name;
        }

        filteredLink.subMenus.map((subMenu) => {
          if (subMenu.subLinks) {
            const found = subMenu.subLinks.find(
              (submenu) => submenu.link == loc_name
            );
            if (found) {
              headerName = found.name;
            }
          }
        });
      }
    });

    if (headerName) {
      setHeaderName(headerName);
    } else {
      filteredLinks.map((filteredLink) => {
        if (loc_name.includes(filteredLink.link)) {
          setHeaderName(filteredLink.name);
          // return;
        }
      });
    }
  }, [location]);

  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/dashboard"
          )}`}
        >
          <h3 className="kt-margin-t-20">{headerName}</h3>
        </li>
        {/*end::1 Level*/}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
