import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
// import * as courses from "../app/modules/Courses/_redux/courseRedux";
import { coursesSlice } from "../app/modules/Courses/_redux/coursesSlice";
import { exerciseLibrarySlice } from "../app/modules/Exercises/_redux/exercises/exerciseLibrarySlice";
import { userSlice } from "../app/modules/User/_redux/userSlice";
import { permissionSlice } from "../app/modules/Permissions/_redux/presmissionSlice";
import { holidaySlice } from "../app/modules/Courses/_redux/holiday/holidaySlice";
import { clinicalRehabSlice } from "../app/modules/ClinicalRehab/_redux/clincalRehabSlice";
import { exercisePlanSlice } from "../app/modules/ExercisePlans/_redux/exercisePlanSlice";
import { templateSlice } from "../app/modules/ClassPlans/Templates/_redux/templateSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,

  // exercise
  exerciseLibrary: exerciseLibrarySlice.reducer,
  // courses
  courses: coursesSlice.reducer,
  // clinical rehab
  clinicalRehab: clinicalRehabSlice.reducer,
  exercisePlans: exercisePlanSlice.reducer,
  holidays: holidaySlice.reducer,
  // users
  users: userSlice.reducer,
  permissions: permissionSlice.reducer,
  classTemplates: templateSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
