import React, { useState, useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import ViewHolidays from "./holiday-modal/ViewHolidays";
import EditHolidays from "./holiday-modal/EditHolidays";
import HolidaysDialog from "./dialog-bars/HolidaysDialog";
import moment from "moment";
import { useSelector } from "react-redux";

const HolidaysModal = (props) => {
  const { list, isSuccess } = useSelector((state) => state.holidays);
  const [editDates, setEditDates] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [separatedHolidayYears, setSeparatedHolidayYears] = useState([]);
  const newHoliday = {
    name: "",
    holiday_date: null,
  };

  useEffect(() => {
    if (list) {
      if (list.length == 0) {
        setHolidays([newHoliday]);
      } else {
        setHolidays(list);
      }
      separateYears(list);
    }

    isSuccess && setEditDates(false);
  }, [list, isSuccess]);

  const handleEditDates = () => {
    setEditDates(true);
  };

  const handleCancelEdit = () => {
    setHolidays(list);
    setEditDates(false);
  };

  const handleHide = () => {
    props.onHide();
    setTimeout(() => {
      setEditDates(false);
    }, 500);
  };

  const handleNewHoliday = () => {
    setHolidays([...holidays, newHoliday]);
  };

  const handleInputChange = (e, index) => {
    const val = e.target.value;

    let newHoliday = holidays.map((holiday, idx) => {
      if (idx == index) {
        return { ...holiday, name: val };
      }

      return holiday;
    });

    setHolidays(newHoliday);
  };

  const separateYears = (holidays) => {
    let holidayYears = [];
    holidays.map((holiday) => {
      let eventYear = moment(holiday.holiday_date + " 00:00:00").year();
      // console.log(eventYear, holiday);
      let newHoliday = {
        year: eventYear,
        holidays: [],
      };
      let find_holiday = holidayYears.find(
        (holiday) => holiday.year == eventYear
      );

      if (find_holiday) {
        find_holiday.holidays = [...find_holiday.holidays, holiday];
      } else {
        newHoliday.holidays = [...newHoliday.holidays, holiday];
        holidayYears = [...holidayYears, newHoliday];
      }
    });
    setSeparatedHolidayYears(holidayYears);
  };

  const handleDateChange = (newDate, index) => {
    const val = moment(newDate).format("YYYY-MM-DD");

    let newHoliday = holidays.map((holiday, idx) => {
      if (idx == index) {
        return { ...holiday, holiday_date: moment(val)._i };
      }

      return holiday;
    });

    setHolidays(newHoliday);
  };

  const handleRemoveHoliday = (e, index) => {
    let newHolidays = [];
    holidays.map((holiday, idx) => {
      if (idx !== index) {
        newHolidays = [...newHolidays, holiday];
      }
    });
    if (holidays.length == 1) {
      newHolidays = [...newHolidays, newHoliday];
    }
    setHolidays(newHolidays);
  };

  return (
    <Modal
      // {...props}
      show={props.show}
      onHide={handleHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-90w"
      backdrop="static"
    >
      <HolidaysDialog />
      <Modal.Header>
        <h3 className="m-auto">Public holidays</h3>
        <a href="javascript:void(0)" onClick={handleHide}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
        </a>
      </Modal.Header>
      <Modal.Body>
        {editDates ? (
          <EditHolidays
            holidays={holidays}
            handleCancelEdit={handleCancelEdit}
            handleInputChange={handleInputChange}
            handleDateChange={handleDateChange}
            handleNewHoliday={handleNewHoliday}
            handleRemoveHoliday={handleRemoveHoliday}
          />
        ) : (
          <ViewHolidays
            list={list}
            holidays={holidays}
            separatedHolidayYears={separatedHolidayYears}
            handleEditDates={handleEditDates}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default HolidaysModal;
