import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, Spinner } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { archiveExercise } from "../../_redux/exercises/exerciseLibraryActions";

const ArchiveExerciseDialog = (props) => {
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.exerciseLibrary);
  const [open, setOpen] = useState(false);

  const { actionsLoading, isSuccess } = useSelector(
    (state) => state.exerciseLibrary
  );

  // function handlePublishExercises() {
  //   setArchiveDialog(true);
  // }

  function handleClose() {
    props.setArchiveDialog(false);
  }

  function handleConfirm() {
    dispatch(archiveExercise(props.exercise_id));
  }

  // auto close if success

  useEffect(() => {
    handleClose();
  }, [isSuccess]);

  return (
    <Dialog
      open={props.archiveDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="text-center">
        {"Archive Exercise"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <p>Are you sure you want to publish archive this exercise?</p>
          <p>
            You can reactivate an exercise from the archived list at any time.
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="d-flex justify-content-center p-5">
        {/* <Button
          onClick={handleClose}
          color="secondary"
          className="rounded-pill w-25"
        >
          Cancel
        </Button> */}
        <button
          className="btn btn-secondary rounded-pill w-25 text-primary"
          onClick={handleClose}
        >
          Cancel
        </button>
        <Button
          disabled={actionsLoading}
          variant="primary"
          onClick={handleConfirm}
          className="rounded-pill w-25"
        >
          {actionsLoading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              style={{
                // position: "absolute",
                left: "2rem",
                top: "1rem",
              }}
              aria-hidden="true"
            />
          )}
          Archive
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArchiveExerciseDialog;
