import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import Select, { StylesConfig } from "react-select";
import {
  fetchClinicalRehabList,
  fetchWeeks,
} from "../_redux/clinicalRehabActions";
import { useDispatch, useSelector } from "react-redux";
import Filter from "../../Other/Filter";
import DateRangePickerModal from "../../Other/DateRangePickerModal";
import moment from "moment";

const TableFilters = ({
  handleFilters,
  handleClearFilters,
  selectedFilters,
}) => {
  const dispatch = useDispatch();
  const { filters, listLoading, activeWeek } = useSelector(
    (state) => state.clinicalRehab
  );
  const [dateRangeModal, setDateRangeModal] = useState(false);
  let physioSeletRef = useRef(null);
  let locationSelectRef = useRef(null);
  let statusSelectRef = useRef(null);
  const [dateRange, setDateRange] = useState({
    label: "this_course",
    value: "this_course",
  });

  const initDates = {
    from: null,
    to: null,
  };

  const [dates, setDates] = useState(initDates);

  const options = [
    // {
    //   label: "today",
    //   value: "today",
    // },
    // {
    //   label: "tomorrow",
    //   value: "tomorrow",
    // },
    // {
    //   label: "last week",
    //   value: "last_week",
    // },
    // {
    //   label: "this week",
    //   value: "this_week",
    // },
    // {
    //   label: "next week",
    //   value: "next_week",
    // },
    // {
    //   label: "this month",
    //   value: "this_month",
    // },
    // {
    //   label: "next month",
    //   value: "next_month",
    // },
    {
      label: "this course",
      value: "this_course",
    },
    {
      label: "next course",
      value: "next_course",
    },
    // {
    //   label: "custom",
    //   value: "custom",
    // },
  ];
  // const optionsArrayString = (options) => {
  //   let opts = [];
  //   options &&
  //     options.map((option) => {
  //       option = {
  //         label: option,
  //         value: option,
  //       };
  //       opts = [...opts, option];
  //     });

  //   return opts;
  // };

  const colourStyles: StylesConfig<ColourOption, true> = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        ":active": {
          backgroundColor: "#ffe5e5",
        },
        ":focus": {
          backgroundColor: "#ffe5e5",
        },
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        padding: "3px",
        backgroundColor: "#ffe5e5",
      };
    },
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#ff5764 ",
        color: "white",
      },
    }),
  };

  const handleSelectChange = (e) => {
    if (e.value !== "custom") {
      setDates(initDates);
    }
    setDateRange(e);
  };

  const handleSearch = (val, target) => {
    handleFilters(val, target);
  };

  const clearFilters = () => {};

  useEffect(() => {
    // console.log(dates);
    if (dateRange.value == "custom") {
      setDateRangeModal(true);
    } else {
      dispatch(fetchWeeks(dateRange.value));
    }
  }, [dateRange]);

  useEffect(() => {
    if (activeWeek !== null) {
      // console.log(physioSeletRef.current);
      physioSeletRef.current.select.clearValue();
      locationSelectRef.current.select.clearValue();
      statusSelectRef.current.select.clearValue();
    }
  }, [activeWeek]);

  useEffect(() => {
    activeWeek && dispatch(fetchClinicalRehabList(activeWeek));
  }, [activeWeek]);

  const optionsArrayString = (options) => {
    let opts = [];
    options &&
      options.map((option) => {
        option = {
          label: option,
          value: option,
        };
        opts = [...opts, option];
      });

    return opts;
  };

  const handleCustomDateConfirm = (e) => {
    const { startDate, endDate } = e;

    const sDate = moment(startDate).format("YYYY-MM-DD");
    const eDate = moment(endDate).format("YYYY-MM-DD");

    setDates({
      from: sDate,
      to: eDate,
    });

    setDateRangeModal(false);

    const customDates = {
      from: sDate,
      to: eDate,
    };

    dispatch(fetchClinicalRehabList(dateRange.value, customDates));
  };

  return (
    <>
      <DateRangePickerModal
        show={dateRangeModal}
        onHide={() => setDateRangeModal(false)}
        handleCustomDateConfirm={handleCustomDateConfirm}
      />
      <Row>
        <Col lg={12}>
          <Filter
            disabled={listLoading}
            handleSearch={(e) => handleSearch(e, "key")}
            clearSearch={listLoading && activeWeek !== null}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Row>
            <Col lg={3}>
              <Select
                name="date_range"
                isDisabled={listLoading}
                // className="w-50"
                // value={dateRange}
                onChange={handleSelectChange}
                options={options}
                defaultValue={options[0]}
                // getOptionLabel={(option) => `${option.label}`}
                // getOptionValue={(option) => `${option.label}`}
                aria-labelledby="aria-label"
                inputId="aria-example-input"
                placeholder="Date range"
                styles={colourStyles}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#ffe5e5",
                    primary: "#ff8a93",
                  },
                })}
              />
            </Col>
            <Col lg={6} className="d-flex align-items-center">
              {dates.from !== null && dates.to !== null && (
                <button
                  className="btn btn-primary"
                  onClick={() => setDateRangeModal(true)}
                >
                  {dates.from && moment(dates.from).format("MMM DD, YYYY")}
                  {" - "}
                  {dates.to && moment(dates.to).format("MMM DD, YYYY")}
                </button>
              )}
            </Col>
          </Row>
        </Col>
        <Col lg={6}>
          <Row>
            {/* <Col lg={3} className="d-flex align-items-end justify-content-end">
              <a href="javascript:void(0)" onClick={handleClearFilters}>
                Clear filters
              </a>
            </Col> */}
            <Col lg={4}>
              <Select
                ref={physioSeletRef}
                isDisabled={listLoading}
                isClearable={true}
                onChange={(e) => handleSearch(e?.name ?? "", "physio")}
                placeholder="Physio"
                styles={colourStyles}
                options={filters.physio}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => option.id}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#ffe5e5",
                    primary: "#ff8a93",
                  },
                })}
              />
            </Col>
            <Col lg={4}>
              <Select
                ref={locationSelectRef}
                isDisabled={listLoading}
                isClearable={true}
                onChange={(e) => handleSearch(e?.value ?? "", "studio")}
                placeholder="Location"
                options={optionsArrayString(filters.location)}
                styles={colourStyles}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#ffe5e5",
                    primary: "#ff8a93",
                  },
                })}
              />
            </Col>
            <Col lg={4}>
              <Select
                ref={statusSelectRef}
                isDisabled={listLoading}
                isClearable={true}
                onChange={(e) => handleSearch(e?.value ?? "", "status")}
                placeholder="Status"
                options={optionsArrayString(filters.status)}
                styles={colourStyles}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#ffe5e5",
                    primary: "#ff8a93",
                  },
                })}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default TableFilters;
