import axios from "axios";

const exercisePlansUrl = `${process.env.REACT_APP_API_URL}/clinical-rehab`;
const exercisesUrl = `${process.env.REACT_APP_API_URL}/exercise`;
// const exercisePlansUrl = `${process.env.REACT_APP_API_URL}/exercise-plans`;

export function getExercisePlans(schedule_id, class_id) {
  return axios.get(`${exercisePlansUrl}/data`, {
    params: {
      schedule_id: schedule_id,
      class_id: class_id,
    },
  });
}

export function getPatientExercises(patient_id, schedule_id, class_id) {
  return axios.get(`${exercisePlansUrl}/patient/exercises/v3`, {
    params: {
      patient_id: patient_id,
      schedule_id: schedule_id,
      class_id: class_id,
    },
  });
}

// search exercises using body id
export function searchExercise(parameters) {
  return axios.get(`${exercisesUrl}/search`, {
    params: parameters,
  });
}

export function getFilters() {
  return axios.get(`${exercisesUrl}/search-filters`);
}

export function saveExercisesDraft(payload) {
  return axios.post(`${exercisePlansUrl}/patient/v2/save-exercises`, payload);
}

export function publishPatientExercises(payload) {
  return axios.post(`${exercisePlansUrl}/patient/exercises/publish`, payload);
}

export function getProps(variation_id, patient_exercise_id) {
  return axios.get(`${exercisePlansUrl}/exercise-variation/props`, {
    params: {
      variation_id: variation_id,
      patient_exercise_id: patient_exercise_id,
    },
  });
}
