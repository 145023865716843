import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
// import {Step1,Step2,Step3} from ''
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

const AddExerciseModal = (props) => {
  const dispatch = useDispatch();
  const { isSuccess, isError } = useSelector((state) => state.exercisePlans);
  const [step, setStep] = useState(1);
  const [newExercises, setNewExercises] = useState([]);
  const [searchedExercises, setSearchedExercises] = useState([]);

  const handleNextStep = () => {
    step !== 3 && setStep((step) => step + 1);
  };
  const handleBack = () => {
    step !== 1 && setStep((step) => step - 1);
  };

  const handleSave = (selectedExercises) => {
    // const payload = {
    //   schedule_id: props.schedule_id,
    //   patient_id: props.patient_id,
    //   exercises: selectedExercises,
    // };
    // console.log("saving");
    // console.log("selectedExercises");
    // console.log(selectedExercises);
    props.addExercises(selectedExercises);
    // dispatch(addToPatientExercises(payload));
  };

  useEffect(() => {
    if (isSuccess) {
      setStep(1);
      props.onHide();
    }
  }, [isSuccess, isError]);

  return (
    <Modal {...props} size={step == 1 ? `lg` : "md"} centered>
      <Modal.Header>
        {step > 1 && (
          <a href="javascript:void(0)" onClick={handleBack}>
            <SVG
              className="h-75 align-self-end"
              src={toAbsoluteUrl("/media/svg/icons/angle-left.svg")}
            />
            Back
          </a>
        )}
        <h3 className="m-auto">Add exercise</h3>
        <a href="javascript:void(0)" onClick={() => props.onHide()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
        </a>
      </Modal.Header>
      <Modal.Body style={{ minHeight: "650px" }}>
        {step == 1 ? (
          <Step1
            handleNextStep={handleNextStep}
            handleBack={handleBack}
            setSearchedExercises={setSearchedExercises}
            {...props}
          />
        ) : step == 2 ? (
          <Step2
            searchedExercises={searchedExercises}
            handleSave={handleSave}
            handleBack={handleBack}
            {...props}
          />
        ) : step == 3 ? (
          ""
        ) : (
          //   <Step3
          //     handleNextStep={handleNextStep}
          //     handleBack={handleBack}
          //     {...props}
          //   />
          ""
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddExerciseModal;
