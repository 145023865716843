import React, { useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { InputGroup, FormControl } from "react-bootstrap";

const Filter = ({ handleSearch, disabled = false, clearSearch = false }) => {
  let searchInputRef = useRef("");

  useEffect(() => {
    if (clearSearch) {
      searchInputRef.current.value = "";
    }
  }, [clearSearch]);

  return (
    <Grid container justify="flex-end">
      <div>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text className="border-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            ref={searchInputRef}
            disabled={disabled}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
            placeholder="Search"
            className="border-0"
            style={{ backgroundColor: "#f2f4f8" }}
          />
        </InputGroup>
      </div>
    </Grid>
  );
};

export default Filter;
