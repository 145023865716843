import { holidaySlice } from "./holidaySlice";
import moment from "moment";
import * as requestFromServer from "./holidaysCrud";

const { actions } = holidaySlice;

export const fetchHolidays = () => (dispatch) => {
  dispatch(actions.startCall({ type: "listLoading" }));

  requestFromServer
    .getHolidays()
    .then((res) => {
      const { holidays } = res.data;
      const sortedByDate = holidays.sort(
        (a, b) =>
          moment(a.holiday_date + " 00:00:00") -
          moment(b.holiday_date + " 00:00:00")
      );
      // console.log(sortedByDate);
      dispatch(actions.holidayListFetched(sortedByDate));
    })
    .catch((err) => {
      console.log(err);
    });
  // const holidaysData = [
  //   {
  //     event: "Holden new car show",
  //     eventDate: "2024-03-01",
  //   },
  //   {
  //     event: "Holiday 1",
  //     eventDate: "2023-02-23",
  //   },
  //   {
  //     event: "Holiday 2",
  //     eventDate: "2023-02-24",
  //   },
  //   {
  //     event: "Bayani Party",
  //     eventDate: "2023-03-01",
  //   },
  //   {
  //     event: "Bayani Boxing Day",
  //     eventDate: "2023-05-03",
  //   },
  //   {
  //     event: "Bayani's car show",
  //     eventDate: "2023-05-04",
  //   },
  //   {
  //     event: "Bayani's cooking show",
  //     eventDate: "2023-05-20",
  //   },
  //   {
  //     event: "Bayani's training",
  //     eventDate: "2023-06-06",
  //   },
  //   {
  //     event: "Holiday 77",
  //     eventDate: "2024-02-23",
  //   },
  //   {
  //     event: "Holddd",
  //     eventDate: "2024-02-24",
  //   },
  // ];
  // const sortedByDate = holidaysData.sort(
  //   (a, b) =>
  //     moment(a.eventDate + " 00:00:00") - moment(b.eventDate + " 00:00:00")
  // );
  // // console.log(sortedByDate);
  // dispatch(actions.holidayListFetched(sortedByDate));
};

export const saveHolidays = (holidayData) => (dispatch) => {
  // console.log(holidayData);
  dispatch(actions.startCall({ loadingType: "saveLoading" }));
  requestFromServer
    .saveHolidays(holidayData)
    .then((res) => {
      dispatch(actions.holidayListSaved(res.data));
    })
    .catch((err) => {
      dispatch(actions.catchError(err.response.data));
    });
  // setTimeout(() => {
  // }, 1000);
};

export const statusReset = () => (dispatch) => {
  dispatch(actions.statusReset());
};
