import React, { forwardRef, useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";

const HolidayRow = ({
  handleInputChange,
  handleDateChange,
  holiday,
  handleRemoveHoliday,
}) => {
  const [holidayDate, setHolidayDate] = useState(null);
  const CustomDatePicker = forwardRef(({ value, onClick }, ref) => (
    <button className="btn btn-primary btn-sm" onClick={onClick} ref={ref}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-calendar-range"
        viewBox="0 0 16 16"
      >
        <path d="M9 7a1 1 0 0 1 1-1h5v2h-5a1 1 0 0 1-1-1zM1 9h4a1 1 0 0 1 0 2H1V9z" />
        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
      </svg>
    </button>
  ));

  useEffect(() => {
    holiday.holiday_date && setHolidayDate(moment(holiday.holiday_date)._d);
  }, [holiday]);

  return (
    <Row className="mb-3">
      <Col lg={6}>
        <Form.Control
          type="text"
          value={holiday.name}
          onChange={handleInputChange}
        />
      </Col>
      <Col className="d-flex justify-content-center align-items-center" lg={3}>
        <span>
          {holiday.holiday_date &&
            moment(holiday.holiday_date).format("DD/MM/YYYY")}
        </span>
      </Col>
      <Col lg={3} className="d-flex justify-content-between align-items-center">
        <DatePicker
          popperProps={{
            positionFixed: true,
          }}
          selected={
            holiday.holiday_date ? moment(holiday.holiday_date)._d : null
          }
          onChange={(date) => handleDateChange(date)}
          customInput={<CustomDatePicker />}
        />
        {/* <button className="btn btn-xs btn-danger"> */}
        <a href="javascript:void(0)" onClick={handleRemoveHoliday}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-dash-circle"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
          </svg>
        </a>
        {/* </button> */}
        {/* <button className="btn btn-primary btn-sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-calendar-range"
            viewBox="0 0 16 16"
          >
            <path d="M9 7a1 1 0 0 1 1-1h5v2h-5a1 1 0 0 1-1-1zM1 9h4a1 1 0 0 1 0 2H1V9z" />
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
          </svg>
        </button> */}
      </Col>
    </Row>
  );
};

export default HolidayRow;
