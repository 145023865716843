import React, { useState, useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { LinearProgress, Box, Typography, makeStyles } from "@material-ui/core";
import { statusReset } from "../_redux/coursesActions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles({
  root: {
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#ff8a93",
    },
  },
});

function LinearProgressWithLabel(props) {
  const classes = useStyles();
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          className="bg-secondary"
          classes={{
            root: classes.root,
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          color="text.primary"
          className="text-center"
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const VerificationModal = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState();
  const {
    isVerified,
    verifyLoadingFinish,
    verificationMessage,
    verificationErrors,
    verifyLoading,
    verifyLoadingProgress,
    requestConflict,
  } = useSelector((state) => state.courses);

  const handleExport = () => {
    setOpen(false);
    props.handleExport();
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      dispatch(statusReset());
    }, 500);
  };

  useEffect(() => {
    if (verifyLoading == true) {
      setOpen(true);
    }
    if (requestConflict == true) {
      setOpen(false);
    }
  }, [verifyLoading, requestConflict]);

  // useEffect(() => {
  //   console.log(open);
  // }, [open]);

  return (
    !requestConflict && (
      <Modal
        show={open}
        onHide={() => handleClose()}
        size={verifyLoading || isVerified ? `md` : `lg`}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-50w"
        centered
        backdrop="static"
      >
        <Modal.Body>
          {verifyLoading ? (
            <>
              <Row className="d-flex justify-content-center">
                {/* <Col lg={9}> */}
                <span>Verifying</span>
                {/* </Col> */}
              </Row>
              <Row className="my-5">
                <Col lg={12}>
                  <Box sx={{ width: "100%" }}>
                    <LinearProgressWithLabel value={verifyLoadingProgress} />
                  </Box>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row className="d-flex justify-content-center p-5">
                <Col lg={12} className="mb-5 text-center">
                  {verificationMessage}
                </Col>
                <div>
                  {/* The below classes has patients in mindbody already and export cannot
          be completed */}
                  {verificationErrors &&
                    verificationErrors.map((e) => (
                      // <Row className="mb-5 border">
                      <Col lg={12} className="mb-5 border shadow">
                        <Row className="my-5">
                          <Col
                            lg={3}
                            className="d-flex align-items-center font-weight-bold"
                          >
                            {/* {e.classData.class_type} {e.classData.studio} */}
                            Class trying to export:
                          </Col>
                          <Col
                            lg={9}
                            className="d-flex align-items-center font-weight-bold"
                          >
                            {/* {e.classData.start_date}, {e.classData.time} */}
                            {/* {e.classData.class_type} {e.classData.studio} */}
                            {e.class_trying_to_export}
                          </Col>
                          {/* <Col
                          lg={4}
                          className="d-flex align-items-center font-weight-bold"
                        >
                          {e.classData.start_date}, {e.classData.time}
                          {e.message}
                        </Col> */}
                        </Row>
                        {e.message !== "" && (
                          <Row className="mb-5">
                            <Col lg={11} className="p-3 border m-auto">
                              {/* <Row> */}
                              <span className="font-weight-bold">Error: </span>
                              <span className="text-danger text-danger">
                                {e.message}
                              </span>
                              {/* </Row> */}
                            </Col>
                            {/* <Col lg={11} className="text-danger">
                          </Col> */}
                          </Row>
                        )}
                        <Row>
                          <Col lg={12} className="mb-3">
                            {e.data?.map(
                              (error) =>
                                // <li>
                                typeof error === "object" ? (
                                  // <span>{error.error_type}: </span>
                                  <Row className="mb-3">
                                    <Col lg={11} className="p-3 border m-auto">
                                      <>
                                        <Row>
                                          <Col lg={12}>
                                            <span className="font-weight-bold">
                                              Receiving class:{" "}
                                            </span>
                                            {
                                              error.class_and_patients
                                                ?.receiving_class
                                            }
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col lg={12}>
                                            <span className="font-weight-bold">
                                              Error:{" "}
                                            </span>
                                            <span className="text-danger">
                                              {error.error}
                                            </span>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col lg={12}>
                                            <span className="font-weight-bold">
                                              Resolution:{" "}
                                            </span>
                                            {error.resolution}
                                          </Col>
                                        </Row>
                                      </>

                                      {/* <Row>
                                      <Col lg={12}>
                                        <span className="font-weight-bold">
                                          Error:{" "}
                                        </span>
                                        <span className="text-danger">
                                          {error.message}
                                        </span>{" "}
                                      </Col>
                                    </Row> */}
                                      <Row>
                                        {error.error_type ==
                                          "patient_not_match" && (
                                          <>
                                            <Col lg={6}>
                                              <span className="font-weight-bold">
                                                Existing mindbody patient:{" "}
                                              </span>
                                              <ul>
                                                {error.class_and_patients.patients.existing_mindbody_patients.map(
                                                  (patient) => (
                                                    <li>{patient.name}</li>
                                                  )
                                                )}
                                              </ul>
                                            </Col>
                                            <Col lg={6}>
                                              <span className="font-weight-bold">
                                                Draft patients:{" "}
                                              </span>
                                              <ul>
                                                {error.class_and_patients.patients.draft_patients.map(
                                                  (patient) => (
                                                    <Col lg={12}>
                                                      <li>{patient.name}</li>
                                                    </Col>
                                                  )
                                                )}
                                              </ul>
                                            </Col>
                                          </>
                                        )}
                                      </Row>
                                    </Col>
                                  </Row>
                                ) : (
                                  // <li>{error}</li>
                                  <Col lg={6} className="m-auto">
                                    {error}
                                  </Col>
                                )
                              // </li>
                            )}
                            {/* <li></li> */}
                            {/* </ul> */}
                          </Col>
                        </Row>
                      </Col>
                      // </Row>
                    ))}
                </div>
              </Row>
              {verificationErrors.length == 0 ? (
                <Row className="d-flex justify-content-center">
                  <Col lg={11}>
                    <Row className="justify-content-center">
                      <Col lg={6}>
                        <button
                          className="btn btn-secondary btn-md rounded-pill w-100 text-primary"
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          Back to courses
                        </button>
                      </Col>
                      <Col lg={6}>
                        <button
                          className="btn btn-primary btn-md rounded-pill w-100"
                          onClick={() => {
                            handleExport();
                          }}
                        >
                          Export to Mindbody
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col lg={11} className="d-flex justify-content-center">
                    <button
                      className="btn btn-secondary btn-md rounded-pill text-primary"
                      onClick={handleClose}
                    >
                      Ok, go back
                    </button>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    )
  );
};

export default VerificationModal;
