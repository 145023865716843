import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";

const NoteFiles = ({ files }) => {
  const [nFiles, setNFiles] = useState([]);

  useEffect(() => {
    let newFiles = [];
    files.map((file) => {
      file.map((nFile) => {
        if (nFile.image_key) {
          const newFile = {
            type: "image",
            key: nFile.image_key,
          };

          newFiles = [...newFiles, newFile];
        }

        if (nFile.video_key) {
          const newFile = {
            type: "video",
            key: nFile.video_key,
          };

          newFiles = [...newFiles, newFile];
        }
      });
    });

    setNFiles(newFiles);
  }, []);

  return (
    <>
      {nFiles.map((nFIle) => {
        if (nFIle.type == "image") {
          return (
            <Col lg={12} className="my-5">
              <img
                src={`${process.env.REACT_APP_API_URL}/patient-exercises/image-video/view-image/${nFIle.key}`}
                width="100%"
              />
            </Col>
          );
        }
        if (nFIle.type == "video") {
          return (
            <Col lg={12} className="my-5">
              <video controls width="100%">
                <source
                  src={`${process.env.REACT_APP_API_URL}/patient-exercises/image-video/view-video/${nFIle.key}`}
                  type="video/mp4"
                />
              </video>
            </Col>
          );
        }
      })}
    </>
  );
};

export default NoteFiles;
