import React, { useEffect, useState } from "react";
//ui
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { Button } from "react-bootstrap";

//components
import UsersTable from "./components/UsersTable";
import Filter from "../Other/Filter";
import UserDialog from "./components/dialog-bars/UserDialog";

//
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "./_redux/userActions";

const UserPage = () => {
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.users);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  useEffect(() => {
    setUsers(list);
  }, [list]);

  const handleSearch = (key) => {
    const filteredList = list.filter((row) => {
      let fullname = `${row.first_name} ${row.last_name}`;
      if (fullname.toLowerCase().includes(key.toLowerCase())) {
        return row;
      }
      if (row.email.toLowerCase().includes(key.toLowerCase())) {
        return row;
      }
      if (row.access.toLowerCase().includes(key.toLowerCase())) {
        return row;
      }
    });
    setUsers(filteredList);
  };

  return (
    <Grid container spacing={1}>
      <UserDialog />
      <Grid item xs={6}>
        <Link component={RouterLink} to="/users/add" color="inherit">
          <Button variant="primary" size="medium" className="rounded-pill">
            + Add User
          </Button>
        </Link>
      </Grid>
      <Grid item xs={6}>
        <Filter handleSearch={handleSearch} />
      </Grid>
      <Grid item xs={12}>
        <UsersTable list={users} />
      </Grid>
    </Grid>
  );
};

export default UserPage;
