import React from "react";
import { Row, Col } from "react-bootstrap";

import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

const VariationRow = ({ variation, weeks }) => {
  return (
    <Row className="mb-3 align-items-center">
      <Col lg={2}>
        <Row>
          <Col className="text-center">
            <p className="font-italic">{variation?.name}</p>
          </Col>
        </Row>
      </Col>
      <Col lg={10}>
        <Row>
          <Col lg={10}>
            <Row>
              <Col lg={1}></Col>
              <Col lg={11}>
                <Row>
                  <Col lg={12}>
                    <Row>
                      {/* foreach weeks  */}
                      {weeks.map((i, index) => (
                        <Col>
                          {/* Wk {index + 1} */}
                          <Row>
                            <Col className="text-center">
                              {i?.is_completed ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-check-circle-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default VariationRow;
