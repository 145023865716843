import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Grid } from "@material-ui/core";

const MissingScheduleErrorModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-90w"
    >
      <Modal.Body>
        <Row className="d-flex justify-content-center">
          <Col lg={9}>
            <p className="text-center">
              There are still missing class id. Please check the drafts.
            </p>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col lg={11}>
            <Row className="justify-content-center">
              <Col lg={6}>
                <button
                  className="btn btn-primary btn-md rounded-pill w-100"
                  onClick={() => props.onHide()}
                >
                  Okay
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default MissingScheduleErrorModal;
