import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import Notes from "../components/patient-notes/Notes";
import NotesScheduleView from "../components/patient-notes/NotesScheduleView";
import CustomizedSnackbars from "../components/snackbars/ClinicalRehabSnackbars";
import { handleNoteSave } from "../_redux/clinicalRehabActions";
import { useDispatch, useSelector } from "react-redux";

const PatientNotesPage = () => {
  const dispatch = useDispatch();
  const notesRef = useRef();
  const { patientNotes, noteSaveLoading, message } = useSelector(
    (state) => state.clinicalRehab
  );
  const [disabled, setDisabled] = useState(true);

  const handleSave = () => {
    if (disabled) {
      setDisabled(false);
    } else {
      let payload = { ...patientNotes };
      let formatTags = [];
      payload.criticalNotes = payload.critical_notes;

      payload.tags.map((tag) => {
        formatTags = [
          ...formatTags,
          {
            isSelected: true,
            name: tag.value,
          },
        ];
      });

      payload.tags = formatTags;

      dispatch(handleNoteSave(payload));
    }
  };

  const handleResetEdit = () => {
    setDisabled(true); 
    if(notesRef && notesRef.current) {
      notesRef.current.click();
    }
  }

  useEffect(() => {
    if (message === "Note successfullys saved.") {
      setDisabled(true);
    }
  }, [message]);

  return (
    <div>
      <CustomizedSnackbars />
      <div className="card border-0">
        <Row className="mb-3">
          <Col>
            <h4>Patient notes</h4>
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col>
            <span style={{ fontSize: "16px" }}>{`${patientNotes?.patient_info?.patient_first_name
                ? patientNotes?.patient_info?.patient_first_name
                : ""
              } ${patientNotes?.patient_info?.patient_last_name
                ? patientNotes?.patient_info?.patient_last_name
                : ""
              }`}</span>
          </Col>
          <Col className="d-flex justify-content-end">

            <button
              className="btn btn-primary text-white rounded-pill position-relative"
              style={{ width: "150px" }}
              onClick={handleResetEdit}
              hidden={disabled}
              disabled={noteSaveLoading}
            >
              {noteSaveLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  style={{
                    position: "absolute",
                    left: "2rem",
                    top: "1rem",
                  }}
                  aria-hidden="true"
                />
              )}
              Cancel
            </button>

            <button
              className="btn btn-primary text-white rounded-pill position-relative"
              style={{ width: "150px" }}
              onClick={handleSave}
              disabled={noteSaveLoading}
            >
              {noteSaveLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  style={{
                    position: "absolute",
                    left: "2rem",
                    top: "1rem",
                  }}
                  aria-hidden="true"
                />
              )}
              {disabled ? "Edit" : "Save"}
            </button>

          </Col>
        </Row>
        <Row className="mb-3">
          <Notes disabled={disabled} notesRef={notesRef}/>
        </Row>
        <Row>
          <NotesScheduleView />
        </Row>
      </div>
    </div>
  );
};

export default PatientNotesPage;
