import axios from "axios";

const exercisePlansUrl = `${process.env.REACT_APP_API_URL}/clinical-rehab`;

export const getProps = (variation_id, patient_exercise_id) => (dispatch) => {
  //   console.log(variation_id, patient_exercise_id);
  return axios.get(`${exercisePlansUrl}/exercise-variation/props`, {
    params: {
      variation_id: variation_id,
      patient_exercise_id: patient_exercise_id,
    },
  });
};
