import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";
import VariationRow from "../variation/VariationRow";
// import {
//   addVariation,
//   handleCheckExercise,
//   handleExerciseEquipmentChange,
//   removeExercise,
//   removeExistingExercise,
// } from "../../_redux/exercisePlanActions";
import { useDispatch } from "react-redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import CustomSelect from "../../CustomSelect";
import AddVariationModal from "../variation/AddVariationModal";
import DeleteExerciseModal from "./DeleteExerciseModal";
import { Card } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

const ExerciseRow = ({
  index,
  exercise,
  patient,
  readOnly,
  provided,
  handleRemoveExercise,
  handleExerciseFieldChange,
  handleAddVariation,
  handleRemoveVariation,
  handleIntChange,
  handlePropUpdate,
}) => {
  const dispatch = useDispatch();

  const [variationModalShow, setVariationModalShow] = useState(false);
  const [deleteExerciseModal, setDeleteExerciseModal] = useState(false);
  const [defaultExercise, setDefaultExercise] = useState(null);

  const handleCheck = () => {
    // dispatch(handleCheckExercise(patient.id, exercise.uid));
  };

  const handleEquipment = (option) => {
    let val;

    if (option) {
      val = option.id;
    } else {
      val = null;
    }

    handleExerciseFieldChange(index, val);

    // console.log(option);
    // dispatch(handleExerciseEquipmentChange(patient.id, exercise.uid, option));
  };

  useEffect(() => {
    // formatSelectValue(exercise.equipment);
  }, []);

  const formatSelectValue = (option) => {
    // console.log();
    let optionType = typeof option;

    if (optionType == "number") {
      exercise.equipment_options.map((eq) => {
        if (eq.id == option) {
          console.log("default value");
          // console.log(eq);
          // return eq;
          let eqz = {
            id: eq.id,
            name: eq.name,
            label: eq.name,
            value: eq.id,
          };
          // console.log(eqz);
          setDefaultExercise(eqz);
        }
      });
    }

    setDefaultExercise(option);
  };

  const handleRemoveExerciseModal = () => {
    setDeleteExerciseModal(true);
  };

  const handleRemoveExistingExercise = () => {
    // dispatch(removeExistingExercise(patient.id, exercise.uid));
    handleRemoveExercise(index);
    setDeleteExerciseModal(false);
  };

  return (
    <Card className="mb-3 p-5 border" style={{ overflow: "visible" }}>
      {exercise?.variation_options && (
        <AddVariationModal
          exercise_index={index}
          show={variationModalShow}
          variationList={exercise?.variation_options}
          exerciseVariations={exercise?.variations}
          onHide={() => setVariationModalShow(false)}
          patient={patient}
          exercise_uid={exercise.uid}
          equipment_id={exercise?.equipment}
          handleAddVariation={handleAddVariation}
        />
      )}
      <DeleteExerciseModal
        show={deleteExerciseModal}
        onHide={() => setDeleteExerciseModal(false)}
        handleRemoveExistingExercise={handleRemoveExistingExercise}
        handleDeleteExercise={handleRemoveExistingExercise}
      />
      <Row>
        <Col
          lg={1}
          className="d-flex align-items-center justify-content-center"
        >
          {!readOnly && (
            <span {...provided.dragHandleProps}>
              <SVG
                height="3.25rem"
                width="3.25rem"
                src={toAbsoluteUrl("/media/svg/icons/six-dots.svg")}
              />
            </span>
          )}
        </Col>
        <Col lg={11}>
          <Row className="mb-3 align-items-center">
            <Col lg={3}>
              <Row>{exercise?.name}</Row>
            </Col>
            <Col lg={4}>
              <CustomSelect
                isDisabled={readOnly}
                placeholder="Select equipment"
                options={exercise?.equipment_options}
                onChange={handleEquipment}
                defaultValue={exercise?.equipment_options?.find(
                  (eq) => eq.id == exercise.equipment
                )}
                isClearable
                // defaultValue={formatSelectValue(exercise.equipment)}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option.id}`}
              />
            </Col>
            <Col lg={4}>
              <Row className="justify-content-end">
                <Link
                  target="_blank"
                  component={RouterLink}
                  color="inherit"
                  className="btn btn-primary text-white btn-sm ml-3"
                  to={`/exercises/${exercise?.exercise_id}`}
                >
                  See full exercise
                </Link>
                {/* <button className="btn btn-primary text-white btn-sm">
                  See full exercise
                </button> */}
                <Link
                  target="_blank"
                  component={RouterLink}
                  color="inherit"
                  className="btn btn-primary text-white btn-sm ml-3"
                  to={`/clinical-rehab-classes/patient-notes/${patient?.patient_id}`}
                >
                  Patient notes
                </Link>
              </Row>
            </Col>
            <Col lg={1}>
              <Row className="justify-content-center">
                <a
                  href="javascript:void(0)"
                  onClick={handleRemoveExerciseModal}
                  style={{ pointerEvents: readOnly && "none" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-trash3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                  </svg>
                </a>
              </Row>
            </Col>
          </Row>
          <TransitionGroup>
            {exercise?.variations.map((variation, i) => (
              <CSSTransition
                key={variation?.uid}
                classNames="example"
                timeout={{ enter: 500, exit: 300 }}
              >
                <VariationRow
                  patient_exercise_id={exercise?.patients_exercise_id}
                  exercise_index={index}
                  index={i}
                  variation={variation}
                  // exercise_uid={exercise.uid}
                  patient={patient}
                  exercise={exercise}
                  readOnly={readOnly}
                  handleRemoveVariation={handleRemoveVariation}
                  handleIntChange={handleIntChange}
                  handlePropUpdate={handlePropUpdate}
                />
              </CSSTransition>
            ))}
          </TransitionGroup>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Row className="justify-content-end">
                <button
                  className="btn btn-sm btn-primary text-white"
                  onClick={() => setVariationModalShow(true)}
                  disabled={readOnly}
                >
                  + Add variation
                </button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default ExerciseRow;
