import React, { useEffect, useRef } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientNOtes } from "../../_redux/clinicalRehabActions";
import { useParams } from "react-router-dom";
import ProgressBar from "../../../Other/ProgressBar";
import CreatableSelect from "react-select/creatable";
import { handleFieldChange as fieldChange } from "../../_redux/clinicalRehabActions";

const CustomTextArea = withStyles({
  root: {
    backgroundColor: "white",

    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#ffbdc2",
      },
    },
  },
})(TextField);

const colourStyles: StylesConfig<ColourOption, true> = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      ":active": {
        backgroundColor: "#ffe5e5",
      },
      ":focus": {
        backgroundColor: "#ffe5e5",
      },
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      padding: "3px",
      backgroundColor: "#ffe5e5",
    };
  },
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#ff5764 ",
      color: "white",
    },
  }),
};

const Notes = ({ disabled, notesRef }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { patientNotes, patientNotesLoading } = useSelector(
    (state) => state.clinicalRehab
  );

  useEffect(() => {
    dispatch(fetchPatientNOtes(id));
  }, []);

  useEffect(() => {
    if (patientNotes !== null) {
      const payload = {
        target: "patient_id",
        val: id,
      };

      dispatch(fieldChange(payload));
    }
  }, [patientNotes]);

  const handleFieldChange = (target, val) => {
    // console.log(target, val);
    const payload = {
      target: target,
      val: val,
    };

    dispatch(fieldChange(payload));
  };

  return (
    <>
      <Col lg={12}>
        <Card.Body
          style={{
            backgroundColor: disabled ? "whitesmoke" : "#ffe5e5",
          }}
        >
          {patientNotesLoading ? (
            <div style={{ minHeight: "240px" }}>
              <ProgressBar />
            </div>
          ) : (
            <>
              <Row className="mb-3">
                <Col lg={6}>
                  <Row>
                    <Col>Goals</Col>
                  </Row>
                  <Row>
                    <Col>
                      <CustomTextArea
                        onChange={(e) =>
                          handleFieldChange("goals", e.target.value)
                        }
                        disabled={disabled}
                        // label="Goals"
                        id="outlined-multiline-static"
                        multiline
                        rows="3"
                        margin="normal"
                        variant="outlined"
                        value={patientNotes?.goals}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row>
                    <Col>Critical notes</Col>
                  </Row>
                  <Row>
                    <Col>
                      <CustomTextArea
                        onChange={(e) =>
                          handleFieldChange("critical_notes", e.target.value)
                        }
                        disabled={disabled}
                        // label="Critical notes"
                        id="outlined-multiline-static"
                        multiline
                        rows="3"
                        margin="normal"
                        variant="outlined"
                        value={patientNotes?.critical_notes}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col lg={6}>
                  <Row>
                    <Col>History</Col>
                  </Row>
                  <Row>
                    <Col>
                      <CustomTextArea
                        onChange={(e) =>
                          handleFieldChange("history", e.target.value)
                        }
                        disabled={disabled}
                        // label="History"
                        id="outlined-multiline-static"
                        multiline
                        rows="4"
                        margin="normal"
                        variant="outlined"
                        value={patientNotes?.history}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row className="mb-3 align-items-center">
                    <Col lg={2}>Tags</Col>
                    <Col>
                      <CreatableSelect
                        onChange={(e) => handleFieldChange("tags", e)}
                        isMulti
                        placeholder="Tags"
                        isDisabled={disabled}
                        styles={colourStyles}
                        menuPortalTarget={document.body}
                        value={patientNotes?.tags}
                        getOptionLabel={(option) => `${option.label}`}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: "#ff8a93",
                            primary: "#ffe5e5",
                          },
                        })}
                        className={disabled ? "bg-white" : ""}
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col lg={2}>Case</Col>
                    <Col>
                      <Form.Control
                        // onChange={(e) =>
                        //   handleFieldChange("case", e.target.value)
                        // }
                        disabled={disabled}
                        className={disabled ? "bg-white" : ""}
                        value={patientNotes?.case}
                        // value="Clinical rehab"
                      />
                    </Col>
                    <Col
                      hidden={true}
                    >
                        <button 
                          ref={notesRef}
                          onClick={() => {  dispatch(fetchPatientNOtes(id)); }}
                        >
                          Reset
                          </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </Card.Body>
      </Col>
    </>
  );
};

export default Notes;
