import React, { useEffect, useState, useMemo, forwardRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
// import SplitButton from "react-bootstrap";
import { DropdownButton, Dropdown, Button, Form } from "react-bootstrap";

// ui
import { Row, Col } from "react-bootstrap";
import RemoveClassModal from "./course-export/RemoveClassModal";
import ScheduleIdModal from "./course-export/ScheduleIdModal";
// drag and drop
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// table style
import "./styles/ClassesTable.css";
import {
  searchReset,
  updatePatientMark,
  removePatient,
  transferPatient,
} from "../_redux/coursesActions";
import PatientSearchModal from "./course-export/patient/PatientSearchModal";
import { useDispatch } from "react-redux";
import { patientSearch } from "../_redux/coursesCrud";

const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <a
    href="#"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    {/* &#x25bc; */}
  </a>
));

const CustomMenu = forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

const CourseClasses = ({ classes }) => {
  const dispatch = useDispatch();
  // const { classes } = useSelector((state) => state.courses);
  // const { class_ids } = currentBlockClassList;
  const [sortedClasses, setSortedClasses] = useState([]);
  const [timeRanges, setTimeRanges] = useState([]);
  const [dragStart, setDragStart] = useState(false);
  const [tablizedClassList, setTablizedClasslist] = useState({
    headers: [],
    rows: [],
  });
  const [patientSearchToggle, setPatientSearchToggle] = useState(false);
  // const [patientSearchType, setPatientSearchType] = useState(null);
  const [patientSearchParams, setPatientSearchParams] = useState({
    class_id: null,
    type: null,
    index: null,
    uid: null,
  });
  const [selectedClassId, setSelectedClassId] = useState(null);
  const [removeClassModal, setRemoveClassModal] = useState(false);
  const [removeClassId, setRemoveClassId] = useState(null);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [scheduleId, setScheduleId] = useState(null);

  let sortedClassList = [];

  useEffect(() => {
    if (classes) {
      tablizeClassList(classes);
    }
  }, [classes]);

  const sortByTimeRange = (sortedClassList) => {
    let newClassList = [];
    if (sortedClassList.length > 0) {
      sortedClassList.map((scl) => {
        let timeSorted = [];
        scl.classList.map((cl) => {
          if (timeSorted.length > 0) {
            let existCount = 0;
            timeSorted.map((ts) => {
              if (ts.timeRange == cl.aTimeRange) {
                ts.data = [...ts.data, cl];
                existCount++;
              }
            });
            if (existCount == 0) {
              let classData = {
                timeRange: cl.aTimeRange,
                data: [cl],
              };
              timeSorted = [...timeSorted, classData];
            }
          } else {
            let classData = {
              timeRange: cl.aTimeRange,
              data: [cl],
            };
            timeSorted = [...timeSorted, classData];
          }
        });
        let newClassObj = {
          date: scl.date,
          classes: timeSorted,
        };
        newClassList = [...newClassList, newClassObj];
      });
    }
    let newTimeRanges = [];
    newClassList.map((ncl) => {
      ncl.classes.map((data) => {
        if (!newTimeRanges.includes(data.timeRange)) {
          newTimeRanges = [...newTimeRanges, data.timeRange];
        }
      });
    });

    setTimeRanges(newTimeRanges);
    setSortedClasses(newClassList);
  };

  const tablizeClassList = (classes) => {
    let headers = [];
    let rowTimeRange = [];
    let tempClasses = [];
    let rows = [];

    // add timeRanges for each class
    classes.map((cls) => {
      let claz = cls;
      let timeRange = cls.time;
      // moment(cls.StartDateTime).format("LT") +
      // " - " +
      // moment(cls.EndDateTime).format("LT");
      claz = { ...claz, timeRange };
      tempClasses = [...tempClasses, claz];
    });

    // order by startdatetime
    tempClasses.sort((a, b) => moment(a.start_time) - moment(b.start_time));

    // set headers
    tempClasses.filter((cls) =>
      !headers.includes(moment(cls.start_date).format("LL"))
        ? (headers = [...headers, moment(cls.start_date).format("LL")])
        : ""
    );

    // set first row values
    tempClasses.filter((cls) =>
      !rowTimeRange.includes(cls.time)
        ? (rowTimeRange = [...rowTimeRange, cls.time])
        : ""
    );

    // order by time range
    let tempTimesArr = [];
    let sortedTimeRange = [];
    // make time ranges into timestamps
    rowTimeRange.map((timeRange, index) => {
      let tempSingleTime = timeRange.split("-");
      tempTimesArr = [
        ...tempTimesArr,
        {
          index: index,
          time: moment(tempSingleTime[0].trim(), "hh:mm a").unix(),
        },
      ];
    });
    // sort by time
    tempTimesArr.sort((a, b) => moment(a.time) - moment(b.time));
    // assign original timerange to temp array
    tempTimesArr.map((tempTime) => {
      // console.log(rowTimeRange[tempTime.index]);
      sortedTimeRange = [...sortedTimeRange, rowTimeRange[tempTime.index]];
    });

    // rowTimeRange.sort((a,b)=> moment(a,b));

    // create table data
    sortedTimeRange.map((rtr) => {
      let newtd = {
        timeRange: rtr,
        td: [],
      };
      headers.map((headDate) => {
        let tdList = [];
        tempClasses.map((tc) => {
          if (
            moment(tc.start_date).format("LL") == headDate &&
            tc.time == rtr
          ) {
            let studioData = {
              studio: tc.location,
              classes: [tc],
            };
            tdList = [...tdList, studioData];
            // tdList = [...tdList, [tc.location][tc]];
          }
        });
        newtd.td = [...newtd.td, tdList];
      });

      rows = [...rows, newtd];
    });

    setTablizedClasslist((tablizeClassList) => ({
      ...tablizeClassList,
      headers: headers,
      rows: rows,
    }));
  };

  const handleRemoveClassModal = (uid) => {
    setRemoveClassId(uid);
    setRemoveClassModal(true);
  };

  const handleRemoveClassModalHide = () => {
    setRemoveClassId(null);
    setRemoveClassModal(false);
  };

  const handleScheduleModal = (cls) => {
    setScheduleId(cls);
    setScheduleModal(true);
  };

  const handleScheduleModalHide = () => {
    setScheduleModal(false);
    setScheduleId(null);
  };

  const handleSearchModal = (uid, type, index = null) => {
    // setPatientSearchType(type);
    setPatientSearchParams((prev) => ({
      ...prev,
      uid: uid,
      type: type,
      index: index,
    }));
    setPatientSearchToggle(true);
    // setSelectedClassId(class_id);
  };

  const handleCloseSearch = () => {
    setPatientSearchToggle(false);
    setSelectedClassId(null);
    setTimeout(() => {
      dispatch(searchReset());
    }, 500);
  };

  const markAsBg = (markId) => {
    if (markId == 1) {
      return "#d9d9d9";
    }
    if (markId == 2) {
      return "#93a4ff";
    }
    if (markId == 3) {
      return "#038500";
    }
    if (markId == 4) {
      return "#ffba53";
    }
    if (markId == 5) {
      return "#f481db";
    }
    if (markId == 6) {
      return "#00a3ff";
    }
  };

  const handleMarkUpdate = (uid, patient_id, mark_id) => {
    dispatch(updatePatientMark(uid, patient_id, mark_id));
  };

  const handleRemovePatient = (uid, patient_id) => {
    dispatch(removePatient(uid, patient_id));
  };

  const handleOnDrag = (e) => {
    setDragStart(true);
  };

  const handleDragEnd = (e) => {
    setDragStart(false);
    if (e.destination == null) {
      return;
    }

    let index = e?.source.index;
    let cls = classes.find(
      // (clx) => clx.schedule_id == e.source.droppableId.split("-")[1]
      (clx) => clx.uid == e.source.droppableId
    );
    let patient = cls?.patients[index];

    // let cls = classes.find(clx => clx.class_id == );
    let from = {
      patient: patient,
      uid: cls.uid,
      index: index,
    };

    let transfer_to = {
      // schedule_id: e?.destination?.droppableId.split("-")[1],
      uid: e?.destination?.droppableId,
      index: e?.destination?.index,
    };

    dispatch(transferPatient(from, transfer_to));
  };

  return (
    <div
      className="d-flex"
      style={{
        minHeight: "550px",
        maxHeight: "550px",
        overflow: "auto",
        border: "1px solid",
      }}
    >
      <PatientSearchModal
        searchToggle={patientSearchToggle}
        handleCloseSearch={handleCloseSearch}
        // selectedClassId={selectedClassId}
        patientSearchParams={patientSearchParams}
      />
      <RemoveClassModal
        uid={removeClassId}
        show={removeClassModal}
        onHide={() => handleRemoveClassModalHide()}
      />
      <ScheduleIdModal
        // schedule_id={scheduleModal}
        class={scheduleId}
        show={scheduleModal}
        onHide={() => handleScheduleModalHide()}
      />
      {tablizedClassList.length !== 0 ? (
        <DragDropContext
          onDragEnd={(result) => handleDragEnd(result)}
          onDragStart={(result) => handleOnDrag(result)}
        >
          <table id="classes-table" style={{ fontSize: "11px" }}>
            <colgroup>
              <col></col>
              {tablizedClassList.headers.map((header) => (
                <>
                  <col></col>
                </>
              ))}
            </colgroup>
            <thead>
              <th></th>
              {tablizedClassList.headers.map((header) => (
                <th className="p-5 text-center">
                  {moment(header).format("dddd")}, {moment(header).format("LL")}
                </th>
              ))}
            </thead>
            <tbody>
              {tablizedClassList.rows.map((row) => (
                <tr>
                  <td
                    className="p-2 border-bottom border-dark"
                    style={{ minWidth: "5%", maxWidth: "10%" }}
                  >
                    {row.timeRange}
                  </td>
                  {row.td.map((td) => (
                    <td
                      className="p-5 border-bottom border-dark"
                      style={{ minWidth: "15.8%", maxWidth: "15.8%" }}
                      // style={{ minWidth: "300px", maxWidth: "300px" }}
                    >
                      <Row className="d-flex justify-content-start">
                        {td.map((d) => (
                          <Col lg={10} className="m-5">
                            {d.classes.map((cls) => (
                              <>
                                <Row className="d-flex justify-content-center mb-3">
                                  <>
                                    {!cls.schedule_id && (
                                      <span
                                        className="bg-warning rounded-pill mr-1 text-center"
                                        style={{ width: "20px" }}
                                      >
                                        !
                                      </span>
                                    )}
                                    {cls.studio}
                                    {/* {!cls.schedule_id && ( */}
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        as={CustomToggle}
                                        id="dropdown-custom-components"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="black"
                                          className="bi bi-three-dots-vertical"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                        </svg>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu as={CustomMenu}>
                                        <Dropdown.Item
                                          eventKey="1"
                                          onClick={() =>
                                            handleScheduleModal(cls)
                                          }
                                        >
                                          Add class ID
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          eventKey="2"
                                          onClick={() =>
                                            handleRemoveClassModal(cls.uid)
                                          }
                                        >
                                          Remove class
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                    {/* )} */}
                                  </>
                                </Row>
                                <Row>
                                  <Col lg={3}>
                                    <Row className="d-flex justify-content-center">
                                      <span className="p-3">
                                        {cls.patients?.length ?? "??"}/
                                        {cls.max_capacity}
                                      </span>
                                    </Row>
                                    <Row className="d-flex justify-content-center">
                                      <p
                                        className="border rounded-circle p-3"
                                        style={{ backgroundColor: "#d9d9d9" }}
                                      >
                                        {cls.physio_name
                                          // .replace(/[^a-zA-Z]/, "") //replace special characters and numbers with space
                                          .split(" ")
                                          //
                                          .map((name, index) => {
                                            if (index < 2) {
                                              return name
                                                .substring(0, 1)
                                                .toUpperCase();
                                            }
                                          })}
                                      </p>
                                    </Row>
                                  </Col>
                                  {typeof cls.patients == "undefined" && (
                                    <Col
                                      lg={9}
                                      className="d-flex align-items-center"
                                    >
                                      Patients not fetched
                                    </Col>
                                  )}
                                  <Droppable
                                    isDropDisabled={cls?.patients?.length == 2}
                                    droppableId={cls.uid}
                                    key={cls.uid}
                                  >
                                    {(provided) => {
                                      return (
                                        <Col
                                          lg={9}
                                          {...provided.droppableProps}
                                          ref={provided.innerRef}
                                          // style={{
                                          //   background: snapshot.isDraggingOver
                                          //     ? "lightblue"
                                          //     : "yellow",
                                          // }}
                                        >
                                          {cls?.patients &&
                                            cls?.patients.map(
                                              (patient, index) => (
                                                <Draggable
                                                  key={patient.uid}
                                                  index={index}
                                                  draggableId={patient.uid}
                                                >
                                                  {(provided, snapshot) => {
                                                    return (
                                                      <Row
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                          ...provided
                                                            .draggableProps
                                                            .style,
                                                        }}
                                                      >
                                                        <Col
                                                          lg={9}
                                                          className="border p-3"
                                                          // draggable
                                                          // onDragStart={(e) =>
                                                          //   handleOnDrag(
                                                          //     e,
                                                          //     patient,
                                                          //     cls.class_id,
                                                          //     index
                                                          //   )
                                                          // }
                                                          style={{
                                                            backgroundColor: markAsBg(
                                                              patient.mark_as_id
                                                            ),
                                                          }}
                                                        >
                                                          <span>
                                                            {patient.FirstName}{" "}
                                                            {patient.LastName}
                                                          </span>
                                                        </Col>

                                                        <Col
                                                          lg={3}
                                                          className="d-flex align-items-center"
                                                        >
                                                          <DropdownButton
                                                            size="sm"
                                                            // variant="secondary"
                                                            // title=""
                                                            title={
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                fill="currentColor"
                                                                class="bi bi-three-dots-vertical"
                                                                viewBox="0 0 16 16"
                                                              >
                                                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                              </svg>
                                                            }
                                                            data-toggle="dropdown"
                                                            id={`dropdown-button-drop-${patient.Id}`}
                                                            key={patient.Id}
                                                          >
                                                            <Dropdown.Item
                                                              eventKey="1"
                                                              onClick={() =>
                                                                handleRemovePatient(
                                                                  cls.uid,
                                                                  patient.Id
                                                                )
                                                              }
                                                            >
                                                              Remove patient
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                              eventKey="2"
                                                              onClick={() =>
                                                                handleSearchModal(
                                                                  cls.uid,
                                                                  "update",
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              Change patient
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                              eventKey="2"
                                                              onClick={() =>
                                                                handleMarkUpdate(
                                                                  cls.uid,
                                                                  patient.Id,
                                                                  2
                                                                )
                                                              }
                                                            >
                                                              Confirmed, payment
                                                              pending
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                              eventKey="3"
                                                              onClick={() =>
                                                                handleMarkUpdate(
                                                                  cls.uid,
                                                                  patient.Id,
                                                                  3
                                                                )
                                                              }
                                                            >
                                                              Mark as Paid
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                              eventKey="4"
                                                              onClick={() =>
                                                                handleMarkUpdate(
                                                                  cls.uid,
                                                                  patient.Id,
                                                                  4
                                                                )
                                                              }
                                                            >
                                                              Mark as Payment
                                                              Plan
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                              eventKey="5"
                                                              onClick={() =>
                                                                handleMarkUpdate(
                                                                  cls.uid,
                                                                  patient.Id,
                                                                  5
                                                                )
                                                              }
                                                            >
                                                              Mark as
                                                              Tac/Workcover/NDIS
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                              eventKey="6"
                                                              onClick={() =>
                                                                handleMarkUpdate(
                                                                  cls.uid,
                                                                  patient.Id,
                                                                  6
                                                                )
                                                              }
                                                            >
                                                              Mark as TBC
                                                            </Dropdown.Item>
                                                          </DropdownButton>
                                                          {/* </div> */}
                                                        </Col>
                                                      </Row>
                                                    );
                                                  }}
                                                </Draggable>
                                              )
                                            )}

                                          {cls.patients &&
                                          !dragStart &&
                                          cls.patients?.length <
                                            cls.max_capacity
                                            ? [
                                                ...new Array(
                                                  cls.max_capacity -
                                                    cls.patients?.length
                                                ),
                                              ].map(() => (
                                                <Row>
                                                  <Col
                                                    lg={9}
                                                    className="border p-3"
                                                    // onDragOver={handleDragOver}
                                                    // onDrop={(e) =>
                                                    //   handleOnDrop(e, cls.class_id)
                                                    // }
                                                    style={{
                                                      height: "40px",
                                                      backgroundColor: "#ffff",
                                                    }}
                                                  >
                                                    <span>
                                                      {/* {patient.FirstName} {patient.LastName} */}
                                                    </span>
                                                  </Col>
                                                  <Col
                                                    lg={3}
                                                    className="d-flex align-items-center"
                                                  >
                                                    <Button
                                                      variant="primary"
                                                      size="sm"
                                                      onClick={() =>
                                                        handleSearchModal(
                                                          cls.uid,
                                                          "add"
                                                        )
                                                      }
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="#ffff"
                                                        class="bi bi-plus-lg"
                                                        viewBox="0 0 16 16"
                                                      >
                                                        <path
                                                          fill-rule="evenodd"
                                                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                                                        />
                                                      </svg>
                                                    </Button>
                                                  </Col>
                                                </Row>
                                              ))
                                            : ""}
                                        </Col>
                                      );
                                    }}
                                  </Droppable>
                                </Row>
                              </>
                            ))}
                          </Col>
                        ))}
                      </Row>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </DragDropContext>
      ) : (
        ""
      )}
    </div>
  );
};

export default CourseClasses;
