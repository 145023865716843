import React from "react";
import { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";

// const selectionRange = {
//   startDate: new Date(),
//   endDate: new Date(),
//   key: "selection",
// };

const DateRangePickerModal = (props) => {
  const [selection, setSelection] = useState({
    key: "selection",
    startDate: new Date(),
    endDate: new Date(),
  });

  const handleConfirm = (e) => {
    props.handleCustomDateConfirm(selection);
    // props.handleCustomDateConfirm(e);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <h3 className="m-auto">Custom Date Range</h3>
        <a href="javascript:void(0)" onClick={() => props.onHide()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
        </a>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center">
          <Col lg={10} className="mb-2">
            <DateRangePicker
              ranges={[selection]}
              onChange={(e) => setSelection(e.selection)}
              rangeColors={["#ff8a93", "#ff5764"]}
            />
          </Col>
          <Col lg={10} className="d-flex justify-content-center">
            <button
              className="btn btn-primary text-white rounded-pill"
              style={{ width: "150px" }}
              onClick={handleConfirm}
            >
              Confirm
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default DateRangePickerModal;
