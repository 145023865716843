import React from "react";
import {
  Row,
  Col,
  Form,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { Checkbox } from "@material-ui/core";
import Select from "react-select";
import { Image } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

const CustomSelect = (props) => {
  return (
    <Select
      {...props}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "#ffe5e5",
          primary: "#ff8a93",
        },
      })}
    />
  );
};

const Step3 = (props) => {
  return (
    <div>
      {[...new Array(3)].map(() => (
        <Row className="p-2">
          <Col lg={4}>
            <Row>
              <Col lg={3}>
                <Checkbox
                  defaultChecked
                  color="default"
                  inputProps={{
                    "aria-label": "checkbox with default color",
                  }}
                />
              </Col>
              <Col lg={9}>
                <Row className="py-2">
                  <Col>Exercise name</Col>
                </Row>
                <Row className="mb-3">
                  <Col lg={12}>
                    <ButtonToolbar>
                      <ToggleButtonGroup
                        type="radio"
                        name="options"
                        defaultValue={1}
                      >
                        <ToggleButton value={1}>Bas</ToggleButton>
                        <ToggleButton value={2}>Int</ToggleButton>
                        <ToggleButton value={3}>Adv</ToggleButton>
                      </ToggleButtonGroup>
                    </ButtonToolbar>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col lg={12}>
                    <CustomSelect placeholder={"Variation"} />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col lg={12}>
                    <CustomSelect placeholder={"Equipment"} />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col lg={8}>
                    <Form.Control placeholder="Type level" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col lg={8}>
            <Row className="align-items-end">
              <Col lg={8}>
                <Row className="justify-content-end">
                  <Col lg={10} className="py-5">
                    Description goes here (is based on the variation) from the
                    exercise library
                  </Col>
                </Row>
                <Row className="justify-content-end pt-2">
                  <Col lg={10}>
                    <Row className="align-items-center">
                      <Col lg={1}>
                        <SVG
                          className="h-75 align-self-end"
                          src={toAbsoluteUrl("/media/svg/icons/dumbell.svg")}
                        />
                      </Col>
                      <Col lg={1}>
                        <a href="javascript:void(0)">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-plus-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                          </svg>
                        </a>
                      </Col>
                      <Col>
                        <CustomSelect placeholder={"Set"} />
                      </Col>
                      <Col>
                        <CustomSelect placeholder={"Rep"} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col lg={4} className="border">
                <Image
                  src={toAbsoluteUrl("/media/images/default-exercise.png")}
                  rounded
                  height={150}
                  width={150}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      ))}
      <Row className="p-2 justify-content-center">
        <Col lg={2}>
          <button
            className="btn btn-secondary text-primary rounded-pill w-100"
            onClick={() => props.onHide()}
          >
            Cancel
          </button>
        </Col>
        <Col lg={2}>
          <button
            className="btn btn-primary text-white rounded-pill w-100"
            onClick={() => props.handleNextStep()}
          >
            Save
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default Step3;
