import axios from "axios";

const exerciseUrl = `${process.env.REACT_APP_API_URL}/exercise`;

// get exercise
export function getExerciseList() {
  return axios.get(`${exerciseUrl}/list`);
}

export function getExercise(id) {
  return axios.get(`${exerciseUrl}/${id}`);
}
//tags
export function getTags() {
  return axios.get(`${exerciseUrl}/tags`);
}

export function getEquipmentIcons() {
  return axios.get(`${exerciseUrl}/equipment-icons`);
}

export function getPropIcons() {
  return axios.get(`${exerciseUrl}/props-icons`);
}

export function saveEquipment(data) {
  return axios.post(`${exerciseUrl}/equipment`, data);
}

export function updateEquipment(data) {
  return axios.put(`${exerciseUrl}/equipment/${data.id}`, data);
}

export function saveProp(data) {
  return axios.post(`${exerciseUrl}/props`, data);
}

export function updateProp(data) {
  return axios.put(`${exerciseUrl}/props/${data.id}`, data);
}

export function saveExercise(data) {
  // console.log("this is d data", data);
  return axios.post(`${exerciseUrl}`, data);
}

export function updateExercise(data) {
  // console.log("this is d data", data);
  return axios.put(`${exerciseUrl}/${data.id}`, data);
}

export function exerciseArchive(data) {
  return axios.patch(`${exerciseUrl}/${data}`);
}

export function exerciseDelete(data) {
  return axios.delete(`${exerciseUrl}/${data}`);
}

export function reactivateExercise(data) {
  return axios.patch(`${exerciseUrl}/reactivate/${data}`);
}
