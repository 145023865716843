import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { validateDates } from "../_redux/coursesActions";
import { useDispatch } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import InfoIcon from "@material-ui/icons/Info";
import { makeStyles } from "@material-ui/core/styles";
import { amber, green } from "@material-ui/core/colors";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
// import "react-datepicker/dist/react-datepicker.css";
// import "./app/styles/react-datepicker.css"; //custom datepicker styles
import "../../../../app/styles/react-datepicker.css";

const variantIcon = {
  info: InfoIcon,
};

const useStyles1 = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
};

const useStyles2 = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

const BlocksRow = ({
  block,
  year,
  handleBlockUpdate,
  handleNextBlockUpdate,
  setActiveDatepicker,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles2();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectionComplete, toggleSelectionComplete] = useState(false);
  const [dateRange, setDateRange] = useState("");
  const [datepicker, setDatepicker] = useState(false);
  const [snackBar, setSnackBar] = useState(false);

  const handleDateChange = (date) => {
    if (!selectionComplete && !startDate) {
      //on first date selection, set the start date

      setStartDate(date);
      return;
    }

    if (!selectionComplete && startDate && !endDate) {
      //on second date selection, set the end date
      if (startDate > date) {
        // dispatch(validateDates("End date must be greater than start date."));
        setSnackBar(true);
      } else {
        setEndDate(date);
        toggleSelectionComplete(true);
      }

      //do stuff with date range

      return;
    }

    if (selectionComplete && startDate && endDate) {
      //on third date selection, begin selection of a new date range
      //reset the start date and clear the end date.

      setStartDate(date);
      setEndDate(undefined);
      toggleSelectionComplete(false);
      return;
    }

    if (selectionComplete && startDate) {
      setStartDate(date);
      setEndDate(undefined);
      toggleSelectionComplete(false);
      return;
    }
  };

  const handleSelect = (date) => {
    //onChange is not fired if selecting same date - workaround to fire handleDateChange
    if (
      !selectionComplete &&
      startDate &&
      !endDate &&
      sameDay(date, startDate)
    ) {
      handleDateChange(date);
    }
  };

  const sameDay = (d1, d2) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  const handleClose = () => {
    console.log("close");
    setSnackBar(false);
  };

  useEffect(() => {
    setDatepicker(false);
  }, [dateRange]);

  function updateBlocks() {
    let start_date = moment(startDate).format("DD/MM/YYYY");
    let end_date = moment(endDate).format("DD/MM/YYYY");
    setDateRange(`${start_date} - ${end_date}`);
    // setDatepicker(false);
    if (handleBlockUpdate) {
      //fif block has id
      if (block?.id) {
        handleBlockUpdate({
          block: {
            id: block.id ? block.id : null,
            block_no: block.block_no,
            date_from: moment(startDate).format("YYYY-MM-DD"),
            date_to: moment(endDate).format("YYYY-MM-DD"),
            year: block.year,
          },
        });
      } else {
        handleBlockUpdate({
          block: {
            block_no: block.block_no,
            date_from: moment(startDate).format("YYYY-MM-DD"),
            date_to: moment(endDate).format("YYYY-MM-DD"),
            year: block.year,
          },
        });
      }
    } else {
      if (block?.id) {
        handleNextBlockUpdate({
          block: {
            id: block.id ? block.id : null,
            block_no: block.block_no,
            date_from: moment(startDate).format("YYYY-MM-DD"),
            date_to: moment(endDate).format("YYYY-MM-DD"),
            year: block.year,
          },
        });
      } else {
        handleNextBlockUpdate({
          block: {
            block_no: block.block_no,
            date_from: moment(startDate).format("YYYY-MM-DD"),
            date_to: moment(endDate).format("YYYY-MM-DD"),
            year: block.year,
          },
        });
      }
    }
  }

  useEffect(() => {
    endDate && updateBlocks();
  }, [endDate]);

  useEffect(() => {
    if (block.date_from !== null && block.date_to !== null) {
      let start_date = moment(block.date_from);
      let end_date = moment(block.date_to);

      setStartDate(start_date._d);
      setEndDate(end_date._d);
      toggleSelectionComplete(true);
    } else {
      setDateRange("Dates not set.");

      if (year == "next") {
        setStartDate(
          moment()
            .add(1, "year")
            .startOf("year")._d
        );
        toggleSelectionComplete(true);
      }
    }
  }, []);

  const handleDatePicker = () => {
    // setDatepicker(!datepicker);
    console.log(block);
    setActiveDatepicker(block);
  };

  return (
    <Row className="mb-5">
      {/* <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackBar}
        autoHideDuration={4000}
        onClose={handleClose}
        message="End date must be greater than start date."
      /> */}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackBar}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          variant="info"
          className={classes.margin}
          message="End date must be greater than start date."
        />
      </Snackbar>
      <Col lg={4} className="d-flex justify-content-center align-items-center">
        Block {block.block_no}
      </Col>
      <Col lg={6} className="d-flex align-items-center">
        <span>{dateRange}</span>
      </Col>
      <Col lg={2}>
        <button className="btn btn-primary btn-sm" onClick={handleDatePicker}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-calendar-range"
            viewBox="0 0 16 16"
          >
            <path d="M9 7a1 1 0 0 1 1-1h5v2h-5a1 1 0 0 1-1-1zM1 9h4a1 1 0 0 1 0 2H1V9z" />
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
          </svg>
        </button>
        {datepicker && (
          <div style={{ position: "absolute", zIndex: "1" }}>
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              onSelect={handleSelect}
              selectsEnd={!startDate}
              startDate={startDate}
              endDate={endDate}
              inline
            ></DatePicker>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default BlocksRow;
