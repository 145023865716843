import React, { useEffect, useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import RowCollapse from "./RowCollapse";

const ClinicalRehabTableRow = ({ row }) => {
  const [open, setOpen] = useState(null);

  const handleOpen = (class_id) => {
    if (open == class_id) {
      setOpen(null);
    }

    if (open == null) {
      setOpen(class_id);
    } else {
      if (open == class_id) {
        setOpen(null);
      } else {
        setOpen(class_id);
      }
    }
  };

  // useEffect(() => {
  //   console.log(open);
  // }, [open]);

  return (
    <>
      <TableRow
        hover
        // onClick={(event) => handleClick(event, row.name)}
        // role="checkbox"
        // aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.exercise_id}
        // selected={isItemSelected}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              handleOpen(row.class_id);
            }}
          >
            {open == row.class_id ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell>
          {row.day} {moment(`${row.start_date} ${row.start_time}`).format("LT")}
        </TableCell>
        <TableCell>
          {moment(`${row.start_date} 00:00:00`).format("DD/MM/YYYY")} -{" "}
          {moment(`${row.end_date} 00:00:00`).format("DD/MM/YYYY")}
        </TableCell>
        <TableCell>{row.studio}</TableCell>
        <TableCell>{row.physio_name}</TableCell>
        <TableCell>{moment(row.updated_at).format("DD/MM/YYYY")}</TableCell>
        <TableCell>
          {row.patients.some((p) => p.is_published == 0)
            ? "Draft"
            : "Published"}
        </TableCell>
        {/* <TableCell>{row.status}</TableCell> */}
        <TableCell>
          {row.patients.some((patient) => patient.newly_added == true) && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#ffba53"
              class="bi bi-patch-exclamation-fill"
              viewBox="0 0 16 16"
            >
              <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>
          )}
        </TableCell>
        <TableCell>
          <Link
            component={RouterLink}
            to={`/clinical-rehab-classes/exercise-plans/${row.schedule_id}/${row.class_id}`}
            color="inherit"
            className="pr-3"
          >
            {/* <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 svg-icon-sort"> */}
            <SVG
              style={{ width: "24px" }}
              fill="currentColor"
              src={toAbsoluteUrl("/media/svg/icons/notes.svg")}
            />
            {/* </span> */}
          </Link>
          {/* <Link
            component={RouterLink}
            color="inherit"
            className="pr-3"
            to={`/clinical-rehab-classes/exercise-plans/${row.schedule_id}/${row.class_id}/read-only`}
          > */}
            {/* <Link component={RouterLink} to="/users/details" color="inherit" className="pr-3">  */}
            {/* <span className="svg-icon svg-icon-sm svg-icon-primary svg-icon-sort"> */}
            {/* <SVG
              style={{ width: "24px" }}
              fill="currentColor"
              src={toAbsoluteUrl("/media/svg/icons/eye-solid.svg")}
            /> */}
            {/* </span> */}
          {/* </Link> */}
          {/* <Link
            color="inherit"
            className="pr-3"
            onClick={() => console.log("nice click")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-arrow-repeat"
              viewBox="0 0 16 16"
            >
              <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
              <path
                fill-rule="evenodd"
                d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
              />
            </svg>
          </Link> */}
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="border-0 p-0"></TableCell>
        <TableCell colSpan={2} className="border-0 p-0">
          <Collapse
            in={open == row.class_id ? true : false}
            timeout="auto"
            unmountOnExit
          >
            {/* <Box sx={{ margin: 1 }}> */}
            <RowCollapse
              patients={row.patients}
              schedule_id={row.schedule_id}
              class_id={row.class_id}
            />
            {/* </Box> */}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ClinicalRehabTableRow;
