import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import moment from "moment";

const HolidayListModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-90w"
    >
      <Modal.Header>
        <h3 className="m-auto">Public holidays</h3>
        <a href="javascript:void(0)" onClick={() => props.onHide()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
        </a>
      </Modal.Header>
      <Modal.Body>
        <Grid container>
          <Grid item lg={11}>
            {props.holidays.map((holiday) => (
              <Row className="d-flex justify-content-center my-3">
                <Col lg={6}>
                  <strong>{holiday.name}</strong>
                </Col>
                <Col lg={5} className="d-flex justify-content-center">
                  {moment(holiday.holiday_date).format("DD/MM/YYYY")}
                </Col>
              </Row>
            ))}
          </Grid>
        </Grid>
      </Modal.Body>
    </Modal>
  );
};

export default HolidayListModal;
