import React, { useState, useEffect, forwardRef } from "react";
import { Modal, Col, Row, Form, Spinner } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { addClass } from "../../_redux/coursesActions";
import DatePicker from "react-datepicker";
import moment from "moment";

const CustomDatePicker = forwardRef(({ value, onClick }, ref) => (
  <button
    className="btn btn-primary btn-sm ml-auto"
    onClick={onClick}
    ref={ref}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-calendar-range"
      viewBox="0 0 16 16"
    >
      <path d="M9 7a1 1 0 0 1 1-1h5v2h-5a1 1 0 0 1-1-1zM1 9h4a1 1 0 0 1 0 2H1V9z" />
      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
    </svg>
  </button>
));

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    textAlign: "center",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const AddClassModal = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { filters, staffs, addClassLoading, addClassSuccess } = useSelector(
    (state) => state.courses
  );
  const { physio, location } = filters;
  const [newClass, setNewClass] = useState({
    physio_name: null,
    studio: "",
    max_capacity: null,
    start_date: null,
    start_time: null,
    end_time: null,
  });

  const [errors, setErrors] = useState([]);

  // array string options/value
  const optionsArrayString = (options) => {
    let opts = [];
    options &&
      options.map((option) => {
        option = {
          label: option,
          value: option,
        };
        opts = [...opts, option];
      });

    return opts;
  };

  const optionsArrayStringDate = (options) => {
    let opts = [];
    options &&
      options.map((option) => {
        option = {
          label: moment(option).format("dddd"),
          value: option,
        };
        opts = [...opts, option];
      });

    return opts;
  };

  const handleFieldChange = (target, value) => {
    let newErrors = [];
    if (errors.length > 0) {
      errors.map((error) => {
        if (error !== target) {
          newErrors = [...newErrors, error];
        }
      });
      setErrors(newErrors);
    }
    setNewClass((newClass) => ({
      ...newClass,
      [target]: value,
    }));
  };

  const handleModalClose = () => {
    setNewClass({
      physio_name: null,
      studio: "",
      max_capacity: null,
      start_date: null,
      start_time: null,
      end_time: null,
    });
    setErrors([]);
    props.onHide();
  };

  useEffect(() => {
    if (addClassSuccess) {
      props.onHide();
      setNewClass({
        physio_name: null,
        studio: "",
        max_capacity: null,
        start_date: null,
        start_time: null,
        end_time: null,
      });
    }
  }, [addClassSuccess]);

  useEffect(() => {
    console.log(newClass);
  }, [newClass]);

  const handleSave = () => {
    let newErrors = [];
    if (newClass.physio_name == null) {
      // setErrors([...errors, "physio"]);
      newErrors = [...newErrors, "physio_name"];
    }
    if (newClass.studio == "") {
      newErrors = [...newErrors, "studio"];
    }
    if (newClass.max_capacity == null) {
      newErrors = [...newErrors, "max_capacity"];
    }
    if (newClass.start_date == null) {
      newErrors = [...newErrors, "start_date"];
    }
    if (newClass.start_time == null) {
      newErrors = [...newErrors, "start_time"];
    }
    if (newClass.end_time == null) {
      newErrors = [...newErrors, "end_time"];
    }

    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }
    dispatch(addClass(newClass));
  };

  return (
    <Modal
      {...props}
      onHide={handleModalClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <h3 className="m-auto">Add class</h3>
        <a href="javascript:void(0)" onClick={handleModalClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
        </a>
      </Modal.Header>
      <Modal.Body>
        <>
          <Grid container className="justify-content-center mb-3">
            <Grid item lg={9}>
              <Row className="mb-5 align-items-center">
                <Col lg={5}>
                  <strong>Physio</strong>
                </Col>
                <Col lg={7}>
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    // isDisabled={classesLoading}
                    name="physio_name"
                    // options={optionsArrayString(physio)}
                    options={staffs}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => option.id}
                    // value={filters.physio}
                    onChange={(e) => handleFieldChange("physio_name", e)}
                    placeholder="Physio"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#ff8a93",
                        primary: "#ffe5e5",
                      },
                    })}
                  />
                  {errors.includes("physio_name") && (
                    <span
                      className="position-absolute text-danger"
                      style={{
                        fontSize: "10px",
                      }}
                    >
                      This field is required
                    </span>
                  )}
                </Col>
              </Row>
              <Row className="mb-5 align-items-center">
                <Col lg={5}>
                  <strong>Location</strong>
                </Col>
                <Col lg={7}>
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    // isDisabled={classesLoading}
                    name="studio"
                    options={optionsArrayString(location)}
                    // value={filters.physio}
                    onChange={(e) => handleFieldChange("studio", e.value)}
                    placeholder="Studio"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#ff8a96",
                        primary: "#ffe5e5",
                      },
                    })}
                  />
                  {errors.includes("studio") && (
                    <span
                      className="position-absolute text-danger"
                      style={{
                        fontSize: "10px",
                      }}
                    >
                      This field is required
                    </span>
                  )}
                </Col>
              </Row>
              <Row className="mb-5 align-items-center">
                <Col lg={5}>
                  <strong>Capacity</strong>
                </Col>
                <Col lg={7}>
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    // isDisabled={classesLoading}
                    name="max_capacity"
                    options={optionsArrayString([
                      1,
                      2,
                      3,
                      4,
                      5,
                      6,
                      7,
                      8,
                      9,
                      10,
                    ])}
                    // getOptionLabel={(option) => `${option}`}
                    // getOptionValue={(option) => option}
                    // value={filters.physio}
                    onChange={(e) => handleFieldChange("max_capacity", e.value)}
                    placeholder="Capacity"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#ff8a96",
                        primary: "#ffe5e5",
                      },
                    })}
                  />
                  {errors.includes("max_capacity") && (
                    <span
                      className="position-absolute text-danger"
                      style={{
                        fontSize: "10px",
                      }}
                    >
                      This field is required
                    </span>
                  )}
                </Col>
              </Row>
              <Row className="mb-5 align-items-center">
                <Col lg={5}>
                  <strong>Schedule date</strong>
                </Col>
                <Col lg={7}>
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    // isDisabled={classesLoading}
                    name="start_date"
                    options={optionsArrayStringDate(filters?.schedule_dates)}
                    // value={filters.physio}
                    onChange={(e) => handleFieldChange("start_date", e.value)}
                    placeholder="Schedule date"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#ff8a96",
                        primary: "#ffe5e5",
                      },
                    })}
                  />
                  {/* <Row className="d-flex">
                    <span className="m-auto">
                      {newClass.start_date !== null &&
                        moment(newClass.start_date).format("DD/MM/YYYY")}
                    </span>
                    <div className="ml-auto">
                      <DatePicker
                        popperProps={{
                          positionFixed: true,
                        }}
                        selected={
                          newClass.start_date
                            ? moment(newClass.start_date)._d
                            : null
                        }
                        onChange={(date) =>
                          handleFieldChange("start_date", moment(date))
                        }
                        customInput={<CustomDatePicker />}
                      />
                    </div>
                  </Row> */}
                  {errors.includes("start_date") && (
                    <span
                      className="text-danger"
                      style={{
                        fontSize: "10px",
                      }}
                    >
                      This field is required
                    </span>
                  )}
                </Col>
              </Row>
              {newClass.start_date !== null && (
                <>
                  <Row className="mb-5 align-items-center">
                    <Col lg={5}>
                      <strong>Start time</strong>
                    </Col>
                    <Col
                      lg={7}
                      className="flex-column d-flex justify-content-center"
                    >
                      <TextField
                        type="time"
                        className={classes}
                        onChange={(e) =>
                          handleFieldChange("start_time", e.target.value)
                        }
                      />
                      {errors.includes("start_time") && (
                        <span
                          className="text-danger"
                          style={{
                            fontSize: "10px",
                          }}
                        >
                          This field is required
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-5 align-items-center">
                    <Col lg={5}>
                      <strong>End time</strong>
                    </Col>
                    <Col
                      lg={7}
                      className="flex-column d-flex justify-content-center"
                    >
                      <TextField
                        type="time"
                        className={classes}
                        onChange={(e) =>
                          handleFieldChange("end_time", e.target.value)
                        }
                        inputProps={{
                          min: `${newClass.start_time}`,
                        }}
                      />
                      {errors.includes("end_time") && (
                        <span
                          className="text-danger"
                          style={{
                            fontSize: "10px",
                          }}
                        >
                          This field is required
                        </span>
                      )}
                    </Col>
                  </Row>
                </>
              )}
              {/* <Row className="mb-5 align-items-center">
                <Col lg={5}>
                  <strong>Schedule ID</strong>
                </Col>
                <Col lg={7}>
                  <Form.Control
                    type="text"
                    name="schedule_id"
                    onChange={(e) =>
                      handleFieldChange(e.target.name, e.target.value)
                    }
                  />
                </Col>
              </Row> */}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={11}>
              <Row className="d-flex justify-content-center">
                <Col lg={4}>
                  <button
                    className="btn btn-secondary rounded-pill w-100 text-primary"
                    onClick={handleModalClose}
                  >
                    Cancel
                  </button>
                </Col>
                <Col lg={4}>
                  <button
                    className="btn btn-primary rounded-pill w-100 position-relative"
                    onClick={handleSave}
                  >
                    {addClassLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        style={{
                          position: "absolute",
                          left: "2rem",
                          top: "1rem",
                        }}
                        aria-hidden="true"
                      />
                    )}
                    Save
                  </button>
                </Col>
              </Row>
            </Grid>
          </Grid>
        </>
      </Modal.Body>
    </Modal>
  );
};

export default AddClassModal;
