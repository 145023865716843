import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
//ui
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import { Button } from "react-bootstrap";
import { InputGroup, FormControl } from "react-bootstrap";
//components
import Filter from "../Other/Filter";
import ExercisesTable from "./components/ExercisesTable";
import AdmodExerciseDialog from "./components/dialog-bars/AdModExerciseDialog";
// redux
import {
  getExercisesList,
  statusReset,
  saveReset,
  archiveToggle,
} from "./_redux/exercises/exerciseLibraryActions";
import { useDispatch, useSelector } from "react-redux";

const Exercise = () => {
  const { list, showArchived } = useSelector((state) => state.exerciseLibrary);
  const [exercises, setExercises] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getExercisesList());
    dispatch(saveReset());
    setTimeout(() => {
      dispatch(statusReset());
    }, 2000);
  }, []);

  useEffect(() => {
    let newList = [];
    if (showArchived == true) {
      list.map((data) => {
        newList = [...newList, data];
      });
    } else {
      list.map((data) => {
        if (data.archived_at == null) {
          newList = [...newList, data];
        }
      });
    }
    setExercises(newList);
  }, [list, showArchived]);

  const handleSearch = (key) => {
    const filteredList = list.filter((row) => {
      // if(row.name.toLowerCase().includes(key.toLowerCase()) && ){

      // }
      if (showArchived == true) {
        if (row.name.toLowerCase().includes(key.toLowerCase())) {
          return row;
        }
      } else {
        if (
          row.name.toLowerCase().includes(key.toLowerCase()) &&
          row.archived_at == null
        ) {
          return row;
        }
      }
    });
    setExercises(filteredList);
  };

  const handleArchiveToggle = () => {
    dispatch(archiveToggle());
  };

  return (
    <>
      <AdmodExerciseDialog />
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Link
            component={RouterLink}
            to="/exercises/add"
            color="inherit"
            className="pr-3"
          >
            <Button variant="primary" size="medium" className="rounded-pill">
              + Add exercise
            </Button>
          </Link>
        </Grid>
        <Grid item xs={6}>
          {/* <Filter /> */}
          <Grid container justify="flex-end">
            <div className="d-flex align-items-center">
              <a className="p-5" onClick={handleArchiveToggle}>
                {showArchived ? "Hide archived" : "Show archived"}
              </a>
            </div>
            <div>
              <Filter handleSearch={handleSearch} />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ExercisesTable list={exercises} />
        </Grid>
      </Grid>
    </>
  );
};

export default Exercise;
