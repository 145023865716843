import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import NoteFiles from "./NoteFiles";

const CustomTextArea = withStyles({
  root: {
    backgroundColor: "white",
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#ffbdc2",
      },
    },
  },
})(TextField);

const ScheduleNote = ({ note }) => {
  console.log("note");
  console.log(note);
  return (
    <Card.Body className="rounded" style={{ backgroundColor: "whitesmoke" }}>
      <Row className="mb-2">
        <Col>Notes {note?.date ? <span>{` - ${note?.date}`}</span> : ""}</Col>
      </Row>
      <Row>
        <Col lg={12}>
          <ul>
            {note?.notes.map((text) => {
              return <li>{text}</li>;
            })}
          </ul>
          {/* <CustomTextArea
            id="outlined-multiline-static"
            multiline
            rows="6"
            margin="normal"
            variant="outlined"
            disabled
            value={note?.text}
          /> */}
        </Col>
      </Row>
      <Row className="justify-content-center">
        {note.files && <NoteFiles files={note.files} />}
        {/* {note.files.map((noteFile) => {
          console.log("noteFile");
          console.log(noteFile[0].image_key);
          noteFile.map(
            (nFile) => {
              return "YAWA";
            }
            // if (nFile?.image_key) {
            //   console.log("nFile.image_key");
            //   console.log(nFile.image_key);

            //   return (

            //   );
            // }
          );
        })} */}
      </Row>
    </Card.Body>
  );
};

export default ScheduleNote;
