import React, { useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { Typography } from "@material-ui/core";
import PreviousExportClassesComponent from "../components/previous-export/PreviousExportClassesComponent";
import ProgressBar from "../components/course-export/ProgressBar";
// actions
import { fetchPreviousExportClasses } from "../_redux/coursesActions";
// redux
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
const PreviousExportPage = ({
  history,
  match: {
    params: { id },
  },
}) => {
  const { listLoading, perviousExportData } = useSelector(
    (state) => state.courses
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPreviousExportClasses(id));
  }, [dispatch]);
  return (
    <div className="position-relative h-100">
      {listLoading ? (
        <ProgressBar />
      ) : (
        <>
          <Grid container spacing={2} className="mb-5">
            <Grid item lg={3}>
              <Row>
                <Col>
                  <Typography variant="h6">Export date</Typography>
                </Col>
                <Col className="d-flex align-items-center">
                  {moment(perviousExportData?.exported_date).format(
                    "DD/MM/YYYY"
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Typography variant="h6">Block date range</Typography>
                </Col>
                <Col className="d-flex align-items-center">
                  {moment(
                    perviousExportData?.block_information?.date_from
                  ).format("DD/MM/YYYY")}{" "}
                  -{" "}
                  {moment(
                    perviousExportData?.block_information?.date_to
                  ).format("DD/MM/YYYY")}
                </Col>
              </Row>
            </Grid>
          </Grid>
          <Grid container className="mt-5">
            <Grid item lg={12}>
              <PreviousExportClassesComponent />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default PreviousExportPage;
